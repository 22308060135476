import React,{useState} from 'react'
import Navbar from '../../navbar/Navbar'
import Footer from '../../footer/Footer'
import Uploads from '../../account-components/Uploads';
import AgentData from '../../account-components/AgentData';
import LabelData from '../../account-components/LabelData';
import Dashboard from '../../account-components/Dashboard';
import Catalogue from '../../account-components/Catalogue';
import Expenses from '../../account-components/Expenses';
import AddMiscLabel from '../../account-components/AddMiscLabel';
import Exports from '../../account-components/Exports';

function Accounting() {
    const [activeTab, setActiveTab] = useState("dashboard");

const onTabSelect = (tab) => {
  setActiveTab(tab);
};

  return (
    <div className='z-0'>
        <Navbar/>
        <main className='flex flex-col w-full'>
        <header className="flex items-center overflow-x-auto border-b w-full">
        {settingsNav?.map((nav, index) => (
          <h3
            onClick={() => onTabSelect(nav.tag)}
            key={index}
            className={`flex border w-full font-medium items-center justify-center text-center text-xs  cursor-pointer px-3  py-2 transition-all ${
              activeTab === nav?.tag
                ? "text-white bg-[#273297]  border-primary"
                : "text-white  border-b-2 bg-black border-white"
            } `}
          >
            {nav?.name}
          </h3>
        ))}
      </header>
    
      <section className='w-full'>
     {activeTab === "dashboard" && <Dashboard/>}
     {activeTab === "catalogue" && <Catalogue/>}
     {activeTab === "report" && <Uploads/>}
     {activeTab === "agents" && <AgentData/>}
     {activeTab === "exports" && <Exports/>}
     {activeTab === "expenses" && <Expenses/>}
     {activeTab === "labels" && <LabelData/>}
     {activeTab === "misc" && <AddMiscLabel/>}

      </section>
        </main>
        <Footer/>
    </div>
  )
}

export default Accounting;

const settingsNav = [
    {id: 1, name: "DASHBOARD",tag:"dashboard"},
    {id: 2, name: "CATALOGUE",tag:"catalogue"},
    // {id: 3, name: "VENDORS",tag:"vendors"},
    {id: 4, name: "AGENTS",tag:"agents"},
    {id: 5, name:"LABELS",tag:"labels"},
    {id: 6, name: "BALANCE_EXPORTS",tag:"exports"},
    {id: 7, name: "LABELS_PAID_DIRECTLY",tag:"misc"},
    {id: 8, name: "EXPENSES",tag:"expenses"},
    {id: 9, name:"CREATE_QUATERLY_REPORT",tag:"report"},
    
  ];