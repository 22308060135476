import React,{useEffect,useState} from 'react'
import { LuXCircle } from "react-icons/lu";
import {api,base} from '../../utils/api'
import axios from 'axios'
import DataLoader from '../../smallLoader/DataLoader'
import DynamicTable from '../ui/DynamicTable';
import ExportButton from '../ui/ExportButton';
import ExportButtonPdf from '../ui/ExportButtonPdf';
import ExportReportButton from '../ui/ExportReportButton';
import ExpensesModal from './ExpensesModal';
import ExpenseHistoryModal from './ExpenseHistoryModal';

function LabelDetailSumarry({row,setLabelDetailsDisplay}) {
  const [agentSummaryData,setAgentSummaryData] = useState(null);
  const [error,setError] = useState(null);
  const [loading,setLoading] = useState(false)
  const [usdRev,setUsdRev] = useState(null)
  const [gbpRev,setGbpRev] = useState(null)
  const [usdShare,setUsdShare] = useState(0)
  const [gbpShare,setGpbShare] = useState(0)
  const [lineCount,setLineCount] = useState(null)
  const [qtySales,setQtySales] = useState(null)
  const [err,setErr] = useState(null)
  const [agentUsdShare,setAgentUsdShare] = useState(0)
  const [openHistory,setOpenHistory] = useState(false)
  const [quater,setQuater] = useState(null)
  const [openExpenses,setOpenExpenses] = useState(null)
  console.log(row)

//   useEffect(() => {
//     const agentID = row?.zkp_Agent
//       axios.get(base + `/agent-labels/${agentID}`).then(
//           response =>{
//               const res = response?.data?.data
//               console.log("agent-labels:",res)
//               setAgentSummaryData(response?.data?.data?.label_list);
//               setLineCount(response?.data?.data?.line_count)
//               setQtySales(response?.data?.data?.quantity_sales)
//               setGbpRev(response?.data?.data?.gbp_rev)
//               setUsdRev(response?.data?.data?.usd_rev)
//               setUsdShare(response?.data?.data?.usd_share)
//               setGpbShare(response?.data?.data?.gbp_share)
//               setAgentGpbShare(response?.data?.data?.agent_gbp_share)
//               setAgentUsdShare(response?.data?.data?.agent_usd_share)
//               setLoading(false)
//           }
//       ).catch(
//            err =>{
//               console.error(err);
//               setLoading(false);
//               setError(err.message)
//            }
//       )
//     }, [row]);

    // const columns = [
    //   { key: "zkp_InvoiceLabel", header: "InvoiveID" },
    //   { key: "YearQuarter", header: "YearQuarter" },
    //   { key: "labelID", header: "labelID" },
    //   { key: "LabelName", header: "LabelName" },
    //   { key: "zst_LineCount", header: "LineCount" },
    //   { key: "zst_QtySales", header: "QtySales" },
    //   { key: "zc_LabelCurrencyPref", header: "LabelCurrency" },
    //   { key: "zst_USDRev", header: "USDRev" },
    //   { key: "zst_USDShare", header: "USDShare" },
    //   { key: "zst_GBPRev", header: "GBPRev" },
    //   { key: "zst_GBPShare", header: "GBPShare" },
    //   { key: "AgentCommRate", header: "AgentComm_Rate" },
    //   { key: "CommSource", header: "CommSource" },
    //   { key: "AgentUSDShare", header: "Agent_USDShare" },
    //   { key: "AgentGBPShare", header: "Agent_GBPShare" },
     
    // ];

    

    const displayDetails = (row)=>{
      console.log(row)
    }
  
   function formatCurrency(amount,type, currencySymbol = "£") {
      if (isNaN(amount)) {
        return "Invalid amount";
      }
      const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: type,
        currencyDisplay: "symbol", // Use currency symbol instead of code
        minimumFractionDigits: 3, // Ensures two decimal places even for whole numbers
      });
    
      return formatter.format(amount);
    }

    const handleQuater = (e)=>{
      setQuater(e.target.value)
    }
  

  return (
    <main className='mt-2 bg-slate-50'>
        <div className='bg-black justify-between py-3 px-10 flex gap-4'>
          <p className='text-sm font-semibold text-white'>{row?.LabelName_pkt}_account-summary</p>
          <p onClick={()=>setLabelDetailsDisplay(false)} className='font-bold text-white text-2xl'><LuXCircle/></p>
        </div>

        <section className='grid grid-cols-12 m-10 gap-8'>

            <div className=' col-span-6 p-5'>
                <p className='text-sm font-bold text-gray-600 mb-2'>LABEL REFERENCE</p>

                <div className='flex flex-col'>
                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >Label Name</p>
                     <p className='text-sm font-semibold' >{row?.LabelName_pkt}</p>
                    </div>
                    
                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >Royalty rate</p>
                     <p className='text-sm font-semibold' >{row?.LabelRoyaltyRate}</p>
                    </div>
                

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >Currency Preferred</p>
                     <p className='text-sm font-semibold' >{row?.CurrencyPreferred}</p>
                    </div>

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >Label ID</p>
                     <p className='text-sm font-semibold' >{row?.zkp_Label}</p>
                    </div>

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >Label Agent</p>
                     <p className='text-sm font-semibold' >{`${row?.NameFirst} ${row?.NameLast}`}</p>
                    </div>

                </div>

            </div>

           
            <div className=' col-span-6 bg-white p-5'>
              <div className='flex gap-6'>
              
              <button onClick={()=>setOpenExpenses(true)} className='text-xs flex text-center justify-center bg-slate-500 text-white items-center py-2 px-auto w-[100px]'>Add expenses</button>
              <button onClick={()=>setOpenHistory(true)} className='text-xs flex text-center justify-center bg-green-600 text-white items-center py-2  px-4 '>view expenses history</button>
              {/* <ExportButton agentID={row?.zkp_Agent} fname={row?.NameFirst} lname={row?.NameLast}/>
              <ExportButtonPdf agentID={row?.zkp_Agent} fname={row?.NameFirst} lname={row?.NameLast}/> */}
              {/* <div className='flex p-3 border gap-2'>
              <select className='border items-center text-blue-800 text-xs h-[30px] w-[100px]' onChange={handleQuater}>
                <option>
                Select quater
                </option>
                <option value="2024Q1">
                2024Q1
                </option>
              </select>
              <ExportReportButton agentID={row?.zkp_Agent} fname={row?.NameFirst} lname={row?.NameLast} agentSummaryData={agentSummaryData} quater={quater} />
              </div> */}
              </div>
             </div>

        </section>

        {/* <section className='w-full mt-10 border-t-2 pt-10'>
        {loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err.message} please reload the page</p>:
        <DynamicTable
           data={agentSummaryData}
           columns={columns}
           click={displayDetails}
           showSearch = {false}
            />
    }
      
      </section> */}
      <ExpensesModal open={openExpenses} setOpen={setOpenExpenses} accountType="Label" fullName={row?.LabelName_pkt} id={row?.zkp_Label}/>
      <ExpenseHistoryModal open={openHistory} setOpen={setOpenHistory} id={row?.zkp_Label}/>
    </main>
  )
}

export default LabelDetailSumarry


