// TextToSpeechComponent.js

import React, { useState, useEffect } from 'react';
import { useSpeechSynthesis } from 'react-speech-kit';
//import Recorder from 'recorder-js';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';

const TextToSpeechComponent = () => {
  const [text, setText] = useState('');
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const { speak, cancel, speaking, voices } = useSpeechSynthesis();
  let audioChunks = [];

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = handleDataAvailable;
        mediaRecorder.onstop = handleRecordingStop;
        setMediaRecorder(mediaRecorder);
      })
      .catch((error) => {
        console.error('Error accessing microphone:', error);
      });
  }, []);

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      audioChunks.push(event.data);
    }
  };

  const handleRecordingStop = () => {
    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
    setAudioSrc(URL.createObjectURL(audioBlob));
    console.log("Recording stopped");
    audioChunks = [];
    setRecording(false);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleVoiceChange = (e) => {
    const selectedVoiceName = e.target.value;
    const selectedVoice = voices.find((voice) => voice.name === selectedVoiceName);
    setSelectedVoice(selectedVoice);
  };

  const handleToggleSpeech = () => {
    if (speaking) {
      cancel();
      setRecording(false);
    } else {
      let utterance;
      if (selectedVoice) {
        utterance = new SpeechSynthesisUtterance(text);
        utterance.voice = selectedVoice;
      } else {
        utterance = new SpeechSynthesisUtterance(text);
      }
     
      speak(utterance);
      startRecording();
      
    }
  };

  useEffect(()=>{
      if (speaking) {
        console.log("still speaking")
      }else{
        stopRecording()
      }
  },[speaking])

  const startRecording = () => {
    if (mediaRecorder) {
      setRecording(true);
      mediaRecorder.start();
      console.log("Recording started");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      handleRecordingStop()
    }
  };

  const handleDownload = () => {
    if (audioSrc) {
      const link = document.createElement('a');
      link.href = audioSrc;
      link.download = 'generated_speech.wav';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAudioSrc(null);
    }
  };

  return (
    <div>
    <Navbar/>
      <div className="flex flex-col justify-center items-center mx-auto mt-12 py-5  border-2 w-[70%]">
      <textarea className='w-[500px] h-[200px] border-blue-500 rounded-md border p-3' value={text} onChange={handleTextChange} placeholder="Enter text here..." />
      <div className='flex mt-4 justify-between'>
      <select className='w-[250px] border-blue-500 border ' onChange={handleVoiceChange}>
        <option>--Select Voice--</option>
        {voices.map((voice) => (
          <option key={voice.name} value={voice.name}>
            {voice.name}
          </option>
        ))}
      </select>
      <div className='ml-5 flex'>
      <button className={`${!speaking ? 'bg-blue-900' : 'bg-red-500' } text-white mx-3 py-2 px-3 text-sm rounded-md `} onClick={handleToggleSpeech}> {speaking ? 'Stop' : 'Play'}</button>
      <button className={`${audioSrc ? 'bg-green-500': 'bg-gray-400' } text-white py-2 px-3 text-sm rounded-md`} onClick={handleDownload} disabled={!audioSrc} >
        {audioSrc ? "Download Speech":"!Download Not Ready"}
      </button>
      </div>
      </div>
      {recording && <p className='mt-2 text-sm'>Recording in progress...</p>}
      {recording && <button className='bg-gray-500 text-white mt-3 mx-3 py-2 px-3 text-sm rounded-md'  onClick={stopRecording} disabled={!recording}>
        Stop Recording
      </button>}
      </div>
     <Footer/>
    </div>
  );
};

export default TextToSpeechComponent;
