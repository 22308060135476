import React from 'react';
import { Document, Page, Text, View} from '@react-pdf/renderer';

function convertToPDF(data) {
  return (
    <Document>
      <Page>
        <View>
          {data.map((item, index) => (
            <Text key={index}>{item.text}</Text>
          ))}
        </View>
      </Page>
    </Document>
  );
}

export default convertToPDF;