import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import styled from "styled-components";
import Navbar from '../../navbar/Navbar'
import DragAndDrop from '../../draganddrop/DragAndDrop';
import Footer from '../../footer/Footer'

//import Audio from '../audio'


const Fileupload = ()=> {
  
  const [audios, setAudios] = useState([]);
    
    return (
      <>
       <Navbar/>
      <div className="w-full mt-5 mb-0">
        <div className=" w-[80%] mx-auto h-auto  ">
                <DragAndDrop/>
    
        </div>
      </div>
     <Footer/>
    </>
    )
  }
 

export default Fileupload

const ModalContainer = styled.div`
  background: white;
  opacity:1;
  min-height: auto;
  padding:10px;
  width: 600px;
  max-width: 400px;
  border-radius: 15px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;
