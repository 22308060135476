import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable2 from '../ui/DynamicTable2';
import {api,base} from '../../utils/api'
import DataLoader from '../../smallLoader/DataLoader';
import AddBalanceModal from './AddBalance';
import PromptModal from '../ui/PromptModal';
import UpdateJointLabelsBalanceModal from './UpdateJointLabelsBalanceModal';
import UpdateSingleLabelsBalanceModal from './UpdateSingleLabelsBalanceModal';
import UpdateAgentBalanceModal from './UpdateAgentBalanceModal';


function AgentsBalance({setNextStage}) {

const [agentData, setAgentData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(500);
const [loading, setLoading] = useState(false);
const [openPrompt, setOpenPrompt] = useState(false);
const [agent, setAgent] = useState(null);
const [expenseDisplay,setExpenseDisplay] = useState(false)
const [err,setErr] = useState(null)
const [searchQuery, setSearchQuery] = useState('');
const [colMessage, setColMessage] = useState(null);
const [openJointBalanceUpdate, setOpenJointBalanceUpdate] = useState(false);
const [openSingleBalanceUpdate, setOpenSingleBalanceUpdate] = useState(false);
const [openAgentBalanceUpdate, setOpenAgentBalanceUpdate] = useState(false);

const getAgents = (page,page_size)=>{
    setLoading(true)
    axios.get(api + `/agents/?page=${page}&page_size=${page_size}`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.results;
         setAgentData(arr)
       
         setLoading(false)
         console.log("response.data ok")
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getAgents(currentPage,page_size);
  }, [currentPage]);


  const displayExpense = (row)=>{
    console.log(row)
    setAgent(row)
    setExpenseDisplay(true)
  }


  const columns = [
    { key: "zkp_Agent", header: "Agent ID" },
    { key: "NameFull", header: "Full Name" },
    { key: "IsActive", header: "IsActive" },
   
  ];

  const agentSearch = async (col,colQuery) => {
    try {
      const response = await axios.get(base + `/search-agent/${col}/${colQuery}`);
      let arr = response.data.results;
      console.log("this is arr",response.data.results)
      setAgentData(response.data.results);
      if (response.data.results.length < 1){
        setColMessage(`No result found for ${colQuery} `)
      }
      
    } catch (error) { 
      console.log(`Not Found : Error fetching search results for ${colQuery}`)
      
    }
  };

 

  useEffect(() => {
    if (colMessage) {
      
      const timer = setTimeout(() => {
        setColMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [colMessage]);



  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>
    <div className='flex gap-4 justify-end'>
    <button onClick={()=>setOpenSingleBalanceUpdate(true)} className='flex bg-gray-600 text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 '>Update single account balances</button>
    <button onClick={()=>setOpenJointBalanceUpdate(true)} className='flex bg-slate-950 text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 '>Update joint account balance</button>
    <button onClick={()=>setOpenAgentBalanceUpdate(true)} className='flex bg-blue-700 text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 '>Update agent balances</button>
    {/* <button onClick={()=>setOpenBalanceUpdate(true)} className='flex bg-yellow-400 text-black justify-end py-2 px-6 text-xs rounded-sm mt-4 '>Update Label Balance</button>
    <button onClick={()=>setOpenBalance(true)} className='flex bg-blue-900 text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 '>Upload Label Balance</button> */}
        <button onClick={()=>setOpenPrompt(true)} className='flex bg-black text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 mr-14'>{"Next >>"}</button>
        </div>

{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <div className='rounded-md border-2 mt-5 md:mx-28 px-5'> 
      <p className='font-bold text-center text-lg my-3 text-green-700'>Add Agents Balance</p>
      <DynamicTable2
      data={agentData}
      columns={columns}
      addDeduction={displayExpense}
      showSearch={true}
      findAgent={agentSearch}
      setSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      colMessage={colMessage}
      type="balance"
    />
    </div> 
    }

               
    
    </div>
    <AddBalanceModal open={expenseDisplay} setOpen={setExpenseDisplay} accountType="Agent" name={agent?.NameFull} id={agent?.zkp_Agent}/>
    <PromptModal stage="add_agent_expenses" setNextStage={setNextStage} message="Are you done with adding Agents Balance ?" open={openPrompt} setOpen={setOpenPrompt} />
    <UpdateJointLabelsBalanceModal setOpen={setOpenJointBalanceUpdate} open={openJointBalanceUpdate}/>
    <UpdateSingleLabelsBalanceModal setOpen={setOpenSingleBalanceUpdate} open={openSingleBalanceUpdate}/>
    <UpdateAgentBalanceModal setOpen={setOpenAgentBalanceUpdate} open={openAgentBalanceUpdate}/>

    </div>
  )
}

export default AgentsBalance

