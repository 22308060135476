import React, { useState, useRef, useEffect } from 'react';
import {FiDownload} from 'react-icons/fi'

const CustomAudioPlayer = ({ audioSrc, backgroundImage }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  //let Src = `http://184.105.4.89:8000/media/${audioSrc}`

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleProgress = (e) => {
    const audio = e.target;
    setPlayed(audio.currentTime / audio.duration);
    setCurrentTime(audio.currentTime);
  };

  const handleDuration = (e) => {
    setDuration(e.target.duration);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(audioSrc);
      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = audioSrc  // Src.split('/')[3]; Specify the desired filename here
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading audio:', error);
    }
  };

  return (
    <div
      style={{
        position: 'relative',
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
        width: '120px',
        height: '40px', // Adjust the height as needed
        padding:'2px'
      }}
    >
      <audio
        ref={audioRef}
        controls
        onPlay={handlePlayPause}
        onPause={handlePlayPause}
        onTimeUpdate={handleProgress}
        onDurationChange={handleDuration}
        style={{
          width: '90%',
          height: '25%',
          position: 'absolute',
          background:'transparent',
          bottom: '4px',
          left: '4px',
          //opacity: '0.1', // Adjust the opacity for the background image
        }}
      >
        
        <source src={audioSrc} type="audio/wav" />
        
        Your browser does not support the audio element.
      </audio>
      <button
        onClick={handleDownload}
        style={{
          position: 'absolute',
          top: '5px',
          right: '5px',
          background: 'transparent',
          color: 'white',
          border: 'none',
          cursor: 'pointer',
        }}
      >
        <FiDownload className='text-[#fff]'/>
      </button>
      <div
        style={{
          position: 'absolute',
          top: '5px',
          left: '5px',
          color: 'white',
          fontSize:'10px'
        }}
      >
        {formatTime(currentTime)} / {formatTime(duration)}
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
