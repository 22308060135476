import React,{useEffect,useRef, useState} from 'react';
import LoaderSmall from '../../smallLoader/LoaderSmall';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {Link,useNavigate} from 'react-router-dom';
import Navbar from '../../navbar/Navbar';
import {HiArrowNarrowLeft} from 'react-icons/hi'
import {AiOutlineClose} from 'react-icons/ai'
import Footer from '../../footer/Footer';
import {api,base} from '../../utils/api'

const ViewInfo = ()=>{
 
  const [openStem,setOpenStem] = useState(false)
  const [openArtist,setOpenArtist] = useState(false)
  const [modalOpen,setModalOpen] = useState(false)
  const [singlefile,setSinglefile] = useState("")
  const [splitloader, setSplitLoader] = useState(false);
  
  
  const navigate = useNavigate()
  const params = useParams();
  
useEffect(()=>{
   getFileToEdit();
   
},[])



const handleModal = ()=>{
  setModalOpen(prev=>!prev)
}

const getFileToEdit = ()=>{ 
  axios.get(base + `/getsinglefile/${params.id}`).then(
      response =>{
       setSinglefile(response.data)
      }
  ).catch(
       err =>{
       console.log(err)
       }
  )
}



  return(
    <>
     <Navbar/>
     <Link className='flex mt-3 ml-10 text-lg font-bold' to="/dashboard"><HiArrowNarrowLeft size={20} className='mt-1'/> Back</Link>
      <div className="w-full items-center justify-center mx-auto flex flex-col px-4 mt-0 mb-[70px]">  
     <div className='p-4 h-full md:w-[900px] border rounded-md'>
      <div className='flex flex-col justify-between md:flex-row'>
          <p className='font-bold md:my-5 my-2 text-lg'>Full Information </p>
          <div className='md:mt-3 mb-3 ml-10 md:ml-0 flex'>
          <button type="button" onClick={()=>{setOpenArtist(prev=>!prev)}}  className="bg-green-500 rounded-sm h-[40px] mx-2  px-2 text-white text-sm">{openArtist ? (<AiOutlineClose size={25}/>) : "View Artist Info"}</button>
          <button type="button" onClick={handleModal} className="bg-blue-900 rounded-sm h-[40px] mx-2  px-2 text-white text-sm">Update Artist</button>
        
          
          </div>
          </div>

       {openArtist &&   <div class="relative mx-auto py-8 w-[500px] px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 class="text-blue-800 font-lg font-bold tracking-normal leading-tight mb-4">Artist Full Information</h1>
                        <table class="min-w-full">
          <thead class="bg-white border-b">
            <tr>
            <th scope="col" class="text-md font-bold text-gray-900 px-6 py-4 text-left">
                
              </th>
              <th scope="col" class="text-md font-bold text-gray-900 px-6 py-4 text-left">
                Tag
              </th>
              <th scope="col" class="text-md font-bold text-gray-900 px-6 py-4 text-left">
                Information
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm font-medium text-gray-900 px-6 py-4 whitespace-nowrap">
                Name 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.artist}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm font-medium text-gray-900 px-6 py-4 whitespace-nowrap">
                Artist Url
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.artistUrl}
              </td>
            </tr>
            </tbody>
            </table>  
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={()=>{setOpenArtist(prev=>!prev)}}>
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>}

         
     
                  

  <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div class="overflow-hidden">
        <table class="min-w-full">
          <thead class="bg-white border-b">
            <tr>
            <th scope="col" class="text-md font-bold text-gray-900 px-6 py-4 text-left">
                
              </th>
              <th scope="col" class="text-md font-bold text-gray-900 px-6 py-4 text-left">
                Tag
              </th>
              <th scope="col" class="text-md font-bold text-gray-900 px-6 py-4 text-left">
                Information
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm font-medium text-gray-900 px-6 py-4 whitespace-nowrap">
                Arranger 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.arranger}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm font-medium text-gray-900 px-6 py-4 whitespace-nowrap">
                Artist
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.artist}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Artist Url 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.artistUrl}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                BPM
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.bpm}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                C Line 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.c_line}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Data Type
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.dataType}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Deleted 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.deleted}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Display UPC
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.displayUPC}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Download 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.download}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Error
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.error}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Exclusive 
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.exclusive}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Genre
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.genre}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                GenreAlt
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.genreAlt}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                GenreSub
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.genreSub}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                GenreSubAlt
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.genreSubAlt}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Hidden Track
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.hiddenTrack}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                ISRC
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.ISRC}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Itunes Link
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.itunesLink}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Label Catalog No
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.labelCatalogNo}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Label Name
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.labelName}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Language
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.language}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Manufacturer UPC
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.manufacturerUPC}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Master CarveOuts
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.masterCarveouts}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                P Line
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.p_line}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                Parental Advisory
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.parentalAdvisory}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Preview Clip Duration
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.previewClipDuration}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Preview Clip StartTime
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.previewClipStartTime}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Price Band
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.priceBand}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Producer
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.producer}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Publisher
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.publisher}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Recording Artist
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.recordingArtist}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Release Name
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.releaseName}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Release Date
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.releaseDate}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Royalty Rate
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.royaltyRate}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Sales End Date
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.salesEndDate}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Sales Start Date
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.salesStartDate}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Song Version
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.songVersion}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Territories
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.territories}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Territories CarveOuts
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.territoriesCarveOuts}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Title
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.title}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Total Tracks
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.totalTracks}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Total Volumes
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.totalVolumes}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Track Duration
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.trackDuration}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Track No
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.trackNo}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium  px-6 py-4 whitespace-nowrap">
              Vendor Name
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.vendorName}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Volume No
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.VolumeNo}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              WholeSale Price
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.wholesalePrice}
              </td>
            </tr>
            <tr class="bg-white border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              Writer
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              {singlefile?.data?.[0]?.writer}
              </td>
            </tr>
            <tr class="bg-gray-100 border-b">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">#</td>
              <td class="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
              ZIDKey_UPCISRC
              </td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
               {singlefile?.data?.[0]?.zIDKey_UPISRC}
              </td>
            </tr>
           
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>

{/*--------Modal------*/}

{/*class="py-12 bg-gray-700 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0"*/}

{modalOpen && <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Enter Artist Details</h1>
                        <label for="name" class="text-gray-800 text-sm font-bold leading-tight tracking-normal">Full Name</label>
                        <input id="name" class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Full Name" />
                        <label for="name" class="text-gray-800 text-sm font-bold leading-tight tracking-normal"> Gender</label>
                        <input id="name" class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Gender" />
                        <label for="name" class="text-gray-800 text-sm font-bold leading-tight tracking-normal"> Country</label>
                        <input id="name" class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Country" />
                        <label for="name" class="text-gray-800 text-sm font-bold leading-tight tracking-normal"> Artist Url</label>
                        <input id="name" class="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="Artist Url" />
                       
                        <div class="flex items-center justify-end w-full">
                        <button class="focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-gray-400 mr-3 bg-gray-100 transition duration-150 text-gray-600 ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm" onClick={handleModal}>Cancel</button>
                            <button onClick={handleModal} class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded text-white px-8 py-2 text-sm">Update</button>
                            
                        </div>
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={handleModal} >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>}
    </>
  )
}

export default ViewInfo;