import { toast } from "sonner";


export const notify = (message, type) => {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  };


  export const readFromLocalStorage = (key, initialValue) => {
  
        const storedValue = localStorage.getItem(key);
        return storedValue ? JSON.parse(storedValue) : initialValue;
  };
  
  export const writeToLocalStorage = (key, value) => {
    
        localStorage.setItem(key, JSON.stringify(value));
    
  };
  