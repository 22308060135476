import React from 'react'
import {CgArrowLongRight,CgArrowLongLeft} from 'react-icons/cg'
import {BiEdit} from 'react-icons/bi'
import {GiDrumKit} from 'react-icons/gi'
import {CiNoWaitingSign} from 'react-icons/ci'
import {CgPiano} from 'react-icons/cg'
import {FaMicrophone,FaGuitar,FaDrum} from 'react-icons/fa'
import {RiDeleteBin6Line} from 'react-icons/ri'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import {Link, useNavigate} from 'react-router-dom'
import CustomAudioPlayer from '../../audioPlayer/CustomAudioPlayer'


const AudioDisplay = ({vocals_spleet,splitloader, audios,handleStem,
    base,handleUpload,openDropdown,dropdown,deleteLoader,deleteAudio,
    id,vocalStem,spliteMessage,instrumentalStem,drumStem,closeDropdown,
     pianoStem,bassStem,piano_spleet,drums_spleet,instrumentals_spleet,bass_spleet})=>{

    const navigate = useNavigate();

    return( 
      <>
        {
         audios.map((audio,index)=>{
           return(
            <tr key={index} className='text-xs'>
           <td className='border-collapse border text-xs border-slate-400 '> {index+1} </td>
            <td className='border-collapse border border-slate-400 py-1'>
              {audio?.audio ? <>
              <p className='m-1'>
              
              <CustomAudioPlayer audioSrc={audio.audio.includes("media") ? audio.audio
              :`${base}/media/${audio.audio}`} backgroundImage={"https://wallpapercave.com/wp/wp4269307.jpg"}/>
              </p>
              
  
          </>
           :<button type="button" 
           //onClick={()=>handleUpload(audio.id)}  
           className="bg-gray-400 rounded-sm h-[20px] mx-1  px-2 text-white text-xs">No Audio</button>}
         
          </td>
          <td className='border-collapse border border-slate-400 py-1'>
       <div className={`inline-flex ${audio?.audio ? 'bg-pink-600 border': 'bg-gray-400 border' } rounded-md m-1`}>
      <p
          className="px-2 py-1 text-xs text-[#ffffff] rounded-l-md"
      >
         stems
         
      </p>

      <div className="relative">
      {audio?.audio ? 
          <>
          {!dropdown &&  <button
          disabled={audio?.audio === null ? true : false}
             onClick={()=>{openDropdown(audio.id);handleStem(audio.id)}}
              type="button"
              className="inline-flex items-center justify-center h-full px-2 text-[#ffffff] rounded-r-md "
          >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
              >
                  <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 9l-7 7-7-7"
                  />
              </svg>
          </button>}
          {dropdown && id == audio.id && <button className="inline-flex items-center justify-center h-full px-2 text-[#ffffff] rounded-r-md " 
          onClick={()=>{closeDropdown(audio.id)}} >
            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
            </svg>
        </button>}
          </>
          :<CiNoWaitingSign className='text-[#fff]  mt-2 mr-2'/>}

          {dropdown && id == audio.id && <div className="absolute left-[-40px] w-[350px] mt-1 z-50  origin-top-right bg-gray-300 shadow-lg">
          <a className="flex px-2 py-2 m-1 justify-center items-center cursor-pointer rounded-sm text-sm bg-gray-100"
                  >   
           { vocalStem.length > 0 ? (
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Vocal stem</p>
            {vocalStem.slice(0, 1).map(saudio => {
                     return(
                   <div className="">
                   <label for="name" class="text-gray-800 text-xs font-semibold">{
                    saudio.splited_audio.split("/")[3]
                     }</label>
                    <audio controls className='h-3'>
                   <source src={`${base}/${saudio.splited_audio}`}/>
                  </audio>
                     </div>)
                     })}
            </div>
           ):(
            
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Vocals</p>
            <div className='flex '>
              <h3 className='flex font-semibold text-[10px]'> Vocal <FaMicrophone className='mx-1'/> stem not generated yet</h3>
             <button type="button" 
             onClick={()=>vocals_spleet(id)} 
             className="bg-[#cc2e2e] items-center flex rounded-sm h-[20px] p-1  ml-1 text-white text-[10px]">{splitloader ? <><LoaderSmall/> Please wait..</> : "Generate_Vocals"}</button>
            {/* <p><LoaderSmall/> Please wait..</p> */}
            </div>
            <p className='text-red-500 text-center text-[8px] '>{spliteMessage}</p>
            </div>
         )}
            
        </a>
        <a className="flex px-2 py-2 m-1 justify-center items-center cursor-pointer rounded-sm text-sm bg-gray-100"
                  >   
           { instrumentalStem.length > 0 ? (
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Instrumental stem</p>
            {instrumentalStem.slice(0, 1).map(saudio => {
                     return(
                   <div className="">
                   <label for="name" class="text-gray-800 text-xs font-semibold">{
                    saudio.splited_audio.split("/")[3]
                     }</label>
                    <audio controls className='h-3'>
                   <source src={`${base}/${saudio.splited_audio}`}/>
                  </audio>
                     </div>)
                     })}
            </div>
           ):(
            
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Instrumentals</p>
            <div className='flex '>
              <h3 className='flex font-semibold text-[10px]'> Instrumental <GiDrumKit className='mx-1'/> stem not generated yet</h3>
             <button type="button" 
             onClick={()=>instrumentals_spleet(id)} 
             className="bg-[#cc2e2e] items-center flex rounded-sm h-[20px] p-1  ml-1 text-white text-[10px]">{splitloader ? <><LoaderSmall/> Please wait..</> : "Generate_Instrumentals"}</button>
            {/* <p><LoaderSmall/> Please wait..</p> */}
            </div>
            <p className='text-red-500 text-center text-[8px] '>{spliteMessage}</p>
            </div>
         )}
            
        </a>

        <a className="flex px-2 py-2 m-1 justify-center items-center cursor-pointer rounded-sm text-sm bg-gray-100"
                  >   
           { drumStem.length > 0 ? (
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Drum stem</p>
            {drumStem.slice(0, 1).map(saudio => {
                     return(
                   <div className="">
                   <label for="name" class="text-gray-800 text-xs font-semibold">{
                    saudio.splited_audio.split("/")[3]
                     }</label>
                    <audio controls className='h-3'>
                   <source src={`${base}/${saudio.splited_audio}`}/>
                  </audio>
                     </div>)
                     })}
            </div>
           ):(
            
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Drums</p>
            <div className='flex '>
              <h3 className='flex font-semibold text-[10px]'> Drum <FaDrum className='mx-1'/> stem not generated yet</h3>
             <button type="button" 
             onClick={()=>drums_spleet(id)} 
             className="bg-[#cc2e2e] items-center flex rounded-sm h-[20px] p-1  ml-1 text-white text-[10px]">{splitloader ? <><LoaderSmall/> Please wait..</> : "Generate_Drum"}</button>
            {/* <p><LoaderSmall/> Please wait..</p> */}
            </div>
            <p className='text-red-500 text-center text-[8px] '>{spliteMessage}</p>
            </div>
         )}
            
        </a>

        <a className="flex px-2 py-2 m-1 justify-center items-center cursor-pointer rounded-sm text-sm bg-gray-100"
                  >   
           { pianoStem.length > 0 ? (
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Piano stem</p>
            {pianoStem.slice(0, 1).map(saudio => {
                     return(
                   <div className="">
                   <label for="name" class="text-gray-800 text-xs font-semibold">{
                    saudio.splited_audio.split("/")[3]
                     }</label>
                    <audio controls className='h-3'>
                   <source src={`${base}/${saudio.splited_audio}`}/>
                  </audio>
                     </div>)
                     })}
            </div>
           ):(
            
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Piano</p>
            <div className='flex '>
              <h3 className='flex font-semibold text-[10px]'> Piano <CgPiano className='mx-1'/> stem not generated yet</h3>
             <button type="button" 
             onClick={()=>piano_spleet(id)} 
             className="bg-[#cc2e2e] items-center flex rounded-sm h-[20px] p-1  ml-1 text-white text-[10px]">{splitloader ? <><LoaderSmall/> Please wait..</> : "Generate_Piano"}</button>
            {/* <p><LoaderSmall/> Please wait..</p> */}
            </div>
            <p className='text-red-500 text-center text-[8px] '>{spliteMessage}</p>
            </div>
         )}
            
        </a>

        <a className="flex px-2 py-2 m-1 justify-center items-center cursor-pointer rounded-sm text-sm bg-gray-100"
                  >   
           { bassStem.length > 0 ? (
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Bass stem</p>
            {bassStem.slice(0, 1).map(saudio => {
                     return(
                   <div className="">
                   <label for="name" class="text-gray-800 text-xs font-semibold">{
                    saudio.splited_audio.split("/")[3]
                     }</label>
                    <audio controls className='h-3'>
                   <source src={`${base}/${saudio.splited_audio}`}/>
                  </audio>
                     </div>)
                     })}
            </div>
           ):(
            
            <div className='flex flex-col'>
            <p className='font-semibold mt-[-3px] text-[10px] text-blue-800'>Bass</p>
            <div className='flex '>
              <h3 className='flex font-semibold text-[10px]'> Bass <FaGuitar className='mx-1'/> stem not generated yet</h3>
             <button type="button" 
             onClick={()=>bass_spleet(id)} 
             className="bg-[#cc2e2e] items-center flex rounded-sm h-[20px] p-1  ml-1 text-white text-[10px]">{splitloader ? <><LoaderSmall/> Please wait..</> : "Generate_Bass"}</button>
            {/* <p><LoaderSmall/> Please wait..</p> */}
            </div>
            <p className='text-red-500 text-center text-[8px] '>{spliteMessage}</p>
            </div>
         )}
            
        </a>
        
          </div>}
      </div>
  </div>

          </td>
             
          <td className='border-collapse border border-slate-400 '> {audio.Artist} </td>
          <td className='border-collapse border border-slate-400 '> {audio.Title} </td>
          <td className='border-collapse border border-slate-400 '> {audio.LabelCatalogNo} </td>
            <td className='border-collapse border border-slate-400 '> {audio.ReleaseName} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.Deleted} </td>
            <td className='border-collapse border border-slate-400 '> {audio.DisplayUPC} </td>
            <td className='border-collapse border border-slate-400 '> {audio.BPM} </td>
            <td className='border-collapse border border-slate-400 '> {audio.ISRC} </td>
            <td className='border-collapse border border-slate-400 '> {audio.Genre} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.is_processed === false ? "N":"Y"} </td>
            <td className='border-collapse border border-slate-400 '> {audio.Keyz} </td>
            <td className='border-collapse border border-slate-400 '> {audio.Mood} </td>
            <td className='border-collapse border border-slate-400 '> {audio.LabelName} </td>
            <td className='border-collapse border border-slate-400 '> {audio.ReleaseDate} </td>
            <td className='border-collapse border text-center border-slate-400 '>{audio.TrackNo ? Number(audio.TrackNo) : ""} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.Download} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.DataType} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.C_Line} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.converted} </td>
            <td className='border-collapse border text-center border-slate-400 '> {audio.TotalTracks ? Number(audio.totalTracks) : ""} </td>
            
          
          <td className='border-collapse border border-slate-400'><button onClick={()=>{navigate(`/updatefile/${audio.id}`,{replace:true})}} className="flex bg-blue-900  rounded-sm py-1 px-1 m-1 text-white text-xs" ><BiEdit className='mt-1'/></button></td>

          <td className='border-collapse border border-slate-400'><button type="button" className="bg-blue-500 rounded-sm py-1 px-1 mx-1 my-2 text-white flex text-xs" onClick={()=>{navigate(`/view-info/${audio.id}`,{replace:true})}}>view</button></td> 
           <td className='border-collapse border border-slate-400'><button type="button" className="bg-gray-500 rounded-sm py-1 px-1 m-1 text-white flex text-xs" onClick={()=>{deleteAudio(audio.id)}}>{deleteLoader === audio.id ? <LoaderSmall/>:<RiDeleteBin6Line/>}</button></td>  
          </tr>
       )
      })
      }
</>
    )
}

export default AudioDisplay;

