import React,{useState} from 'react'
import Data from './sub-components/Data';
import UploadSummary from './sub-components/UploadSummary'
import UploadTemplate from './sub-components/UploadTemplate';
import AgentsDeduction from './sub-components/AgentsDeduction';
import LabelsDeduction from './sub-components/LabelsDeduction';
import AgentsExpenses from './sub-components/AgentsExpenses';
import LabelsExpenses from './sub-components/LabelsExpenses';
import ReportSuccess from './sub-components/ReportSuccess';
import AgentsBalance from './sub-components/AgentsBalance';

function Uploads() {

    const [activeTab, setActiveTab] = useState("upload_summary");

const onTabSelect = (tab) => {
  setActiveTab(tab);
};

  return (
    <main>
    <div className='bg-gray-100 flex justify-between px-10 py-3'>
     <p className='text-xs font-semibold text-gray-500'>Hello Digger</p>
     <p className='text-xs font-semibold text-gray-500'>Date: 21/06/2021</p>
    </div>

    <header className="flex items-center overflow-x-auto  w-full">
        {subNav?.map((nav, index) => (
          <h3
            onClick={() => onTabSelect(nav.tag)}
            key={index}
            className={`flex border w-full font-medium items-center justify-center text-center text-xs  cursor-pointer  py-2 transition-all ${
              activeTab === nav?.tag
                ? "text-gray-500 bg-gray-50"
                : "text-black  bg-gray-400 "
            } `}
          >
            {nav?.name}
          </h3>
        ))}
      </header>
    
      <section className='w-full'>
      {activeTab === "add_agent_balance" && <AgentsBalance setNextStage={setActiveTab}/>}
      {activeTab === "add_agent_expenses" && <AgentsExpenses setNextStage={setActiveTab}/>}
      {activeTab === "add_label_expenses" && <LabelsExpenses setNextStage={setActiveTab}/>}
     {activeTab === "upload_summary" && <UploadSummary  setNextStage={setActiveTab} />}
     {activeTab === "upload_template" && <UploadTemplate setNextStage={setActiveTab}/>}
     {activeTab === "agent_deductions" && <AgentsDeduction setNextStage={setActiveTab}/>}
     {activeTab === "label_deductions" && <LabelsDeduction setNextStage={setActiveTab}/>}
     {activeTab === "report_success" && <ReportSuccess/>}

      </section>
    </main>
  )
}

export default Uploads

const subNav = [
    {id: 1, name: "Step 1 : Upload Label Summary",tag:"upload_summary"},
    {id: 2, name: "Step 2 : Upload Agent Template",tag:"upload_template"},
    {id: 3, name: "Step 3 : Add Balances",tag:"add_agent_balance"},
    {id: 4, name: "Step 4 : Add Agents Expenses",tag:"add_agent_expenses"},
    {id: 5, name: "Step 5 : Add Labels Expenses",tag:"add_label_expenses"},
    {id: 6, name: "Step 6 : Agents deductions ",tag:"agent_deductions"},
    {id: 7, name: "Step 7 : Labels deductions",tag:"label_deductions"},
    
  ];