import React,{useEffect,useState} from 'react'
import { LuXCircle } from "react-icons/lu";
import {api,base} from '../../utils/api'
import axios from 'axios'
import DataLoader from '../../smallLoader/DataLoader'
import DynamicTable from '../ui/DynamicTable';
import ExportButton from '../ui/ExportButton';
import ExportButtonPdf from '../ui/ExportButtonPdf';
import ExportReportButton from '../ui/ExportReportButton';
import ExpensesModal from './ExpensesModal';
import ExpenseHistoryModal from './ExpenseHistoryModal';

function AgentComSumarry({row,setAgentDetailsDisplay}) {
  const [agentSummaryData,setAgentSummaryData] = useState(null);
  const [error,setError] = useState(null);
  const [loading,setLoading] = useState(false)
  const [usdRev,setUsdRev] = useState(null)
  const [gbpRev,setGbpRev] = useState(null)
  const [usdShare,setUsdShare] = useState(0)
  const [gbpShare,setGpbShare] = useState(0)
  const [lineCount,setLineCount] = useState(null)
  const [qtySales,setQtySales] = useState(null)
  const [err,setErr] = useState(null)
  const [agentUsdShare,setAgentUsdShare] = useState(0)
  const [agentGbpShare,setAgentGpbShare] = useState(0)
  const [quater,setQuater] = useState(null)
  const [openExpenses,setOpenExpenses] = useState(false)
  const [openHistory,setOpenHistory] = useState(false)
  console.log(row)

  useEffect(() => {
    const agentID = row?.zkp_Agent
      axios.get(base + `/agent-labels/${agentID}`).then(
          response =>{
              const res = response?.data?.data
              console.log("agent-labels:",res)
              setAgentSummaryData(response?.data?.data?.label_list);
              setLineCount(response?.data?.data?.line_count)
              setQtySales(response?.data?.data?.quantity_sales)
              setGbpRev(response?.data?.data?.gbp_rev)
              setUsdRev(response?.data?.data?.usd_rev)
              setUsdShare(response?.data?.data?.usd_share)
              setGpbShare(response?.data?.data?.gbp_share)
              setAgentGpbShare(response?.data?.data?.agent_gbp_share)
              setAgentUsdShare(response?.data?.data?.agent_usd_share)
              setLoading(false)
          }
      ).catch(
           err =>{
              console.error(err);
              setLoading(false);
              setError(err.message)
           }
      )
    }, [row]);

    const columns = [
      { key: "zkp_InvoiceLabel", header: "InvoiveID" },
      { key: "YearQuarter", header: "YearQuarter" },
      { key: "labelID", header: "labelID" },
      { key: "LabelName", header: "LabelName" },
      { key: "zst_LineCount", header: "LineCount" },
      { key: "zst_QtySales", header: "QtySales" },
      { key: "zc_LabelCurrencyPref", header: "LabelCurrency" },
      { key: "zst_USDRev", header: "USDRev" },
      { key: "zst_USDShare", header: "USDShare" },
      { key: "zst_GBPRev", header: "GBPRev" },
      { key: "zst_GBPShare", header: "GBPShare" },
      { key: "AgentCommRate", header: "AgentComm_Rate" },
      { key: "CommSource", header: "CommSource" },
      { key: "AgentUSDShare", header: "Agent_USDShare" },
      { key: "AgentGBPShare", header: "Agent_GBPShare" },
     
    ];

    

    const displayDetails = (row)=>{
      console.log(row)
    }
  
   function formatCurrency(amount,type, currencySymbol = "£") {
      if (isNaN(amount)) {
        return "Invalid amount";
      }
      const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: type,
        currencyDisplay: "symbol", // Use currency symbol instead of code
        minimumFractionDigits: 3, // Ensures two decimal places even for whole numbers
      });
    
      return formatter.format(amount);
    }

    const handleQuater = (e)=>{
      setQuater(e.target.value)
    }
  

  return (
    <main className='mt-2 bg-slate-50'>
        <div className='bg-black justify-between py-3 px-10 flex gap-4'>
          <p className='text-sm font-semibold text-white'>{row.NameFull}_commission-summary</p>
          <p onClick={()=>setAgentDetailsDisplay(false)} className='font-bold text-white text-2xl'><LuXCircle/></p>
        </div>

        <section className='grid grid-cols-12 m-10 gap-8'>

            <div className=' col-span-4 p-5'>
                <p className='text-sm font-bold text-gray-600'>REFERENCE</p>

                <div className='flex flex-col'>
                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >AccountID</p>
                     <p className='text-sm font-semibold' >{row?.zkp_Agent}</p>
                    </div>
                    
                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >AccountName</p>
                     <p className='text-sm font-semibold' >{row?.NameFull}</p>
                    </div>
                

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >YearQuater</p>
                     <p className='text-sm font-semibold' >2024Q1</p>
                    </div>

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >AgentType</p>
                     <p className='text-sm font-semibold' >{row?.AgentType}</p>
                    </div>

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >PaymentType</p>
                     <p className='text-sm font-semibold' >{row?.CommSource}</p>
                    </div>

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >Date</p>
                     <p className='text-sm font-semibold' >21/09/67</p>
                    </div>

                    <div className='flex justify-between px-3 py-2 border-b'>
                     <p className='text-sm font-semibold text-gray-500' >InvoiceID</p>
                     <p className='text-sm font-semibold' >INVA00345</p>
                    </div>

                

                </div>

            </div>

            <div className=' col-span-4 bg-white p-5'>
            <p className='text-sm font-bold text-gray-500'>SUMMARY</p>

                  <div className='grid grid-cols-3 gap-4 px-3 pt-2 '>
                     <p className='col-span-1'></p>
                     <p className='col-span-1 text-xs font-bold text-gray-500 text-end' >ACTUAL</p>
                     <p className='col-span-1 text-xs font-bold text-gray-500 text-end' >FILTERED</p>
                    </div>

                  <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className=' col-span-1 text-xs font-semibold text-start' >LineCount</p>
                     <p className='col-span-1 text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ' >{lineCount}</p>
                     <p className='col-span-1 text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ml-2'>{lineCount}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >Revenue(GBP)</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ' >{formatCurrency(gbpRev,"GBP")}</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ml-2' >{formatCurrency(gbpRev,"GBP")}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >Revenue(USD)</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ' >{formatCurrency(usdRev,"USD")}</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ml-2' >{formatCurrency(usdRev,"USD")}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >Share(GBP)</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ' >{formatCurrency(gbpShare,"GBP")}</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ml-2' >{formatCurrency(gbpShare,"GBP")}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >Share(USD)</p>
                     <p className='text-xs  bg-slate-200 w-[100px] font-bold text-end p-1 ' >{formatCurrency(usdShare,"USD")}</p>
                     <p className='text-xs font-bold bg-slate-200 w-[100px] text-end p-1 ml-2' >{formatCurrency(usdShare,"USD")}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >QuantitySales</p>
                     <p className='text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ' >{qtySales}</p>
                     <p className='text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ml-2' >{qtySales}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >AgentComm(USD)</p>
                     <p className='text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ' >{formatCurrency(agentUsdShare,"USD")}</p>
                     <p className='text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ml-2' >{formatCurrency(agentUsdShare,"USD")}</p>
                    </div>

                    <div className='grid grid-cols-3 gap-4 px-3 py-2'>
                    <p className='text-xs font-semibold text-start' >AgentComm(GPB)</p>
                     <p className='text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ' >{formatCurrency(agentGbpShare,"GBP")}</p>
                     <p className='text-xs font-semibold bg-slate-200 w-[100px] text-end p-1 ml-2' >{formatCurrency(agentGbpShare,"GBP")}</p>
                    </div>

                   </div>

            <div className=' col-span-4 bg-white p-5'>
              <div className='flex justify-between'>
              <button className='text-sm font-bold text-gray-500'>Actions</button>
              {/* <ExportButton agentID={row?.zkp_Agent} fname={row?.NameFirst} lname={row?.NameLast}/>
              <ExportButtonPdf agentID={row?.zkp_Agent} fname={row?.NameFirst} lname={row?.NameLast}/> */}
              <div className='flex p-3 border gap-2'>
              <select className='border items-center text-blue-800 text-xs h-[30px] w-[100px]' onChange={handleQuater}>
                <option>
                Select quater
                </option>
                <option value="2024Q1">
                2024Q1
                </option>
              </select>
              <ExportReportButton agentID={row?.zkp_Agent} fname={row?.NameFirst} lname={row?.NameLast} agentSummaryData={agentSummaryData} quater={quater} />
              </div>
              </div>
            

             <div className='flex flex-col gap-4 mt-5'>
             <div className='flex gap-4'>
             <button onClick={()=>setOpenExpenses(true)} className='text-xs flex text-center justify-center bg-slate-500 text-white items-center py-2 px-auto w-[100px]'>Add expenses</button>
             <button onClick={()=>setOpenHistory(true)} className='text-xs flex text-center justify-center bg-green-600 text-white items-center py-2  px-4 '>view expenses history</button>

             </div>
               <input placeholder='search Label name' className='w-full p-2 text-xs border border-slate-400'/>
               <input placeholder='search Label currency' className='w-full p-2  text-xs border border-slate-400'/>
             </div>
             <p className='text-xs mt-[120px] text-end font-semibold text-blue-600'><span className='text-gray-500'>Showing </span>{agentSummaryData ? agentSummaryData.length : 0}/{agentSummaryData ? agentSummaryData.length : 0} records</p>
            </div>

        </section>

        <section className='w-full mt-10 border-t-2 pt-10'>
        {loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err.message} please reload the page</p>:
        <DynamicTable
           data={agentSummaryData}
           columns={columns}
           click={displayDetails}
           showSearch = {false}
            />
    }
      
      </section>
      <ExpensesModal open={openExpenses} setOpen={setOpenExpenses} accountType="Agent" fullName={row.NameFull} id={row.zkp_Agent}/>
      <ExpenseHistoryModal open={openHistory} setOpen={setOpenHistory} id={row.zkp_Agent}/>
    </main>
  )
}

export default AgentComSumarry


