import React,{useState,useEffect} from 'react'
import {AiOutlineClose,AiOutlineMenu} from 'react-icons/ai'
import {Link} from 'react-router-dom'
const Navbar = ()=> {
    const [nav,setNav] = useState(false);
    const [urlPath, setUrlPath] = useState('');
  
    

    useEffect(() => {
      // Get the current URL path
      const currentPath = window.location.pathname;
      setUrlPath(currentPath);
    }, []);
  
    // Function to extract a particular string of characters from the URL path
    const extractString = () => {
      const lastSlashIndex = urlPath.lastIndexOf('/');
      if (lastSlashIndex !== -1) {
        return urlPath.substring(lastSlashIndex + 1);
      }
      return '';
    };
   

    const handleNav = ()=>{
      setNav(prev=>!prev);
    }

    useEffect(()=>{

    },[])

  return (
    
    <div style={{borderBottom:"1px solid gray"}} className="flex justify-between h-24 items-center w-full mx-auto px-6 text-white ">
      {/* <h1 className="w-full md:pl-3 text-2xl font-bold text-[#0c0d4e] ">~StemGenerator~</h1>  */}
      <h1 className="w-full md:pl-3 text-2xl font-bold text-[#dc4646] ">~Processing System~</h1>
      <ul className="hidden text-[#0c0d4e] md:flex md:pr-3 ">
        <li className={`${extractString() === "" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/">Home</Link></li>
        {/* <li className={`${extractString() === "create-soundbit" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/create-soundbit">SoundBit</Link></li> */}
        <li className={`${extractString() === "file-bulk-upload" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/file-bulk-upload">UploadAudios</Link></li>
        <li className={`${extractString() === "text-to-speech" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/text-to-speech">TextToSpeech</Link></li>
        <li className={`${extractString() === "convert-audio" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/convert-audio">ConvertAudio</Link></li>
        <li className={`${extractString() === "encode-file" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/encode-file">EncodeFile</Link></li>
        <li className={`${extractString() === "dashboard" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/dashboard">Database</Link></li>
        
        <li className={`${extractString() === "accounting" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/accounting">Accounting</Link></li>
      </ul>  
 
      {/* <ul className="hidden text-[#0c0d4e] md:flex md:pr-3 ">
      <li className={`${extractString() === "" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/">Home</Link></li>
        <li className={`${extractString() === "file-bulk-upload" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/file-bulk-upload">UploadAudios</Link></li>
        <li className={`${extractString() === "dashboard" ? "text-[#dc4646]" : "text-[#0c0d4e]"} p-4 font-bold text-1xl`}><Link to="/dashboard">Database</Link></li>
        
      </ul>    */}
      <div onClick={handleNav} className="block md:hidden text-[#0c0d4e] font-bold">
        {!nav ? <AiOutlineMenu  size={24} /> : <AiOutlineClose size={24} /> }
      </div>
      <div className={nav ? "fixed  left-0 top-0 w-[70%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500" : "fixed left-[-100%]"}>
      <h1 className="w-full text-3xl m-4 font-bold text-[#0c0d4e] ">~Stem-Generator~</h1>
      <ul className="uppercase p-4">
        <li className="p-4 text-1xl border-b border-b-gray-400">Tracks</li>
        <li className="p-4 text-1xl border-b border-b-gray-400">Login</li>
        <li className="p-4 text-1xl">SignIn</li>
      </ul>
      </div>

    </div>
  )
}

export default Navbar
