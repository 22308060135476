import React, { useState, useEffect, useContext, useRef } from 'react';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import { FileContext } from './context/fileContext';
import wavesurfer from 'wavesurfer.js';
import axios from 'axios'
import {HiArrowNarrowLeft} from 'react-icons/hi'
import LoaderSmall from '../smallLoader/LoaderSmall';
import {api,base} from '../utils/api'
import { useNavigate } from 'react-router-dom';

const AudioVisualizer = () => {
	const wavesurferRef = useRef(null);
	const timelineRef = useRef(null);
  const [selectedStartTime, setSelectedStartTime] = useState(0);
  const [selectedEndTime, setSelectedEndTime] = useState(0);
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loopedAudio, setLoopedAudio] = useState(null);
  const [loopDuration, setLoopDuration] = useState(0);

	// fetch file url and original file from the context
  const { fileURL, originalFile, setFileURL } = useContext(FileContext);

	// crate an instance of the wavesurfer
	const [wavesurferObj, setWavesurferObj] = useState();

	const [playing, setPlaying] = useState(true); // to keep track whether audio is currently playing or not
	const [volume, setVolume] = useState(1); // to control volume level of the audio. 0-mute, 1-max
	const [zoom, setZoom] = useState(8); // to control the zoom level of the waveform
	const [duration, setDuration] = useState(0); // duration is used to set the default region of selection for trimming the audio

  const navigate = useNavigate();
	// create the waveform inside the correct component
	useEffect(() => {
		if (wavesurferRef.current && !wavesurferObj) {
			setWavesurferObj(
				wavesurfer.create({
					container: '#waveform',
					backgroundColor:'#777575',
					scrollParent: true,
					autoCenter: true,
					cursorColor: 'green',
					loopSelection: true,
					waveColor: 'gray',
					progressColor: 'white',
					height: 200,
					barWidth: 1, // Set the width of the vertical bars
					barHeight:20,
                    barGap: 10, // Set the gap between the vertical bars
					responsive: true,
					plugins: [
						TimelinePlugin.create({
							container: '#wave-timeline',
						}),
						RegionsPlugin.create({}),
					],
					
				})
			);
			
		}
	}, [wavesurferRef, wavesurferObj]);

	useEffect(() => {
    if (fileURL && wavesurferObj) {
      wavesurferObj.loadBlob(originalFile); // Use the original file Blob instead of fileURL
	  wavesurferObj.zoom(zoom)
    }
	
  }, [fileURL, originalFile, wavesurferObj]);

	useEffect(() => {
		if (wavesurferObj) {
			// once the waveform is ready, play the audio
			wavesurferObj.on('ready', () => {
				wavesurferObj.play();
				wavesurferObj.enableDragSelection({}); // to select the region to be trimmed
				setDuration(Math.floor(wavesurferObj.getDuration())); // set the duration in local state
			});

			// once audio starts playing, set the state variable to true
			wavesurferObj.on('play', () => {
				setPlaying(true);
			});

			// once audio starts playing, set the state variable to false
			wavesurferObj.on('finish', () => {
				setPlaying(false);
			});

			// if multiple regions are created, then remove all the previous regions so that only 1 is present at any given time
			wavesurferObj.on('region-updated', (region) => {
				const regions = region.wavesurfer.regions.list;
				const keys = Object.keys(regions);
				if (keys.length > 1) {
					regions[keys[0]].remove();
				}
			});
		}
	}, [wavesurferObj]);

	// set volume of the wavesurfer object, whenever volume variable in state is changed
	useEffect(() => {
		if (wavesurferObj) wavesurferObj.setVolume(volume);
	}, [volume, wavesurferObj]);

	// set zoom level of the wavesurfer object, whenever the zoom variable in state is changed
	useEffect(() => {
		if (wavesurferObj) wavesurferObj.zoom(zoom);
	}, [zoom, wavesurferObj]);

	// when the duration of the audio is available, set the length of the region depending on it, so as to not exceed the total lenght of the audio
	useEffect(() => {
		if (duration && wavesurferObj) {
			// add a region with default length
			wavesurferObj.addRegion({
				start: Math.floor(duration / 2) - Math.floor(duration) / 5, // time in seconds
				end: Math.floor(duration / 2), // time in seconds
				color: 'hsla(265, 100%, 86%, 0.4)', // color of the selected region, light hue of purple
			});
			
		}
	}, [duration, wavesurferObj]);

	const handlePlayPause = (e) => {
		wavesurferObj.playPause();
		setPlaying(!playing);
	};

	const handleReload = (e) => {
		// stop will return the audio to 0s, then play it again
		wavesurferObj.stop();
		wavesurferObj.play();
		setPlaying(true); // to toggle the play/pause button icon
	};

	const handleVolumeSlider = (e) => {
		setVolume(e.target.value);
	};

	const handleZoomSlider = (e) => {
		setZoom(e.target.value);
	};

	const handleTrim = () => {
		if (wavesurferObj) {
		  // get start and end points of the selected region
		  const region =
			wavesurferObj.regions.list[
			  Object.keys(wavesurferObj.regions.list)[0]
			];
	  
		  if (region) {
			const start = region.start;
			const end = region.end;
      setSelectedStartTime(start)
      setSelectedEndTime(end)
	  
			// set the new position for playback to the start of the trimmed region
			wavesurferObj.seekTo(start / wavesurferObj.getDuration());
	  
			let reachedEnd = false; // flag to track if the end is reached
	  
			// handle the audioprocess event to stop playback at the end of the trimmed region
			wavesurferObj.on('audioprocess', () => {
			  const currentTime = wavesurferObj.getCurrentTime();
	  
			  // check if we have reached the end of the trimmed region
			  if (currentTime >= end && !reachedEnd) {
				reachedEnd = true; // set the flag to true to prevent further checks
				wavesurferObj.stop(); // stop playback at the end of the trimmed region
			  }
			});
	  
			// play the trimmed region
			wavesurferObj.play();
		  }
		}
	  };
    


    const handleLoop = () => {
      console.log("this is originalfile",originalFile)
      console.log("start time :",selectedStartTime)
      console.log("end time : ",selectedEndTime)
      setLoader(true)
      
      if (originalFile) {

        if(selectedEndTime === 0) {
          setErrorMessage("No region selected, play trimmed audio to set start and end time")
          setLoader(false)
          return
        }

        if(loopDuration < 1) {
          setErrorMessage("Please Enter Loop Duration")
          setLoader(false)
          return
        }

        let formData = new FormData();
        formData.append("start_time",selectedStartTime)
        formData.append("end_time",selectedEndTime)
        formData.append(`audio_file`, originalFile);
        formData.append('name', originalFile.name);
        formData.append('loopDuration', loopDuration);
  
           const axiosConfig = {
            headers: {
                "Content-Type": "multipart/form-data",
              }
        }
        axios.post(api + `/audio-loops/`, formData, axiosConfig).then(
          response =>{
           console.log("this is audio loop : ",response)
           const originalString  = response?.data?.audio_file;
           const modifiedString = originalString.replace(/\/media\/app\//, '/');
           setLoopedAudio(modifiedString)
           setErrorMessage(response?.data?.message)
           setLoader(false) 
          }
      ).catch(err=>{
              console.log(err) 
           setErrorMessage(err?.message)
           setLoader(false)
           }
      )
      }else{

        setErrorMessage("No file uploaded")
        setLoader(false)
      }
      
    };
  
	  
	  
	  const backtoUpload = ()=>{
      navigate('/create-soundbit')
    }
	  const handleLoopDuration = (e)=>{
       const duration = e.target.value
       setLoopDuration(duration)
    }
	  

	return (
		<section className='w-[70%] mt-[-4px]'>
      <div className='flex mb-5 font-semibold cursor-pointer' onClick={backtoUpload}> <HiArrowNarrowLeft/> <p className='mt-[-4px]'>Back</p></div>
			<div ref={wavesurferRef} id='waveform' />
			<div ref={timelineRef} id='wave-timeline' />
			<div className='flex justify-between mt-12'>
				<div className='left-container'>
        <p className='text-xs text-center'>Play/Pause</p>
					<button
						title='play/pause'
						className='controls'
						onClick={handlePlayPause}>
						{playing ? (
							<i className='material-icons'>pause</i>
						) : (
							<i className='material-icons'>play_arrow</i>
						)}
					</button>
				</div>
        <div>
        <button className='bg-pink-500 flex text-xs text-white py-2 px-2 rounded-md mt-3' title='play' onClick={handleTrim}>
						<i
							style={{
								fontSize: '1.2em',
								color: 'white',
							}}
							className='material-icons'>
							content_cut
						</i>
						Play trimmed audio
					</button>
        </div>
        <div>
          <p className='text-xs text-center'>replay</p>
        <button
						title=''
						className=''
						onClick={handleReload}>
						<i className='material-icons'>replay</i>
					</button>
        </div>
				
					<div className='volume-slide-container'>
            <p className='text-xs text-center'>Zoom Visualizer</p>
						<i className='material-icons zoom-icon cursor-pointer'>
							remove_circle
						</i>
						<input
							type='range'
							min='1'
							max='1000'
							value={zoom}
							onChange={handleZoomSlider}
							class=''
						/>
						<i className='material-icons zoom-icon cursor-pointer'>add_circle</i>
					</div>
          <div className=''>
          <p className='text-center text-xs'>Volume</p>
						{volume > 0 ? (
							<i className='material-icons'>volume_up</i>
						) : (
							<i className='material-icons'>volume_off</i>
						)}
						<input
							type='range'
							min='0'
							max='1'
							step='0.05'
							value={volume}
							onChange={handleVolumeSlider}
							className='slider volume-slider'
						/>
					</div>
          <div className='border rounded px-2 bg-gray-100'>
          <p className='text-xs text-center'>Enter loop duration</p>
            <div className='flex mt-1'>
            <input type="number" onChange={handleLoopDuration} className='w-12 h-8 px-1 mr-1 border-gray-200 border-2 rounded-md' />
            <button onClick={handleLoop} className='flex text-center text-xs text-white py-2 px-2 rounded-md bg-green-600'>
              {loader ? <LoaderSmall/> : "Loop Audio"}
            </button>
           
            </div>
            <p className='text-red-500 text-center text-xs'>{errorMessage}</p>
          </div>
			</div>

      
      
       
       {loopedAudio && <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                        <h1 class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Looped Audio</h1>
                        
                           <audio controls src={loopedAudio}></audio>
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={()=>{setLoopedAudio(null)}} >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>}
		</section>
	);
};

export default AudioVisualizer;
