import React, { useCallback,useState,useEffect } from 'react';
import axios from 'axios'
import {Link} from 'react-router-dom'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import ProgressBar from '../../progressbar/ProgressBar';
import {api,base} from '../../utils/api'
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import Folder from  '../../../assets/imgs/folder_image.png'
import SuccessIcon from  '../../../assets/imgs/success-icon.png'
import {AiOutlineClose} from 'react-icons/ai'
import JSZip from 'jszip';


const ConvertAudio = ()=>{
  const [files,setFiles] = useState(null);
  const [fileIndex,setFileIndex] = useState(null);
  const [imagePreview,setImagePreview] = useState(null);
  const [disableConvertButon,setDisableConvertButton] = useState(false);
  const [audioType,setAudioType] = useState(null);
  const [audioFormat,setAudioFormat] = useState(null);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState([]);
  const [errorMessage, setErrorMessage] = useState([])
  const [onUploadErrorMessage,setOnUploadErrorMessage] = useState(null)
  const [downloadMessage, setDownloadMessage] = useState(null)
  const [folderId, setFolderId] = useState(null)
  const [folderName, setFolderName] = useState([])
  const [responseHeader, setResponseHeader] = useState(null)
  const [downloadButton, setDownloadButton] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');
  const [success, setSuccess] = useState(false)

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    console.log("selected options",event.target.value)
  };

  
const randomChar = (length) => {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
 
    for (let i = 0; i < length; i++) {
       const randomIndex = Math.floor(Math.random() * charset.length);
       result += charset[randomIndex];
    }
 
    return result;
 }
 


const fetchFilesForDownload = async (fn) => {
  const downloadLk = []
  const filename = fn[0]
  try {
    const response = await axios.get(base + `/getfiles-download/${filename}/${folderId}/${audioFormat}`,{ responseType: 'blob' });
    console.log(response.headers);
    downloadLk.push(response.data);
    
  } catch (error) {
    console.error('Error fetching audio files for download:', error);
    setDownloadMessage(error);
  }
  setDownloadLink((prev)=>[...prev,...downloadLk]);

  if (fn.length === 1 && !downloadMessage){
    setDisableConvertButton(true)
    setDownloadButton(true);
    setDownloadMessage("Files ready for download")
}else{
  fetchFilesForDownload(fn.slice(1));
}
};

const handleDownload = () => {

  if (downloadLink.length === 1) {
    
    // Create a ZIP file with all the responses
    const blob = new Blob([downloadLink[0]], { type: 'application/zip' });
    const zipFileName = `${folderName[0]}.zip`;
    const downloadLinkElement = document.createElement('a');
    downloadLinkElement.href = window.URL.createObjectURL(blob);
    downloadLinkElement.download = zipFileName ;
    downloadLinkElement.click();
    // Cleanup: Revoke the Blob URL to free up resources
    window.URL.revokeObjectURL(downloadLinkElement.href);
    setSuccess(true);
    handleCancel();
    }else if (downloadLink.length > 1) {
    // Create a ZIP file with all the responses
    const zip = new JSZip();
    downloadLink.forEach((response, index) => {
      // Assuming each response.data is a Blob
      const blob = new Blob([response], { type: 'application/zip' });
      const zipFileName = `${folderName[index]}.zip`;
      // Add the blob to the ZIP file with a specific filename
      zip.file(zipFileName, blob);
    });

    // Generate the ZIP file
    zip.generateAsync({ type: 'blob' }).then((blob) => {
      // Create a download link and trigger the click event
      const downloadLinkElement = document.createElement('a');
      downloadLinkElement.href = window.URL.createObjectURL(blob);
      downloadLinkElement.download = 'Converted_Files.zip';
      downloadLinkElement.click();
      // Cleanup: Revoke the Blob URL to free up resources
      window.URL.revokeObjectURL(downloadLinkElement.href);
    });

    setSuccess(true);
    handleCancel();
    
  } else {
    console.log("No responses to download");
  }
 
};




const handleDrop = useCallback(async (event) => {
  event.preventDefault();
  const audioFiles = [];
  const folder = [];
  const folderN = [];

  const traverseDirectory = async (item, path = '') => {
    if (item.isFile) {
      const file = await new Promise((resolve) => item.file(resolve));
      const fileName = file.name.toLowerCase();

      if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
        audioFiles.push(file);
      }else if (file.type.startsWith('image/')) {
        audioFiles.push(file);
        setImagePreview(fileName)
    }
    } else if (item.isDirectory) {
      const entries = await new Promise((resolve) => item.createReader().readEntries(resolve));

      for (const entry of entries) {
        await traverseDirectory(entry, `${path}${item.name}/`);
      }
    }
  };

 
 const foldID = randomChar(10)
 setFolderId(foldID)
 
  for (let i = 0; i < event.dataTransfer.items.length; i++) {
    const foldN =  event.dataTransfer.items[i].getAsFile().name
    folderN.push(foldN)
    const item = event.dataTransfer.items[i];

    if (item.kind === 'file' && item.type === "audio/wav") {
      const file = item.getAsFile();
      const fileName = file.name.toLowerCase();

      if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
        audioFiles.push(file);
      }else if (file.type.startsWith('image/')) {
          audioFiles.push(file);
          setImagePreview(fileName)
        
    }
    } else if (item.webkitGetAsEntry) {
      const entry = item.webkitGetAsEntry();
      if (entry) {
        if (entry.isDirectory) {
            folder.push(entry)
        } else if (entry.isFile) {
          const file = item.getAsFile();
          const fileName = file.name.toLowerCase();

          if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
            audioFiles.push(file);
          }else if (file.type.startsWith('image/')) {
            audioFiles.push(file);
            setImagePreview(fileName)
        }
        }
      }
    }
  }
 
  if(folder.length > 0 ){
    console.log("this is folder",folder)
    for (let i = 0; i < folder.length; i++) {
      await traverseDirectory(folder[i]);
    }
  }

   
  setFolderName((prev)=>[...prev,...folderN])
  setFiles(audioFiles);
}, []);



  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const containsAlphabets = (str) => {
    return /[a-zA-Z]/.test(str);
  };


  

  const upload = async (files)=>{ 
    const resMessages = []
    setLoader(true) 

    if(!audioFormat || audioFormat==='') {
      setOnUploadErrorMessage("Please select audio Format(UPC/CatalogNum) you want to convert to")
        setLoader(false) 
        return
    } 
    if(!audioType || audioType ==='') {
      setOnUploadErrorMessage("Please select audio Type(MP3/WAV/FLAC) you want to convert to")
        setLoader(false) 
        return
    } 


    
      const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
          }
      
    }
   
    
    const fileName = folderName[0];
    //const filenameWithoutExtension = fileName.replace(/\.[^/.]+$/, "")
    const containsChar =  containsAlphabets(fileName)

   if(audioFormat==='upc' && containsChar===true ) {
    setOnUploadErrorMessage("Sorry you uploaded a catalogue number encoded files, encode files in upc before converting ")
      setLoader(false) 
      return
  } 

  
  if(audioFormat==='catlognum' && containsChar===false ) {
    setOnUploadErrorMessage("Sorry you uploaded a upc encoded files, encode files in catalogue before converting ")
      setLoader(false) 
      return
  } 

    
    if(fileName.endsWith('.mp3') && audioType ==="mp3"){
      setOnUploadErrorMessage("Audios uploaded are MP3 files; please select another audio type you want to convert")
     setLoader(false)
     return
    }

    if(fileName.endsWith('.wav') && audioType ==="wav"){
        setOnUploadErrorMessage("Audios uploaded are WAV files; please select another audio type you want to convert")
        setLoader(false)
        return
       }

    if(fileName.endsWith('.flac') && audioType ==="flac"){
      setOnUploadErrorMessage("Audios uploaded are FLAC files; please select another audio type you want to convert")
    setLoader(false)
    return
    }

    setDisableConvertButton(true)
    const file = files[0];
    setFileIndex(file)
        
  try{
        const formData = new FormData();
        if (file.type.startsWith('image/')){
        formData.append('image_file', file);
        formData.append('art_work', selectedOption);
        formData.append('name', file.name);
        formData.append('audio_type', audioType);
        formData.append('audio_format', audioFormat);
        formData.append('folder_id', folderId);
        }else{
        formData.append('audio_file', file);
        formData.append('name', file.name);
        formData.append('audio_type', audioType);
        formData.append('audio_format', audioFormat);
        formData.append('folder_id', folderId);
        }
        
       const response = await  axios.post(api + "/convert-files/", formData, axiosConfig)
           resMessages.push(response?.data?.message)
           setFileIndex(null)
           setLoader(false)
           

  } catch(err){
           console.log(err)
           setLoader(prev=>!prev) 
           resMessages.push(err?.message) 
           setFileIndex(null)
           
           }
      //setSuccessMessage((prev)=>[...prev,...resMessages])
      setErrorMessage((prev)=>[...prev,...resMessages])
      
      // Recursive call to handle the next file in the array


      if (files.length === 1){
        setLoader(false)
        setDownloadMessage("Getting files ready for download, please wait...")
        fetchFilesForDownload(folderName)
      
    }else{
      upload(files.slice(1));
    }

        
      }

const handleCancel = ()=>{
  setFiles(null);
  setErrorMessage([]);
  setAudioFormat(null);
  setAudioType(null);
  setImagePreview(null)
  setDownloadLink([]);
  setResponseHeader(null)
  setDisableConvertButton(false)
  setDownloadMessage(null);
  setDownloadButton(false);
  setFolderName([])
  setFolderId(null)
}

  const removeItem = (index) => {
    const updatedItems = [...files]; // Create a copy of the array
    updatedItems.splice(index, 1); // Remove the item at the given index
    setFiles(updatedItems); // Update the state with the new array
  };


const handleAudioFormat = (e) => {
  setAudioFormat(e.target.value)
}

const handleAudioType = (e) => {
  setAudioType(e.target.value)
  }

  useEffect(() => {
    if (onUploadErrorMessage) {
      
      const timer = setTimeout(() => {
        setOnUploadErrorMessage(null);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [onUploadErrorMessage]);


   return(
       <div>
        <Navbar/>
     <div className='flex items-center justify-center'>

     {files && <div  style={{border:"1px solid gray"}} className="mx-24 container py-[40px] flex justify-center items-center mt-14">
       <p onClick={handleCancel} className='absolute right-[160px] top-[170px] font-semibold flex cursor-pointer' >Cancel <AiOutlineClose size={23} /></p> 
     <div>
        <p className='text-md font-semibold mb-3 mt-[-5]'>*Please select conversion type accordingly*</p>
        <div className='flex justify-between items-center border border-blue-500 rounded-md py-5 px-8'>
        <div className='flex flex-col'>
        <p className='text-sm text-blue-800 mb-2'>{files.length} file(s) uploaded</p>
        <img src={Folder} alt='folderimage' className='h-[80px] w-[120px]' />
            </div>
        <div className='flex flex-col ml-6 '>
       <p className='mb-2 text-xs'>*Art Work Conversion (72dpi|640X640px / 300dpi|5000X5000Px)</p>
      <div className='flex justify-between px-3' >
      <label className='text-blue-800 text-sm'>
        <input
        className='mr-1'
          type="radio"
          value="72dpi"
          checked={selectedOption === '72dpi'}
          onChange={handleOptionChange}
        />
        72dpi
      </label>

      <label className='text-blue-800 text-sm'>
        <input
          type="radio"
          value="300dpi"
          checked={selectedOption === '300dpi'}
          onChange={handleOptionChange}
        />
         300dpi
      </label>

      <label className='text-blue-800 text-sm'>
        <input
          type="radio"
          value=""
          checked={selectedOption === ''}
          onChange={handleOptionChange}
        />
        None
      </label>
    </div>
        <select className=' my-3 text-blue-800 h-8 border-blue-800 border rounded-md  px-3' onChange={handleAudioType}>
        {audioType===null && <option>*select(Wav/Mp3)</option>}
          <option value="mp3">
            MP3
          </option>
          <option value="wav">
            WAV
          </option>
          <option value="flac">
            FLAC
          </option>
      </select>
      <select className=' text-blue-800 h-8 border-blue-800 border rounded-md  px-3' onChange={handleAudioFormat}>
        {audioFormat===null && <option  >*select(Catlog/UPC)</option>}
          <option value="catlognum">
            Catalogue Number
          </option>
          <option value="upc">
            UPC
          </option>
      </select>
      </div>
        </div>
        <div className=''>
        {imagePreview && <p className='text-blue-500 mt-4 font-semibold text-sm py-1 px-4 items-center border border-blue-500 '>{imagePreview}</p>}
        </div>
      </div>
      <div className='ml-[30px] flex flex-col'>
        <div>
        <ul className='h-[180px] w-[250px] border border-1 my-5 overflow-auto bg-gray-300 shadow-lg'>
          {files.length > 0 ? Array.from(files).map((file,index)=>
          <li className='flex flex-col px-1 py-1  cursor-pointer rounded-sm text-xs bg-gray-100'  key={index}><p>{file.name}</p>
         {fileIndex===file && 
          <p className='text-[10px] text-blue-800'>Uploading and Converting {file.name} to {file.type.startsWith('image/') ? "JPG" : audioType}...</p>
          }
          <p className={`${errorMessage[index] === "File converted successfully" ?"text-green-600" :"text-red-500"} font-bold text-[10px]`}>{errorMessage[index]}</p>
        </li>)
          :
          <li className='flex px-2 py-2 m-1  cursor-pointer rounded-sm text-sm bg-gray-100' >No Audio Files Selected</li>
          }

        </ul>
        </div>
        {onUploadErrorMessage && <p className='text-xs text-red-500 mb-3 w-[250px]'>{onUploadErrorMessage}</p>}
        <div className='flex'>
        <button disabled={disableConvertButon} onClick={()=>upload(files)} className='flex text-sm py-2 px-6 text-white bg-blue-800 rounded-md'>
         {loader ? <><LoaderSmall/> pls wait..</> : "Convert Folder" }
        </button>
       {downloadButton && downloadLink &&
          <button className='rounded-md ml-3 flex py-2 p-4 bg-pink-700 text-white' onClick={handleDownload}>
          Download Folder
        </button>}
       {/* <button className='rounded-md ml-3 flex py-2 p-4 bg-pink-700 text-white'>Download</button> */}
        </div>
        {downloadMessage && <p className='text-xs text-green-600 mt-3 w-[250px]'>{downloadMessage}</p>}
      </div>
        
            
    </div>
   
    }

     {!files && <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="container h-[300px] bg-slate-400 flex justify-center items-center mt-14 mx-24"
      style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}
    >
      <p className="text-white font-semibold text-lg">Drag & drop a folder for conversion</p>
    </div>}
   
    </div> 
  <Footer/>

  {success && <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                    <div className='flex flex-col justify-center items-center'>
                    <img src={SuccessIcon} alt='folderimage' className='h-[70px] w-[70px] ' />
                    <p className='font-semibold text-sm mt-2'>Conversion Successful...check your download folder</p>
                        </div>
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={()=>{setSuccess(false)}} >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                        </div>
                    </div>
                </div>
            }
</div>
  )
};

export default ConvertAudio;
