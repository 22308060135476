import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable2 from '../ui/DynamicTable2';
import {api,base} from '../../utils/api'
import DataLoader from '../../smallLoader/DataLoader';
import AddDeductionModal from './AddDeductionModal';
import PromptModal from '../ui/PromptModal';
import { readFromLocalStorage } from '../../utils/util';


function AgentsDeduction({setNextStage}) {
const [agentData, setAgentData] = useState([])
const [loading, setLoading] = useState(false);
const [agent, setAgent] = useState(null);
const [deductionDisplay,setDeductionDisplay] = useState(false)
const [openPrompt, setOpenPrompt] = useState(false);
const [err,setErr] = useState(null)
const quater = readFromLocalStorage("quater")


const getAgentsDebtors = ()=>{
    setLoading(true)
    axios.get(base + `/agent-debtors/`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.data;
         setAgentData(arr)
       
         setLoading(false)
         console.log("response.data ok")
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getAgentsDebtors();
  }, []);


  const displayDeduction = (row)=>{
    console.log(row)
    setAgent(row)
    setDeductionDisplay(true)
  }


  const columns = [
    { key: "zkp_Agent", header: "Agent ID" },
    { key: "NameFull", header: "Full Name" },
    { key: "IsActive", header: "IsActive" },
   
  ];

  

 


  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>
    <div className='flex justify-end'>
        <button onClick={()=>setOpenPrompt(true)} className='flex bg-black text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 mr-14'>{"Next >>"}</button>
        </div>

{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <div className='rounded-md border-2 mt-5 md:mx-28 px-5'> 
      <p className='font-bold text-center text-lg my-3 text-gray-500'>Add Agents deductions</p>
      <DynamicTable2
      data={agentData}
      columns={columns}
      addDeduction={displayDeduction}
      type="deduction"
    />
    </div> 
    }

               
    
    </div>
    <AddDeductionModal open={deductionDisplay} setOpen={setDeductionDisplay} quater={quater} accountType="Agent" currency={agent?.CurrencyPreffered} fullName={agent?.NameFull} id={agent?.zkp_Agent}/>
    <PromptModal stage="label_deductions" setNextStage={setNextStage} message="Are you done with adding Agents Deduction ?" open={openPrompt} setOpen={setOpenPrompt} />
    </div>
  )
}

export default AgentsDeduction