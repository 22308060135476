import React from 'react'
import { LuShare,LuPlus,LuImport,LuShieldAlert,LuDatabase} from "react-icons/lu";

function Actions() {
  return (
    <main className='m-10'>
   <div className='flex justify-between'>
    <div className='flex gap-4'>
        <button className='text-sm text-white bg-[#030d09] text-center items-center flex-col flex font-semibold py-3 px-8'><LuPlus/> ADD</button>
        <button className='text-sm text-white bg-[#030d09] text-center items-center flex-col flex font-semibold py-3 px-5'><LuImport/> IMPORT</button>
    </div>

    <div className='flex gap-4'>
        <button className='text-sm text-[#030d09] text-center items-center flex-col flex font-semibold border-2 border-[#030d09] py-3 px-5'><LuShieldAlert/>CHECK DATA</button>
        <button className='text-sm text-[#030d09] text-center items-center flex-col flex font-semibold border-2 border-[#030d09] py-3 px-6'><LuDatabase/>BACK UP</button>
    </div>
   </div>
   <div className='flex justify-end mt-20'>

    <div className='flex gap-4'>
        <button className='text-sm text-[#030d09] text-center items-center flex-col flex font-semibold border-2 border-[#030d09] py-3 px-5'><LuShare/>CUSTOM DATA SHEET</button>
        <button className='text-sm text-[#030d09] text-center items-center flex-col flex font-semibold border-2 border-[#030d09] py-3 px-6'><LuShare/>EXPENSE SHEET</button>
    </div>
   </div>
    </main>
  )
}

export default Actions