import React from "react";
import { LuChevronRight,LuBarChart2,LuRefreshCcw } from "react-icons/lu";



const DynamicTable = ({ data, agentEdit,update, columns,click,showSearch,setSearchQuery,searchQuery,findAgent,colMessage}) => {
  
  const searchAgent = (title)=>{
      findAgent(title,searchQuery)
  }
 
  return (
    <div className="overflow-auto w-full">
      <table className="table min-w-full">
        <thead >
       {showSearch && <tr>
            <th className="py-1 px-2 text-xl text-left mb-1">
            <LuRefreshCcw/>
            </th>
            {
                agentEdit === "yes" &&
                  <td  className="py-1 px-2 ">
                    update  
                </td>
                }

            {columns
              .filter((item) => item.key !== "actions")
              .map((column) => (
                <th
                  key={column.key}
                  className="text-xs text-left mb-1"
                >
                 <input placeholder={`Find ${column.header}`} onPaste={(e)=>{setSearchQuery(e.target.value);searchAgent(column.key)}}  onChange={(e)=>{setSearchQuery(e.target.value);searchAgent(column.key)}} className="border px-3 py-2 flex font-semibold"/>
                {colMessage && <p className="text-[10px] text-red-500 my-1">{colMessage}</p>}
                </th>
              ))}
          </tr>}
          <tr className="bg-slate-100">
            <th className="py-2 px-2 text-xl text-[#37b871] text-left">
            <LuBarChart2/>
            </th>
            {
                agentEdit === "yes" &&
                  <td  className="py-1 px-2 ">
                    update  
                </td>
                }
            {columns
              .filter((item) => item.key !== "actions")
              .map((column) => (
                <th
                  key={column.key}
                  className="py-2 px-2 uppercase text-xs text-[#8B909A] text-left"
                >
                  {column.header}
                </th>
              ))}
            {columns.some((column) => column.actions) && (
              <th className=" text-[#8B909A] font-medium rounded-tr-[14px] rounded-br-[14px] text-left">
                Actions
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b border-[#DBDADE] cursor-pointer">

                <td  className="py-1 px-2 text-xl text-[#37b871]">
                <LuChevronRight/>      
                </td>
                {
                  agentEdit === "yes" &&
                  <td  className="py-1 px-2 ">
                    <button onClick={()=>update(row)} className="text-white bg-blue-600 text-xs rounded-md py-1 px-4 text-center">
                      Edit
                    </button>  
                </td>
                }

                {columns
                  .filter((item) => item.key !== "actions")
                  .map((column) =>
                    
                      <td key={column.key} onClick={()=>click(row)} className={`${column.key==="DisplayUPC" ? "font-bold":"font-normal"} py-1 px-2 text-xs` }>
                        {row[column.key]}
                      </td>
                    
                  )}
              
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="text-center py-2 text-red-600 text-sm"
                colSpan={columns.length + 1}
              >
                NO RECORD FOUND
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;
