import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable from './ui/DynamicTable';
import {api,base} from '../utils/api'
import { LuFolderPlus } from "react-icons/lu";
import UploadLabelsModal from './sub-components/UploadLabelsModal';
import DataLoader from '../smallLoader/DataLoader';
import LabelDetailSumarry from './sub-components/LabelDetailSummary';

function LabelData() {

const [labelData, setLabelData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(20);
const [loading, setLoading] = useState(false);
const [openModal, setOpenModal] = useState(false);
const [labelDetailsDisplay,setLabelDetailsDisplay] = useState(false)
const [label,setLabel] = useState(null)
const [err,setErr] = useState(null)

const getLabels = (page,page_size)=>{
    setLoading(true)
    axios.get(api + `/labels/?page=${page}&page_size=${page_size}`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.results;
         setLabelData(arr)
        //  setTotalRow(response.data.total_items)
        //  setBegin(295364-Number(response.data.results[0].id))
        //  setEnd(295364-Number(response.data.results[arr.length - 1].id))
         setLoading(false)
         console.log("response.data ok")
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getLabels(currentPage,page_size);
  }, [currentPage]);





  const columns = [
    { key: "zkp_Label", header: "Label ID" },
    { key: "LabelName_pkt", header: "Label Name" },
    { key: "LabelRoyaltyRate", header: "LabelRoyaltyRate" },
    { key: "CurrencyPreferred", header: "CurrencyPreferred" },
    { key: "LabelStatus", header: "LabelStatus" },
    { key: "NameFirst", header: "FirstName" },
    { key: "NameLast", header: "LastName" },
    { key: "Email1", header: "Email" },
    { key: "AddressCountry", header: "Country" },
   
  ];

  const displayDetails = (row)=>{
    console.log(row)
    setLabel(row)
    setLabelDetailsDisplay(true)
  }



  return (
    <div className='flex flex-col'>
      {labelDetailsDisplay && <LabelDetailSumarry row={label} setLabelDetailsDisplay={setLabelDetailsDisplay}/>}
      {!labelDetailsDisplay && <>
        <div className='bg-gray-100 flex justify-between px-10 py-4 border-b-2'>
     <p className='text-sm font-semibold text-gray-500'>Label records</p>
     <button onClick={()=>setOpenModal(true)} className='flex text-xs text-white rounded-sm py-2 px-4 bg-black'><LuFolderPlus className='mr-1 text-lg'/> Upload Labels</button>
    </div>
    <div className='w-full'
    >

{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
        
      <DynamicTable
      data={labelData}
      columns={columns}
      click={displayDetails}
    />
    }

               
    
    </div>

      </>}
     
<UploadLabelsModal open={openModal} setOpen={setOpenModal}/>
    </div>
  )
}

export default LabelData