import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import Modal from '../../modal/Modal';
import { notify } from '../../utils/util'

function UpdateAgentModal({open,setOpen,row}) {

    const [firstName,setFirstName] = useState(null);
    const [lastName,setLastName] = useState(null);
    const [agentAddress, setAgentAddress] = useState(null);
    const [phone,setPhone] = useState();
    const [email,setEmail] = useState(null);
    const [loader, setLoader] = useState(false);
    const [currency,setCurrency] = useState(null);
    const [commsource,setCommsource] = useState(null);
    const [paymentMode,setPaymentMode] = useState(null);
    
    
useEffect(()=>{
    setFirstName(row?.NameFirst)
    setLastName(row?.NameLast)
    setCommsource(row?.CommSource)
    setCurrency(row?.CurrencyPreffered)
    setEmail(row?.EmailAgent)
    setPhone(row?.PhoneAgent)
    setAgentAddress(row?.AddressLine)
    setPaymentMode(row?.PaymentMode)
},[row])
     
    
     const updateAgent = async () => {
 
        if (!email || email === ""){
            notify("please enter agent email","error")
            return
        }

        if (!commsource || commsource === ""){
            notify("please enter agent commission source","error")
            return
        }

        if (!currency || currency === ""){
            notify("please enter agent preferred currency","error")
            return
        }

        if (!firstName || firstName === ""){
            notify("please enter agent first name","error")
            return
        }

        if (!lastName || lastName === ""){
            notify("please enter agent last name","error")
            return
        }

        if (!agentAddress || agentAddress === ""){
            notify("please enter agent address","error")
            return
        }

        if (!phone || phone === ""){
            notify("please enter agent phone number","error")
            return
        }
        if (!paymentMode || paymentMode === ""){
            notify("please enter agent payment mode","error")
            return
        }

        setLoader(true)

        const formData = new FormData();
        formData.append('NameFirst', firstName);
        formData.append('NameLast', lastName);
        formData.append('CurrencyPreffered', currency);
        formData.append('AddressLine', agentAddress);
        formData.append('CommSource', commsource);
        formData.append('EmailAgent', email);
        formData.append('PhoneAgent', phone);
        formData.append('zkp_Agent', row?.zkp_Agent);
        formData.append('PaymentMode', paymentMode);
        try {
            const response = await axios.post(api + `/update-agent/update_agent/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data.message === "Agent updated successfully"){
             setLoader(false)
            
             notify("Agent updated successfully","success")
             clearRow()
             
            }
        } catch (error) {
            notify(error?.message,"error")
            setLoader(false)
        }
    };
    
const clearRow = ()=>{
    setFirstName(null)
    setLastName(null)
    setCommsource(null)
    setCurrency(null)
    setEmail(null)
    setPhone(null)
    setAgentAddress(null)
    setPaymentMode(null)
    setOpen(false)
}

  return (
    <Modal open={open}>
    <div className='w-[50%] h-[500px] bg-white p-5 '>
        <p onClick={()=>{clearRow()}} className='font-bold text-xl cursor-pointer text-end'>x</p>
       
   <section className='mx-10 py-4  w-[90%] h-[400px] overflow-y-scroll'>
    <h2 className='text-lg font-bold text-center my-4'>Update Agent details</h2>
    <div className='flex flex-col justify-between mx-5 space-y-3'>
    <div>
    <label for="full name" class="block text-sm font-medium text-gray-700 mb-1">First Name</label>
    <input placeholder='First Name'  value={firstName} onChange={(e)=>setFirstName(e.target.value)} className='w-full p-2 text-xs  border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>

    <div>
    <label for="full name" class="block text-sm font-medium text-gray-700 mb-1">Last Name</label>
    <input placeholder='Last Name' onChange={(e)=>setLastName(e.target.value)}  value={lastName} className='w-full p-2 text-xs  border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>

    <div>
    <label for="full name" class="block text-sm font-medium text-gray-700 mb-1">Agent Phone Number</label>
    <input placeholder='Agent phone' value={phone} onChange={(e)=>setPhone(e.target.value)} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>
    <div className=''>
    <label for="message" class="block text-sm font-medium text-gray-700 mb-1">Currency preferred</label>
    <input  value={currency} onChange={(e)=>setCurrency(e.target.value)} placeholder='Currency preferred' type='text' className='w-full p-2  text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>

    <div className='w-full'>
    <label for="expense-type" class="block text-sm font-medium text-gray-700 mb-1">Agent Email</label>
    <input placeholder='Agent Email' type="email" onChange={(e)=>setEmail(e.target.value)} value={email} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>

    <div className='w-full'>
    <label for="paymentMode" class="block text-sm font-medium text-gray-700 mb-1">Payment Mode</label>
    <input placeholder='Payment Mode' type="text" onChange={(e)=>setPaymentMode(e.target.value)} value={paymentMode} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>

    <div className='w-full'>
    <label for="CommSource" class="block text-sm font-medium text-gray-700 mb-1">Comm Source</label>
    <input placeholder='Agent Comm Source' onChange={(e)=>setCommsource(e.target.value)} value={commsource} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>
    
    <div class="">
  <label for="agent address" class="block text-sm font-medium text-gray-700">Agent Address</label>
  <textarea onChange={(e)=>setAgentAddress(e.target.value)} value={agentAddress} id="address" name="address" rows="2" placeholder="Agent Address Line"
    className="my-1 block text-xs w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"></textarea>
    </div>
     
    <button onClick={updateAgent} className='text-sm rounded-md  py-3 bg-black text-center text-white'>{loader ? <LoaderSmall/> : "Update Agent data"}</button>
     
    </div>
   </section>
    </div> 
    </Modal>
  )
}

export default UpdateAgentModal