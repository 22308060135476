import React from 'react'
import {AiOutlineClose} from 'react-icons/ai'

export default function Alert(props) {
  return (
    <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out">
    <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
        <div className="flex justify-between py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
           
                {props.children}
                <AiOutlineClose size={30} onClick={props.closeAlert} />
            </div>
        </div>
</div>
  )
}
