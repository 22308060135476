import React,{useState} from 'react'
import ExpenseAgentHistory from './sub-components/ExpenseAgentHistory';
import ExpenseLabelHistory from './sub-components/ExpenseLabelHistory';


function Expenses() {

    const [activeTab, setActiveTab] = useState("agent_expenses");

const onTabSelect = (tab) => {
  setActiveTab(tab);
};

  return (
    <main>
    <div className='bg-gray-100 flex justify-between px-10 py-5'>
     <p className='text-xs font-semibold text-gray-500'>Hello Digger</p>
     <p className='text-xs font-semibold text-gray-500'>Date: 21/06/2021</p>
    </div>

    <header className="flex items-center overflow-x-auto  w-full">
        {subNav?.map((nav, index) => (
          <h3
            onClick={() => onTabSelect(nav.tag)}
            key={index}
            className={`flex border w-full font-medium items-center justify-center text-center text-xs  cursor-pointer  py-2 transition-all ${
              activeTab === nav?.tag
                ? "text-gray-500 bg-gray-50"
                : "text-black  bg-gray-400 "
            } `}
          >
            {nav?.name}
          </h3>
        ))}
      </header>
    
      <section className='w-full mt-4'>
     {activeTab === "agent_expenses" && <ExpenseAgentHistory/>}
     {activeTab === "label_expenses" && <ExpenseLabelHistory/>}

      </section>
    </main>
  )
}

export default Expenses

const subNav = [
    {id: 1, name: "Agents expenses",tag:"agent_expenses"},
    {id: 1, name: "Label expenses",tag:"label_expenses"},
    
  ];