import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import Modal from '../../modal/Modal';
import { notify } from '../../utils/util'

function AddDeductionModal({open,setOpen,fullName,id,accountType,currency,quater}) {

    const [success,setSuccess] = useState(null);
    const [description, setDescription] = useState(null);
    const [loader, setLoader] = useState(false);
    const [balanceLoader, setBalanceLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [amount,setAmount] = useState(null);
    const [balance,setBalance] = useState(null);
    const [usdShare,setUsdShare] = useState(null);
    const [gbpShare,setGbpShare] = useState(null);
    const [agentShareUsd,setAgentShareUsd] = useState(null);
    const [agentShareGbp,setAgentShareGbp] = useState(null);
    
  

     const updateExpense = async () => {
        if (!currency || currency === ""){
            notify("Agent/Label preferred currency not detected","error")
            return
        }
        if (!amount || amount === ""){
            notify("please enter valid amount","error")
            return
        }

        if (!description || description === ""){
            notify("please enter expenses description","error")
            return
        }

        if (parseFloat(amount) > parseFloat(balance) ){
            notify("Sorry deduction amount cannot be more than debt","error")
            return
        }

        // if (accountType === "Agent" && currency === "USD"){
        //     if (parseFloat(amount) > parseFloat(agentShareUsd) ){
        //         notify("Deduction cannot be more than commission generated","error")
        //         return
        //     }

        // }else if (accountType === "Agent" && currency === "GBP"){
        //     if (parseFloat(amount) > parseFloat(agentShareGbp) ){
        //         notify("Deduction cannot be more than commission generated","error")
        //         return
        //     }
        // }

        setLoader(true)

        const getCurrentDate = () => {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(date.getFullYear()).slice(-2);
          
            return `${day}-${month}-${year}`;
          };   
          
        const formData = new FormData();
        formData.append('AccountID', id);
        formData.append('ExpenseDate', getCurrentDate());
        formData.append('AccountName', fullName);
        formData.append('ExpenseType', "Deduction");
        formData.append('Description', description);
        formData.append('Amount', amount);
        formData.append('AccountType', accountType);
        formData.append('DeductionForAccounting', "yes");
        formData.append('Quater', quater.toUpperCase());
        try {
            const response = await axios.post(api + `/update-expenses/update_expenses/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data.message === "Expenses updated successfully"){
             setLoader(false)
             setAmount(null)
             setBalance(null)
             notify("Expenses updated successfully","success")
             setOpen(false)
            }
        } catch (error) {
            notify(error?.message,"error")
            setLoader(false)
        }
    };


    const get_balance = async () => {
        setBalanceLoader(true)
        axios.get(base + `/get-balance/${id}/${quater}/${accountType}`).then(
            response =>{
                setBalance(response?.data?.data?.balance);
                setAgentShareGbp(response?.data?.data?.agent_gbp_share)
                setAgentShareUsd(response?.data?.data?.agent_usd_share)
                setUsdShare(response?.data?.data?.usd_share)
                setGbpShare(response?.data?.data?.gbp_share)
                setBalanceLoader(false)
            }
        ).catch(
             err =>{
                console.error(err);
                setBalanceLoader(false)
                setErrorMessage(err.message)
             }
        )
    }

    useEffect(() => {
      get_balance()
     }, [id]);
 
    


  return (
    <Modal open={open}>
    
    <div className='w-[50%] h-[500px] bg-white p-5'>
        <p onClick={()=>{setOpen(false)}} className='font-bold text-xl cursor-pointer text-end'>x</p>
    {balanceLoader ? 

    <div className='flex justify-center items-center mx-auto'>
    <LoaderSmall/>
    <p className='text-sm text-center '> Loading data...</p>
    </div>
    :   
   <section className='mt-4 m-10'>
    <h2 className='text-lg font-bold text-center my-4'>Enter deduction details</h2>
    <div className='flex flex-col justify-between mx-5 space-y-3'>
      {!errorMessage ? 
      <div className='flex gap-4 text-center'> 
      <p className='text-sm text-center text-gray-500 font-semibold'>Debt Owing: {currency === "USD" ? "$":"£"}{balance}</p>
      {accountType === "Agent" && 
      <>
      <p className='text-sm text-center text-gray-500 font-semibold'>Royalty: {currency === "USD" ? usdShare : gbpShare}</p>
      <p className='text-sm text-center text-gray-500 font-semibold'>Commision: {currency === "USD" ? agentShareUsd : agentShareGbp}</p>
      </>}
      
      </div>
      :
      <p className='text-sm text-red-500'>{errorMessage}, cant load debt balance</p>
      }
    <input placeholder='Agent/Label ID' disabled value={id} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    <input placeholder='Agent/Label name' disabled value={fullName} className='w-full p-2 text-xs  border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    <input placeholder='Enter quater' disabled value={quater} className='w-full p-2 text-xs  border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>

    <div className=''>
    <label for="message" className="block text-sm font-medium text-gray-700 mb-1">Amount</label>
    <input onChange={(e)=>setAmount(e.target.value)} placeholder='Amount' type='number' className='w-full p-2  text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>
    
    <div className="">
  <label for="description" className="block text-sm font-medium text-gray-700">Expense Description</label>
  <textarea onChange={(e)=>setDescription(e.target.value)} id="description" name="description" rows="2" placeholder="Enter your description here"
    className="my-1 block text-xs w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"></textarea>
    </div>
     
    <button onClick={updateExpense} className='text-sm  py-3 bg-red-600 text-center text-white'>{loader ? <LoaderSmall/> : "Add Deduction"}</button>
     
    </div>
   </section>   
}
    </div>
    
    </Modal>
  )
}

export default AddDeductionModal