import React,{useState} from 'react'
import ExportSingleBalancesButton from './ui/ExportSingleLabelBalancesButton';
import ExportJointBalancesButton from './ui/ExportJointLabelBalancesButton';
import ExportAgentBalancesButton from './ui/ExportAgentBalancesButton';
import ExportLabelPaidDirectlyButton from './ui/ExportLabelsPaidDirectlyButton';

function Exports() {

    const [activeTab, setActiveTab] = useState("actions");


  return (
    <main>
    <div className='bg-gray-100 flex justify-between px-10 py-5'>
     <p className='text-xs font-semibold text-gray-500'>Hello Digger</p>
     <p className='text-xs font-semibold text-gray-500'>Date: 21/06/2021</p>
    </div>

    
      <section className='w-full flex justify-center mx-auto items-center'>
     
       <div className='flex flex-col my-10 w-[40%] space-y-5 rounded-lg border border-blue-950 p-8'>
        <p className='text-sm font-semibold text-gray-700'>Export arrears, deductions and balances carried forward</p>
        <ExportAgentBalancesButton/>
        <ExportSingleBalancesButton/>
        <ExportJointBalancesButton/>
        <ExportLabelPaidDirectlyButton/>
        </div>
      </section>
    </main>
  )
}

export default Exports;

