import React,{useState} from 'react';
//import axios from 'axios';
//import {api} from '../utils/api'
import convertToCSV from '../../utils/createCsv';
import convertToPDF from '../../utils/createPdf';
import { PDFViewer,Document, Page,Text} from '@react-pdf/renderer';

// const ExportCsv = ({page_size}) => {
//   const handleExportCSV = () => {
//     axios.get(api +`/media-files/export_data/?page_size=${page_size}`, { responseType: 'blob' }) // Replace with your API endpoint
//       .then((response) => {
//         console.log(response)
//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const a = document.createElement('a');
//         a.href = url;
//         a.download = 'data.csv';
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//       })
//       .catch((error) => {
//         console.error('Error exporting CSV:', error);
//       });
//   };

const ExportCsv = ({data}) => {
    const [pdfData, setPdfData] = useState(null);

    const handleExportCSV = () => {
        console.log(data)
        const csvData = convertToCSV(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      };

const handleExportPDF = () => {
    const generatedPdfData = convertToPDF(["hello from this side","whos is that"]); // Replace with your PDF generation logic
        console.log("this is nu",generatedPdfData)
    setPdfData(generatedPdfData);
}

  return (
    <div className='flex ml-3'>
      <p className='text-[10px] font-semibold '>Export Data :</p>
      <button onClick={handleExportCSV} className='bg-gray-300 rounded-sm  h-6 text-xs mx-1 font-semibold px-3  text-gray-500' >csv</button>
      {/* <button onClick={handleExportPDF} className='bg-gray-300 rounded-sm  h-6 items-center px-3 text-xs font-semibold text-gray-500'>pdf</button> */}
      {pdfData && (
        <div style={{ width: '100%', height: '500px' }}>
          <PDFViewer width="100%" height="100%">
            <Document>
              <Page size="A4">
              <Text>{pdfData}</Text>
              </Page>
            </Document>
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default ExportCsv;
