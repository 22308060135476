import React, { useCallback,useState,useEffect } from 'react';
import axios from 'axios'
import {Link} from 'react-router-dom'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import ProgressBar from '../../progressbar/ProgressBar';
import {api,base} from '../../utils/api'
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import Folder from  '../../../assets/imgs/folder_image.png'
import SuccessIcon from  '../../../assets/imgs/success-icon.png'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import JSZip from 'jszip';


const EncodeFile = ()=>{
  const [files,setFiles] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null)
  const [success, setSuccess] = useState(false)


  

 


const handleDrop = useCallback(async (event) => {
  event.preventDefault();
  const audioFiles = [];
  const folder = [];
  const traverseDirectory = async (item, path = '') => {
    if (item.isFile) {
      const file = await new Promise((resolve) => item.file(resolve));
      const fileName = file.name.toLowerCase();

      if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
        audioFiles.push(file);
      }else if (file.type.startsWith('image/')) {
        audioFiles.push(file);
    }
    } else if (item.isDirectory) {
      const entries = await new Promise((resolve) => item.createReader().readEntries(resolve));

      for (const entry of entries) {
        await traverseDirectory(entry, `${path}${item.name}/`);
      }
    }
  };


 
  for (let i = 0; i < event.dataTransfer.items.length; i++) {
    
    const item = event.dataTransfer.items[i];
    if (item.kind === 'file' && item.type === "audio/wav") {
      const file = item.getAsFile();
      const fileName = file.name.toLowerCase();

      if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
        audioFiles.push(file);
      }else if (file.type.startsWith('image/')) {
          audioFiles.push(file);
          
        
    }
    } else if (item.webkitGetAsEntry) {
      const entry = item.webkitGetAsEntry();
      if (entry) {
        if (entry.isDirectory) {
            folder.push(entry)
        } else if (entry.isFile) {
          const file = item.getAsFile();
          const fileName = file.name.toLowerCase();

          if (fileName.endsWith('.mp3') || fileName.endsWith('.flac') || fileName.endsWith('.wav') || fileName.endsWith('.ogg')) {
            audioFiles.push(file);
          }else if (file.type.startsWith('image/')) {
            audioFiles.push(file);
        }
        }
      }
    }
  }
 
  if(folder.length > 0 ){
    for (let i = 0; i < folder.length; i++) {
      await traverseDirectory(folder[i]);
    }
  }

   
  setFiles(audioFiles);
}, []);



  const handleDragOver = (event) => {
    event.preventDefault();
  };

  

  const handleEncode = () => {
    setLoader(true)
    if(!folderName){
        setErrorMessage("please Enter Folder name")
        setLoader(false)
        return
    }
    // Rename folder and files
    const renamedFiles = files.map((file) => {
        const fileExtension = file.name.split(".").pop();
        // Create a new File object with the desired name
        let newName = "";
        if (file.type.startsWith('image/')) {
            newName = folderName+"."+fileExtension;
        }else{
            const filenameWithoutExtension = file.name.replace(/\.[^/.]+$/, "")
            const filecode = extractedStrings(filenameWithoutExtension)
             newName = folderName+filecode+"."+fileExtension;
        }
         // Change this as per your renaming logic
        return new File([file], newName, { type: file.type });
      });

    // Create a new ZIP file
    const zip = new JSZip();
    const folder = zip.folder(folderName);
    renamedFiles.forEach((file) => {
      folder.file(file.name, file);
    });

    // Generate the ZIP file
    zip.generateAsync({ type: "blob" }).then((blob) => {
      // Create a download link for the ZIP file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      //link.download = `${folderName}.zip`;
      link.setAttribute("download", `${folderName}.zip`);
      // Trigger a click event to initiate the download
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
    setLoader(false)
    setFiles(null);
    setErrorMessage(null);
    setFolderName("")
    setSuccess(true)
  };

const handleCancel = ()=>{
  setFiles(null);
  setErrorMessage(null);
  setFolderName("")
 
}


const handlefolderName = (e) => {
  setFolderName(e.target.value)
}

useEffect(() => {
    if (errorMessage) {
      
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 6000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);


  const extractedStrings =  (strings)=>{ 
    
    const lastUnderscoreIndex = strings.lastIndexOf("_");
    // Find the indices of the second underscore from the left (second underscore from the right in the original string)
    const secondToLastUnderscoreIndex = strings.lastIndexOf("_", lastUnderscoreIndex - 1);
    
    if (secondToLastUnderscoreIndex !== -1) {
        // Extract the substring between the second-to-last and last underscores
        const extractedString = strings.substring(secondToLastUnderscoreIndex);
        return extractedString;
      } else {
        // If there are less than two underscores, return the original string or handle it as needed
        return strings; // or throw an error, etc.
      }

}




 

   return(
       <div>
        <Navbar/>
     <div className='flex items-center justify-center mx-auto'>
    {!files && <div 
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    className="w-full cursor-pointer bg-[#F4F4F4] flex flex-col mx-24 justify-center items-center rounded-sm p-12 my-16">
                          <div className="flex w-max flex-col gap-1 justify-center items-center">
                          <img src={ImageUpload} alt='folderimage' className='h-[60px] w-[60px]' />
                            <p className="text-base font-semibold">
                              <span className="text-primary">
                              Drag and drop a file
                              </span>{" "}
                              you want to encode
                            </p>

                            <p className="text-xs font-normal text-[#18191F8C]">
                              Audio format: WAV,MP3 and FLAC
                            </p>
                          </div>
                        </div>}

     {files && <div  style={{border:"1px solid gray"}} className="mx-24 container py-[40px] flex justify-center items-center mt-14">
       <p onClick={handleCancel} className='absolute right-[160px] top-[170px] font-semibold flex cursor-pointer' >Cancel <AiOutlineClose size={23} /></p> 
        <div className='flex flex-col  border py-5 px-8'>
        <p className='text-sm text-blue-800 mb-2 font-semibold'>{files.length} file(s)</p>
        <img src={Folder} alt='folderimage' className='h-[120px] w-[220px]' />
        <p className='text-sm mb-2  mt-3 text-blue-800'>Enter Encoding name:</p>
        <input placeholder='Enter Folder name...' onChange={handlefolderName} className='w-[220px] border p-2 border-blue-800 rounded-md '/>
      </div>
      <div className='ml-[30px] flex flex-col'>
        <div>
            <p className='text-sm text-blue-800 font-semibold mb-3'>List of Files Found inside the folder</p>
        <ul className='h-[180px] w-[250px] border border-1 mb-5 overflow-auto bg-gray-300 shadow-lg'>
          {files.length > 0 ? Array.from(files).map((file,index)=>
          <li className='flex flex-col px-1 py-1  cursor-pointer rounded-sm text-xs bg-gray-100'  key={index}><p>{file.name}</p>
        </li>)
          :
          <li className='flex px-2 py-2 m-1  cursor-pointer rounded-sm text-sm bg-gray-100' >No Audio Files Selected</li>
          }

        </ul>
        </div>
        {errorMessage && <p className='text-xs text-red-500 mb-3 w-[250px]'>{errorMessage}</p>}
        <div className='flex'>
        <button onClick={handleEncode}   className='flex text-sm py-2 px-6 text-white bg-pink-600 rounded-md'>
         {loader ? <><LoaderSmall/> pls wait..</> : "Encode File" }
        </button>
        </div>
        
      </div>
        
            
    </div>
   
    }
    </div> 
  <Footer/>


  {success && <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                    <div className='flex flex-col justify-center items-center'>
                    <img src={SuccessIcon} alt='folderimage' className='h-[70px] w-[70px] ' />
                    <p className='font-semibold text-sm mt-2'>File Encoded Successfully. </p>
                    <p className='font-semibold text-gray-500 text-sm mt-2'>Downloading takes few seconds please wait ....</p>
                        </div>
                        <button class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" onClick={()=>{setSuccess(false)}} >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                        </div>
                    </div>
                </div>
            }
</div>
  )
};

export default EncodeFile;
