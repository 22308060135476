import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'

function Data() {
    const [data,setData] = useState(null);
    const [error,setError] = useState(null);
    const [loading,setLoading] = useState(false)

   

    useEffect(() => {
        axios.get(base + `/data-count/`).then(
            response =>{
                const res = response?.data?.data
                console.log("data-count:",res)
                setData(response?.data?.data);
                setLoading(false)
            }
        ).catch(
             err =>{
                console.error(err);
                setLoading(false);
                setError(err.message)
             }
        )
      }, []);
    
  return (
    <main className='mx-10 mt-10'>
      <p className='text-xs text-gray-500 font-bold my-2'>DATABASE</p>
     <table class="min-w-full border border-gray-300 bg-gray-50">
        <thead>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Catalogue</th>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Vendors</th>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Agents</th>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Labels</th>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Label Invoices</th>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Agents Invoices</th>
            <th class="border border-gray-300 text-gray-500 p-4 text-center">Vendor Statements</th>
        </thead>
        <tbody>
            <tr>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">2300</td>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">245</td>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">89</td>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">90</td>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">82</td>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">34</td>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">70</td>
            </tr>
        </tbody>
     </table>

     <div className='mt-16'>
     <p className='text-xs text-gray-500 font-bold my-2'>ERRORS & ALERTS(Catalogue data)</p>
     <table class=" border border-gray-300 w-[30%] bg-gray-50">
        <thead>
            <th class="border text-gray-500 border-gray-300 p-4 text-center">Unmatched Labels</th>
            <th class="border text-gray-500 border-gray-300 p-4 text-center">Missing Rates</th>
        </thead>
        <tbody>
            <tr>
            <td class="border border-gray-300 text-2xl p-4 font-bold text-center">2300</td>
            <td class="border border-gray-300 text-red-500 text-2xl p-4 font-bold text-center">245</td>
            </tr>
        </tbody>
     </table>
     </div>
    </main>
  )
}

export default Data