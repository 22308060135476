import React from "react";
import Loading from "./Loading";

const DataLoader = ({ message = "Loading data..." }) => {
  return (
    <div className="flex justify-center items-center h-96">
      <section className="flex items-center gap-2">
        <Loading size={20} color="#5E60CE" />
        <p className="text-xs">{message}</p>
      </section>
    </div>
  );
};

export default DataLoader;
