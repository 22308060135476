import React, { useState, useEffect } from 'react'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer'
import AudioVisualizer from './AudioVisualizer';


//import Audio from '../audio'


const  SoundbitLayout =  ()=> {
  
   

  return (
    <>
    <Navbar/>
    <div className='flex mt-20 justify-center items-center'>
    
    <AudioVisualizer />
    </div>
     <Footer/>
    </>
   
  );
};


export default SoundbitLayout

