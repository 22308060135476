import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable2 from '../ui/DynamicTable2';
import {api,base} from '../../utils/api'
import DataLoader from '../../smallLoader/DataLoader';
import ExpensesModal from './ExpensesModal';
import PromptModal from '../ui/PromptModal';


function LabelsExpenses({setNextStage}) {

const [labelData, setLabelData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(500);
const [loading, setLoading] = useState(false);
const [openModal, setOpenModal] = useState(false);
const [label, setLabel] = useState(null);
const [expenseDisplay,setExpenseDisplay] = useState(false)
const [err,setErr] = useState(null)
const [searchQuery, setSearchQuery] = useState('');
const [colMessage, setColMessage] = useState(null);
const [col, setCol] = useState(null);
const [openPrompt, setOpenPrompt] = useState(false);

const getLabels = (page,page_size)=>{
  setLoading(true)
  axios.get(api + `/labels/?page=${page}&page_size=${page_size}`).then(
      response =>{
       console.log("new response",response)
       let arr = response.data.results;
       setLabelData(arr)
       setLoading(false)
       console.log("response.data ok")
      }
  ).catch(
       err =>{
       console.log(err)
       setErr(err.message)
       setLoading(false)
       }
  )
}



useEffect(() => {
    getLabels(currentPage,page_size);
  }, [currentPage]);


  const displayExpense = (row)=>{
    console.log(row)
    setLabel(row)
    setExpenseDisplay(true)
  }

  const columns = [
    { key: "zkp_Label", header: "Label ID" },
    { key: "LabelName_pkt", header: "Label Name" },
    { key: "LabelStatus", header: "LabelStatus" },
   
  ];

  const labelSearch = async (col,colQuery) => {
    try {
      const response = await axios.get(base + `/search-label/${col}/${colQuery}`);
      let arr = response.data.results;
      console.log("this is arr",response.data.results)
      setLabelData(response.data.results);
      if (response.data.results.length < 1){
        setColMessage(`No result found for ${colQuery} `)
      }
      
    } catch (error) { 
      console.log(`Not Found : Error fetching search results for ${colQuery}`)
      
    }
  };

 

  useEffect(() => {
    if (colMessage) {
      
      const timer = setTimeout(() => {
        setColMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [colMessage]);



  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>
        <div className='flex justify-end'>
        <button onClick={()=>setOpenPrompt(true)} className='flex bg-black text-white justify-end py-2 px-6 text-xs rounded-sm mt-4 mr-14'>{"Next >>"}</button>
        </div>
    
{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <div className='rounded-md border-2 mt-5 md:mx-28 px-5'> 
      <p className='font-bold text-center text-lg my-3 text-gray-500'>Add Labels Expenses</p>
      <DynamicTable2
      data={labelData}
      columns={columns}
      addDeduction={displayExpense}
      showSearch={true}
      findAgent={labelSearch}
      setSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      colMessage={colMessage}
      type="expense"
    />
    </div> 
    }

               
    
    </div>
     
    <ExpensesModal open={expenseDisplay} setOpen={setExpenseDisplay} accountType="Label" fullName={label?.LabelName_pkt} id={label?.zkp_Label}/>
    <PromptModal stage="agent_deductions" setNextStage={setNextStage} message="Are you done with adding Label Expenses ?" open={openPrompt} setOpen={setOpenPrompt} />
    </div>
  )
}

export default LabelsExpenses