import React, { useCallback,useState,useEffect } from 'react';
import axios from 'axios'
import {Link} from 'react-router-dom'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import ProgressBar from '../../progressbar/ProgressBar';
import {api,base} from '../../utils/api'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import { readFromLocalStorage,notify } from '../../utils/util';

const UploadTemplate = ({setNextStage})=>{

  const [agentTemplates,setAgentTemplates] = useState(null);
  const [fileIndex,setFileIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [uploadButton, setUploadButton] = useState(true);
  const [errorMessage, setErrorMessage] = useState([])
  const [count, setCount] = useState(0)

  const quater = readFromLocalStorage("quater")
  



const handleDrop = useCallback(async (event) => {
  event.preventDefault();
  const templateFiles = [];
  const folder = [];

  const traverseDirectory = async (item, path = '') => {
    if (item.isFile) {
      const file = await new Promise((resolve) => item.file(resolve));
      const fileType = file.type;

      if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        templateFiles.push(file);
      }
    } else if (item.isDirectory) {
      const entries = await new Promise((resolve) => item.createReader().readEntries(resolve));
      for (const entry of entries) {
        await traverseDirectory(entry, `${path}${item.name}/`);
      }
    }
  };

  for (let i = 0; i < event.dataTransfer.items.length; i++) {
    const item = event.dataTransfer.items[i];

    if (item.kind === 'file' && item.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const file = item.getAsFile();
      templateFiles.push(file);

    } else if (item.webkitGetAsEntry) {
      const entry = item.webkitGetAsEntry();
      if (entry) {
        if (entry.isDirectory) {
            folder.push(entry)
        } else if (entry.isFile) {
          const file = item.getAsFile();
          const fileType = file.type;
          if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            templateFiles.push(file);
          }
        }
      }
    }
  }
 
  if(folder.length > 0 ){
    for (let i = 0; i < folder.length; i++) {
      await traverseDirectory(folder[i]);
    }
  }
  

  setAgentTemplates(templateFiles);
}, []);




  const handleDragOver = (event) => {
    event.preventDefault();
  };

  

  const upload = async (files,count)=>{ 
    if(!quater){
      notify("No quater found","error")
      return
    }
   
    const resMessages = []
    setLoader(true) 
      const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
          }
        
    }
      
        const file = files[0];
        setFileIndex(file)
       
  try{
        const formData = new FormData();
        formData.append('agent_template', file);
        formData.append('agent_file_name', file.name);
        formData.append('quater', quater);
        
       const response = await  axios.post(api + "/agent-temeplate-files/upload_agent_template/", formData, axiosConfig)
           resMessages.push(response?.data?.message)
           setFileIndex(null)
           setLoader(false)
             

  } catch(err){
           console.log(err)
           setLoader(prev=>!prev) 
           resMessages.push(err?.message) 
           setFileIndex(null)
           }
      //setSuccessMessage((prev)=>[...prev,...resMessages])
      setErrorMessage((prev)=>[...prev,...resMessages])
      setCount(count)
      // Recursive call to handle the next file in the array

      if (files.length === 1){
        setUploadButton(false)
        setLoader(false)
        notify("Templates uploaded successfully","success")
        setNextStage("add_agent_balance")
       }else{
      upload(files.slice(1),count+1);
       }

        
      }


const handleCancel = () => {
  setAgentTemplates(null);
  setErrorMessage([]);
  setUploadButton(true);
  setLoader(false)
}

// const getQuaters = ()=>{
  
//   axios.get(base + `/get-quaters/`).then(
//       response =>{
//        console.log("new quater response",response)
//        let arr = response.data.data;
//        setQuaters(arr)
//       }
//   ).catch(
//        err =>{
//        console.log(err)
//        }
//   )
// }

// useEffect(()=>{
//   getQuaters()
// },[])




   return(
       <>
     <div className='flex items-center justify-center'>
     
     {agentTemplates && <form  className="w-full cursor-pointer bg-[#F4F4F4] flex flex-col mx-24 justify-center border-2 items-center rounded-sm p-12 my-10">
         {/* <select onChange={(e)=>setQuater(e.target.value)} className='w-[120px] h-[20px] border border-red-400 text-xs mb-3  bg-white'>
          <option>Select quater</option>
          {quaters.map((qua,index)=>(
           <option key={index} value={qua}>{qua}</option>
          ))}
         </select> */}
         
        <ul className='h-[150px] w-[500px] py-1 mb-3  overflow-auto bg-gray-300 shadow-lg'>
          {agentTemplates.length > 0 ? Array.from(agentTemplates).map((file,index)=>
          <li className='flex flex-col px-1 py-1 m-1 text-gray-500  cursor-pointer rounded-sm text-xs bg-gray-100'  key={index}><span className='flex justify-between'><p>{file.name}</p></span>
         {fileIndex===file && 
          <ProgressBar duration={10} timing={5}/>
          }
           <p className={`${errorMessage[index] === "Agent Data uploaded successfully" ?"text-green-600" :"text-red-500"} font-bold text-[10px] my-1`}>{errorMessage[index]}</p>
        </li>)
          :
          <li className='flex px-2 py-2 m-1  cursor-pointer rounded-sm text-sm bg-gray-100' >No Audio Files Selected</li>
          }

        </ul>
        
        <div className='flex mt-4'>
        <button onClick={handleCancel} type="button" className="bg-gray-400 rounded-sm mx-3 py-2 px-4 text-white text-sm">Cancel</button>
        {agentTemplates.length > 0  ?
         <>{uploadButton && <button onClick={()=>{upload(agentTemplates,1)}} type="button" className="bg-[#0c0d4e] rounded-sm mx-3 py-2 px-4 text-white text-1xl">{loader? (<LoaderSmall/>):"Upload"}</button>}</>:""
       }
      
        </div>
        
          <p className='text-sm font-semibold text-gray-500'>uploaded {count} templates, remaining {agentTemplates.length - count}</p>  
        
    </form>
   
    }

     {!agentTemplates && <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      className="w-full cursor-pointer bg-slate-300 flex flex-col mx-24 justify-center border-2 items-center rounded-sm p-12 my-10"
      style={{ border: '2px dashed #f97c2f8c', padding: '50px', textAlign: 'center' }}
    >
      <div className="flex w-max flex-col gap-1 justify-center items-center">
                          <img src={ImageUpload} alt='folderimage' className='h-[60px] w-[60px]' />
                            <p className="text-base font-semibold">
                              <span className="text-primary">
                              Drag and drop agent template files
                              </span>{" "}
                             
                            </p>

                            <p className="text-xs font-normal text-[#1f1b188c]">
                              File format: csv, xlxs
                            </p>
                          </div>
    </div>}
       
   
    </div> 

   
</>
  )
};

export default UploadTemplate;
