import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall';

function UpdateDatabaseModal({onClose,setOnClose}) {

    const [file,setFile] = useState(null);
    const [fileError,setFileError] = useState(null);
    const [progress,setProgress] = useState(0);
    const [updating,setUpdating] = useState(false);
    const [success,setSuccess] = useState(null);

    const handleDragOver = (event)=>{
        event.preventDefault()
     }
   
   
     const handleDrop = (event)=>{
       event.preventDefault();
       const droppedFile = event.dataTransfer.files;
       if(droppedFile.length > 1){
        setFileError("Only a single csv file is permitted at a time")
        return
       }
       if (droppedFile[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // File is XLSX
        setFile(droppedFile[0]);
        setFileError(null)
      } else {
        setFileError("file format not supported, upload a .XLSX file")
       return
      }
     }

     const updateDatabase = async () => {
        setUpdating(true)
        const formData = new FormData();
        formData.append('csv_file', file);
        try {
            const response = await axios.post(base + `/upload-csv/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data.message === "Data upload successful"){
             setUpdating(false) 
             setProgress(100)
             setSuccess("Data upload successful")
            }
        } catch (error) {
            console.error('Error uploading CSV:', error);
            setFileError(error?.message)
            setUpdating(false)
        }
    };
    
    const updateProgress = async () => {
        try {
            const response = await axios.get(base + `/update-progress/`);
            console.log(response)
            setProgress(response.data.progress);

        } catch (error) {
            console.error('Error uploading CSV:', error);
            setProgress(0);
            setUpdating(false)
        }
    };
    
    // Run the function after every 10 seconds
    useEffect(() => {
        if (updating === true) {
        const intervalId = setInterval(updateProgress, 10000);

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);

        }
      }, [updating]);


       
 



  return (
    <>
   {onClose && <div role="dialog"  className="flex bg-gray-600 bg-opacity-50 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none transition duration-450 ease-in-out"  id="modal">
                <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                    <div class="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">
                    <h1 class="text-gray-800 font-lg font-bold tracking-normal leading-tight mb-4">Update Database</h1>
                    {fileError && <p className='text-xs text-red-500'>{fileError}</p>}
                    {success && <p className='text-xs text-green-700'>{success}</p>}
                     {!file &&<div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="container h-[200px] flex justify-center items-center mt-14"
                style={{ border: '2px dashed #cccccc', padding: '20px', textAlign: 'center' }}
                >
            <p className="text-blue-800 font-semibold text-lg">Drag & drop excel file</p>
             </div>}

             {file &&<div
                className="container h-[200px] px-10 flex flex-col border justify-center items-center mt-10"
                style={{ textAlign: 'center' }}
                >
                    
            <p className='flex flex-col px-10 py-5  cursor-pointer rounded-sm text-sm font-semibold bg-gray-100'>{file.name}</p>
            <button onClick={updateDatabase} className='flex items-center justify-center text-sm text-white bg-blue-600 rounded-sm px-4 py-2 mt-4'>{updating ? <>Pls wait... <LoaderSmall/></>  :"Update Database"}</button>
           {progress > 0 && 
           <>
           <p className='text-xs mt-2'>Step 2 : Updating database...</p>
           <div className='p-0 h-2 w-[100%] rounded-sm mt-4 border text-[10px]' >
            <p style={{width:progress+'%'}} className={`bg-green-700 h-2 rounded-sm text-xs`}></p>{progress}%
            </div></>}
            {progress === 0 && updating && <p className='text-xs'>step 1 : Uploading xlsx file to server...this might take up to 2 mins </p>}
            
             </div>}
                      
                        <button onClick={()=>{setOnClose(false);setFile(null);setFileError(null);setProgress(0);setSuccess(null)}} class="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600"  >
                            <svg  xmlns="http://www.w3.org/2000/svg"  class="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>}
            </>
  )
}

export default UpdateDatabaseModal