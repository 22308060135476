import React, {useState,useRef} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import LoaderSmall from '../smallLoader/LoaderSmall';
import {api,base} from '../utils/api'


const SinglefileUpload = (props)=>{

  const [files,setFiles] = useState(null);
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const inputRef = useRef();

  const handleDragOver = (event)=>{
     event.preventDefault()
  }


  const handleDrop = (event)=>{
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  }


  const upload = (id)=>{   
    setLoader(prev=>!prev) 
    let audios = Array.from(files).filter((file)=>(file.type === "audio/mpeg" || file.type === "audio/wav"))
    console.log("audios filterd",audios)
      let formData = new FormData();
      formData.append("id",Number(id))
      if (files && audios.length < 1){
        setErrorMessage("Sorry No File Selected")
        setLoader(prev=>!prev)
        return
      }
      if (files && audios.length > 1){
        setErrorMessage("single audio allowed at a time")
        setLoader(prev=>!prev)
        return
      }
      for(let i=0; i<audios.length; i++){
        formData.append(`audio`, audios[i]);
      }

         
      
         //console.log(filename)
         const axiosConfig = {
          headers: {
              "Content-Type": "multipart/form-data",
            }
      }
   axios.post(api + `/update-audio/${id}/upload_audio/`, formData, axiosConfig).then(
       response =>{
        console.log("this is response message : ",response)
        setLoader(prev=>!prev) 
        setSuccess(true)
        setFiles([])
        setErrorMessage(response?.data?.message)
        props.getAudios()
       }
   ).catch(err=>{
           console.log(err)
        setLoader(prev=>!prev) 
        setErrorMessage(err?.message)
        }
   )
  }



  if (files) return(
    <form  style={{border:"1px solid gray"}} className="py-2 px-4 w-[500px] h-[200px] mx-auto flex flex-col justify-center items-center rounded-md">
        <ul className=' h-auto w-[400px] mt-5 overflow-auto'>
          {Array.from(files).map((file,index)=>
          <li  key={index}>{file.type === "audio/mpeg" || file.type === "audio/wav"? file.name : null}</li>)
          }
        </ul>
        <div className='flex my-2'>
        <button onClick={()=>setFiles(null)} type="button" className="bg-gray-400 rounded-sm mx-3 py-2 px-4 text-white text-1xl">Cancel</button>
        <button onClick={()=>upload(props.audioId)} type="button" className="bg-[#0c0d4e] rounded-sm mx-3 py-2 px-4 text-white text-1xl">{loader? (<LoaderSmall/>):"Upload"}</button>
        </div>
        <p className={`${success?'text-green-800':'text-red-500'} font-bold text-sm my-2 mx-2`}>{errorMessage}</p>
            
    </form>
  )

   return(
    <>
    {!files &&<form  style={{border:"2px dotted gray"}} className="py-2 bg-gray-50 px-4 w-[500px] h-[200px] mx-auto flex flex-col justify-center items-center rounded-md"
    onDragOver={handleDragOver}
    onDrop={handleDrop}
    >
           <label className="font-bold my-2 text-1xl">Drag and drop audio file</label>
           <h5 className="font-bold my-2 text-1xl">Or</h5>
           
           <input
           type="file"
           className="my-2"
           id="formFile"
           name="audio"
           accept= 'audio/*'
           onChange={(event)=>setFiles(event.target.files)}
           hidden
           ref = {inputRef}
            />
           
            <button onClick={()=>inputRef.current.click()} type="button" className="bg-[#0c0d4e] rounded-sm py-2 px-4 text-white text-1xl">Select  File</button>
    </form>}
    </>
   )
}


export default SinglefileUpload;