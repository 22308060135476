import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import Modal from '../../modal/Modal';
import { notify } from '../../utils/util'

function ExpenseHistoryModal({open,setOpen,id}) {

    
    const [success,setSuccess] = useState(null);
    const [description, setDescription] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null)
    const [expenseType,setExpenseType] = useState(null);
    const [amount,setAmount] = useState(null);
    const [history,setHistory] = useState([]);



    useEffect(() => {
           setLoading(true)
          axios.get(base + `/get-expenses-history/${id}`).then(
              response =>{
                  const res = response?.data?.data
                  setHistory(response?.data?.data);
                  setLoading(false)
              }
          ).catch(
               err =>{
                  console.error(err);
                  setLoading(false);
                  setError(err.message)
               }
          )
        }, []);
    


  return (
    <Modal open={open}>
    <div className='w-[50%] h-[500px] bg-white p-5'>
        <p onClick={()=>{setOpen(false)}} className='font-bold text-xl cursor-pointer text-end'>x</p>
       
   <section className='mt-4 m-5'>
    <h2 className='text-sm font-bold text-blue-800 text-center my-4'>Expenses history for ({id})</h2>
    <div className='flex flex-col bg-slate-100 justify-between mx-5 p-5 space-y-3 rounded-sm border h-[370px] overflow-x-scroll'>
        {loading && <><LoaderSmall/><p className='text-center text-xs font-semibold mt-10'>loadin...</p></> }
        {error ? <p className='text-center text-sm font-semibold text-red-500 mt-10'>{error}</p> :
        <>

   {history.length > 0 ? 
   <>
   {
    history.map((hist,index)=>(
     <div index={index} className='flex flex-col  mx-6 my-1 p-2 rounded border border-blue-700'>
        
        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Name : </p>
         <p className='text-xs text-gray-500'>{hist?.AccountName}</p>
        </div>

        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Advance : </p>
         <p className='text-xs text-gray-500'>{hist?.Advance}</p>
        </div>
        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Commission : </p>
         <p className='text-xs text-gray-500'>{hist?.Commission}</p>
        </div>
        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Deduction : </p>
         <p className='text-xs text-gray-500'>{hist?.Deduction}</p>
        </div>

        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Description : </p>
         <p className='text-xs text-gray-500'>{hist?.Description}</p>
        </div>

        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Amount : </p>
         <p className='text-xs text-gray-500'>{hist?.Amount}</p>
        </div>

        <div className='flex justify-between'>
         <p className='font-semibold text-xs '>Date : </p>
         <p className='text-xs text-gray-500'>{hist?.ExpenseDate}</p>
        </div>
     </div>
    ))
   }
   </> :
   <p className='text-center text-sm font-semibold text-gray-500 mt-10'>No Expenses history found..</p>
   }
        
        </>}
  
    
    </div>
   </section>
    </div> 
    </Modal>
  )
}

export default ExpenseHistoryModal

