import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable3 from './ui/DynamicTable3';
import { base,api } from '../utils/api';
import DataLoader from '../smallLoader/DataLoader';
import { notify,writeToLocalStorage } from '../utils/util';
import UploadLabelsPaidDirectlyModal from './sub-components/UploadLabelsPaidDirectlyModal';




function AddMiscLabel({setNextStage}) {

const [labelData, setLabelData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(500);
const [loading, setLoading] = useState(false);
const [loader, setLoader] = useState(false);
const [label, setLabel] = useState(null);
const [expenseDisplay,setExpenseDisplay] = useState(false)
const [err,setErr] = useState(null)
const [searchQuery, setSearchQuery] = useState('');
const [colMessage, setColMessage] = useState(null);
const [agents, setAgents] = useState(null);
const [openPrompt, setOpenPrompt] = useState(false);
//writeToLocalStorage("quater","2024Q1")

const getLabels = (page,page_size)=>{
  setLoading(true)
  axios.get(api + `/labels/?page=${page}&page_size=${page_size}`).then(
      response =>{
       console.log("new response",response)
       let arr = response.data.results;
       setLabelData(arr)
       setLoading(false)
       console.log("response.data ok")
      }
  ).catch(
       err =>{
       console.log(err)
      
       setLoading(false)
       }
  )
}



useEffect(() => {
    getLabels(currentPage,page_size);
  }, [currentPage]);


  const addLabelMisc = async (row) => {
    setLoader(true)
    const formData = new FormData();
    const misc = row?.Ismisc == "YES" ? "NO" : "YES"
    formData.append('zkp_Label', row?.zkp_Label);
    formData.append('Ismisc', misc);
    try {
        const response = await axios.post(api + `/label-misc/add_label_misc/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }

        });
        console.log(response)
        if (response.data.message === "Label added to miscellaneous successfully"){
         setLoader(false) 
         notify(response.data.message,"success")
         getLabels(currentPage,page_size)
        }
    } catch (error) {
        console.error('Error uploading CSV:', error);
        notify(error,"error")
        setLoader(false)
    }
};


const updatePrimaryAgent = async (agentName,labelId) => {
  setLoader(true)
  const agentId = agents.find(
    (item) => item?.NameFull == agentName).zkp_Agent;

  const formData = new FormData();
  formData.append('zkp_Label', labelId);
  formData.append('agentID', agentId);
  formData.append('agentName',agentName);
  try {
      const response = await axios.post(api + `/label-primary-agent/add_primary_agent/`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }

      });
      console.log(response)
      if (response.data.message === "Primary agent successfully updated for this label"){
       setLoader(false) 
       notify(response.data.message,"success")
       getLabels(currentPage,page_size)
      }
  } catch (error) {
      notify(error,"error")
      setLoader(false)
  }
};

  const columns = [
    { key: "zkp_Label", header: "Label ID" },
    { key: "LabelName_pkt", header: "Label Name" },
    { key: "LabelStatus", header: "LabelStatus" },
   
  ];

  const labelSearch = async (col,colQuery) => {
    try {
      const response = await axios.get(base + `/search-label/${col}/${colQuery}`);
      let arr = response.data.results;
      console.log("this is arr",response.data.results)
      setLabelData(response.data.results);
      if (response.data.results.length < 1){
        setColMessage(`No result found for ${colQuery} `)
      }
      
    } catch (error) { 
      console.log(`Not Found : Error fetching search results for ${colQuery}`)
      
    }
  };

 

  useEffect(() => {
    if (colMessage) {
      
      const timer = setTimeout(() => {
        setColMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [colMessage]);


  const getAgents = ()=>{
  axios.get(base + `/get-agents/`).then(
      response =>{
       console.log("new agent response",response)
       let arr = response.data.data;
       setAgents(arr)
      }
  ).catch(
       err =>{
       console.log(err)
       }
  )
}



useEffect(()=>{
  getAgents()
},[])



  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>
      <div className='flex mt-3 w-full items-end justify-end'>
  <button onClick={()=>setOpenPrompt(true)} className='text-sm rounded-md bg-gray-700 text-white px-6 py-2  mr-10'>Upload Labels Paid Directly</button>
  </div> 
{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <div className='rounded-md border-2 mt-5 md:mx-28 px-5'> 
      <p className='font-bold text-center text-lg my-3 text-blue-900'>Add Labels as Misc (Labels paid directly by agents)</p>
      <DynamicTable3
      data={labelData}
      columns={columns}
      showSearch={true}
      findAgent={labelSearch}
      setSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      colMessage={colMessage}
      addMisc={addLabelMisc}
      agents={agents}
      onChange={updatePrimaryAgent}
    />
    </div> 
    }      
    
    </div>
     
    
    <UploadLabelsPaidDirectlyModal open={openPrompt} setOpen={setOpenPrompt}/>
    </div>
  )
}

export default AddMiscLabel


