import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable2 from '../ui/DynamicTable2';
import {api,base} from '../../utils/api'
import DataLoader from '../../smallLoader/DataLoader';
import AddDeductionModal from './AddDeductionModal';
import PromptModal from '../ui/PromptModal';
import SuccessGif from '../../../assets/imgs/gifsuccess.gif'


function ReportSuccess() {

  const [labelData, setLabelData] = useState([])
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(null);
  const [deductionDisplay,setDeductionDisplay] = useState(false)
  const [openPrompt, setOpenPrompt] = useState(false);
  const [err,setErr] = useState(null)


const getLabelsDebtors = ()=>{
    setLoading(true)
    axios.get(base + `/label-debtors/`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.data;
         setLabelData(arr)
         setLoading(false)
        
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getLabelsDebtors();
  }, []);


  const displayDeduction = (row)=>{
    console.log(row)
    setLabel(row)
    setDeductionDisplay(true)
  }


  const columns = [
    { key: "zkp_Label", header: "Label ID" },
    { key: "LabelName_pkt", header: "Label Name" },
    { key: "LabelStatus", header: "LabelStatus" },
   
  ];



  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>
    
{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <div className='rounded-md flex w-[50%] flex-col items-center justify-center border-green-500 gap-4 border mx-auto mt-5  px-5'> 
      <div>
      <img
      src={SuccessGif}
      alt="success image"
      className='h-[250px]'
      />
      </div>
      <p className='font-bold text-center text-lg mb-3 text-green-500'>Report generated successfully</p>

      <p className='font-bold text-center text-sm mb-6 text-gray-500'>Check download folder <span onClick={()=>window.location.reload()} class="bg-blue-900 text-white py-2 px-4 rounded ml-3 cursor-pointer">Return to Dashboard</span></p>

     
    </div> 
    }

               
    
    </div>
    
    </div>
  )
}

export default ReportSuccess