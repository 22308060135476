import React,{useState,useEffect} from 'react';
import { base } from '../../utils/api';
import Loading from '../../smallLoader/Loading';

const ExportReportButton = ({ agentID,fname,lname,agentSummaryData,quater }) => {
    const [exporting,setExporting] = useState(false)
    const [message,setMessage] = useState(false)

    const handleExport = () => {
        setExporting(true)
        if(!quater){
            setMessage("please select quater")
            setExporting(false)
            return
        }
        if(!agentSummaryData || agentSummaryData?.length < 1){
            setMessage("sorry no summary data for this agent")
            setExporting(false)
            return
        }
        const url = `${base}/export-agent-summary-report/${agentID}/${quater}`;  // Adjust URL if needed
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
        .then(response => {
            if (response.ok) {
                return response.blob();
            }else{
                setExporting(false)
                setMessage("sorry something went wrong")
                throw new Error('Network response was not ok.')
            }
            
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${agentSummaryData?.[0]?.YearQuarter}_${agentID}_${fname}${lname}_Agent-Commision-Summary.zip`); // Specify the filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setExporting(false)
            setMessage("Report generated successfully")
        })
        .catch(error => console.error('Export failed:', error));
    };

    useEffect(() => {
        if (message) {
          
          const timer = setTimeout(() => {
            setMessage(null);
          }, 6000);
    
          return () => clearTimeout(timer);
        }
      }, [message]);

    return (
        <div className='flex flex-col'>
        <button className='flex text-white bg-black py-2 px-4 text-xs text-center rounded-sm' onClick={handleExport}>
           {exporting ? <><Loading color="white" size={18} /> Generating report...</> :"Generate report"} 
        </button>
        {message && <p className={`${message==="Report generated successfully" ? "text-green-600" : "text-red-500"} text-[10px]`}>{message}</p>}
        </div>
    );
};

export default ExportReportButton;
