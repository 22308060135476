import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import Modal from '../../modal/Modal';
import { notify,readFromLocalStorage } from '../../utils/util'

function ExpensesModal({open,setOpen,fullName,id,accountType}) {

    
    const [success,setSuccess] = useState(null);
    const [description, setDescription] = useState(null);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
    const [expenseType,setExpenseType] = useState(null);
    const [amount,setAmount] = useState(null);
    const quater = readFromLocalStorage("quater")

     

     const updateExpense = async () => {
 

        if (!amount || amount === ""){
            notify("please enter valid amount","error")
            return
        }

        if (!description || description === ""){
            notify("please enter expenses description","error")
            return
        }

        if (!expenseType){  
            notify("please select expense type","error")
            return
        }

        
        if (!quater){  
            notify("No quater detected","error")
            return
        }
        setLoader(true)

        const getCurrentDate = () => {
            const date = new Date();
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const year = String(date.getFullYear()).slice(-2);
          
            return `${day}-${month}-${year}`;
          };   

        const formData = new FormData();
        formData.append('AccountID', id);
        formData.append('ExpenseDate', getCurrentDate());
        formData.append('AccountName', fullName);
        formData.append('ExpenseType', expenseType);
        formData.append('Description', description);
        formData.append('Amount', amount);
        formData.append('Quater', quater.toUpperCase());
        formData.append('AccountType', accountType);
        formData.append('DeductionForAccounting', "no");
        try {
            const response = await axios.post(api + `/update-expenses/update_expenses/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data.message === "Expenses updated successfully"){
             setLoader(false)
             setAmount(null)
             setExpenseType(null)
             notify("Expenses updated successfully","success")
             setOpen(false)
            }
        } catch (error) {
            notify(error?.message,"error")
            setLoader(false)
        }
    };
    


  return (
    <Modal open={open}>
    <div className='w-[50%] h-[550px] bg-white p-5'>
        <p onClick={()=>{setOpen(false)}} className='font-bold text-xl cursor-pointer text-end'>x</p>
       
   <section className='mt-4 m-10'>
    <h2 className='text-lg font-bold text-center my-4'>Enter expenses details</h2>
    <div className='flex flex-col justify-between mx-5 space-y-3'>
    <input placeholder='Agent/Label ID' disabled value={id} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    <input placeholder='Agent/Label name' disabled value={fullName} className='w-full p-2 text-xs  border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
   
    <div className=''>
    <label for="message" class="block text-sm font-medium text-gray-700 mb-1">Amount</label>
    <input onChange={(e)=>setAmount(e.target.value)} placeholder='Amount' type='number' className='w-full p-2  text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>

    <div className='w-full'>
    <label for="expense-type" class="block text-sm font-medium text-gray-700 mb-1">Expense Type</label>
        <select onChange={(e)=>setExpenseType(e.target.value)} className='w-full p-2  text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'>
            <option>
               select type
            </option>
            <option value="Advance">
               Loan/Advance +
            </option>
            <option value="Commission">
               Commission +
            </option>
            
        </select>
    </div>
    
    <div class="">
  <label for="description" class="block text-sm font-medium text-gray-700">Expense Description</label>
  <textarea onChange={(e)=>setDescription(e.target.value)} id="description" name="description" rows="2" placeholder="Enter your description here"
    className="my-1 block text-xs w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"></textarea>
    </div>
     
    <button onClick={updateExpense} className='text-sm  py-3 bg-blue-900 text-center text-white'>{loader ? <LoaderSmall/> : "Add Expense"}</button>
     
    </div>
   </section>
    </div> 
    </Modal>
  )
}

export default ExpensesModal