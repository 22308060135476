import React, { useState, useEffect, useRef, useContext } from 'react';
import { FileContext } from './context/fileContext';
import { useNavigate } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';

const AudioPlayer = () => {
  const [audioFile, setAudioFile] = useState(null);
  const inputRef = useRef(null);
  const { fileURL, setFileURL, setFileData } = useContext(FileContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (audioFile) {
      setFileData(URL.createObjectURL(audioFile), audioFile);
      navigate('/loopaudio');
    }
  }, [audioFile, setFileData, navigate]);

  const handleFileChange = (e) => {
    setAudioFile(e.target.files[0]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setAudioFile(droppedFile);
  };

  return (
    <>
      <Navbar />
      <div className='flex mt-20 justify-center items-center'>
        <form
          style={{ border: '2px dotted gray' }}
          className='py-2 bg-gray-50 mb-6 px-4 w-[500px] h-[300px] mx-auto flex flex-col justify-center items-center rounded-md'
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <label className='font-bold my-2 text-1xl'>
            Drag and drop audio file
          </label>
          <h5 className='font-bold my-2 text-1xl'>Or</h5>

          <input
            type='file'
            className='my-2'
            id='formFile'
            name='audio'
            accept='audio/*'
            onChange={handleFileChange}
            hidden
            ref={inputRef}
          />
          <button
            onClick={() => inputRef.current.click()}
            type='button'
            className='bg-[#0c0d4e] rounded-sm py-2 px-4 text-white text-1xl'
          >
            Select File
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default AudioPlayer;
