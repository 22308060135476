import React, {useEffect,useState} from 'react'
import { api,base } from '../../utils/api';
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import { notify } from '../../utils/util';
import { writeToLocalStorage } from '../../utils/util';

function RunAccount({setNextStage}) {

    const [file,setFile] = useState(null);
    const [fileError,setFileError] = useState(null);
    const [progress,setProgress] = useState(0);
    const [updating,setUpdating] = useState(false);
    const [success,setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
  


    const handleDragOver = (event)=>{
        event.preventDefault()
     }
   
     const handleDrop = (event)=>{
       event.preventDefault();
       const droppedFile = event.dataTransfer.files;
       if(droppedFile.length > 1){
        setFileError("Only a single csv file is permitted at a time")
        return
       }
       if (droppedFile[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // File is XLSX
        setFile(droppedFile[0]);
        setFileError(null)
      } else {
        setFileError("file format not supported")
       return
      }
     }

     const updateDatabase = async () => {
      
        setLoader(true)

        const qua = file.name.split('_')[2];
        const formData = new FormData();
        formData.append('rate_summary', file);
        formData.append('file_name', file.name);
        try {
            const response = await axios.post(api + `/label-summary/upload_summary/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data.message === "Data upload successful"){
             setLoader(false) 
             //setProgress(100)
             notify("Summary upload successful","success")
             writeToLocalStorage("quater",qua)
             setNextStage("upload_template")
            }
        } catch (error) {
            console.error('Error uploading CSV:', error);
            setErrorMessage(`${error?.message}, are you sure you uploaded the correct file?`)
            setLoader(false)
        }
    };
    
   

    useEffect(() => {
        if (errorMessage || success) {
          
          const timer = setTimeout(() => {
            setErrorMessage(null);
            setSuccess(null);
          }, 6000);
    
          return () => clearTimeout(timer);
        }
      }, [errorMessage,success]);


       
      const handleCancel = ()=>{
        setFile(null);
        setErrorMessage(null);
      }

      

  return (
    <div className='flex items-center  justify-center mx-auto'>
       
    {!file && <div 
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    className="w-full cursor-pointer bg-[#F4F4F4] flex flex-col mx-24 justify-center border-2 items-center rounded-sm p-12 my-10">
        {fileError && <p className='text-sm text-red-500'>{fileError}</p>}
                          <div className="flex w-max flex-col gap-1 justify-center items-center">
                          <img src={ImageUpload} alt='folderimage' className='h-[60px] w-[60px]' />
                            <p className="text-base font-semibold">
                              <span className="text-primary">
                              Drag and drop Label royalty summary 
                              </span>{" "}
                             
                            </p>

                            <p className="text-xs font-normal text-[#18191F8C]">
                              File format: csv, xlxs
                            </p>
                          </div>
                        </div>}

     {file && <div   className="w-full cursor-pointer bg-[#F4F4F4] flex flex-col mx-24 justify-center border-2 items-center rounded-sm p-12 my-10">
       <p onClick={handleCancel} className='absolute text-red-600 right-40 top-[275px] font-semibold cursor-pointer text-sm' >x Cancel </p> 
       <div className='flex flex-col my-5 w-1/2'>
       
      <p className=' px-10 py-2 border text-center bg-slate-300  cursor-pointer rounded-sm text-sm text-gray-800' >{file.name}</p>
       </div>
        {errorMessage && <p className='text-xs text-red-500 mb-3 w-[250px]'>{errorMessage}</p>}
        {success && <p className='text-xs text-green-500 mb-3 w-[250px]'>{success}</p>}
        <div className='flex'>
        <button onClick={updateDatabase}   className='flex text-sm py-2 px-6 text-white bg-gray-600 rounded-md'>
         {loader ? <><LoaderSmall/> pls wait..</> : "Upload" }
        </button>
        </div>
        
      </div>
   
    } 
    </div> 
  )
}

export default RunAccount