import React, {useEffect,useState} from 'react'
import Modal from '../../modal/Modal';


function PromptModal({open,setOpen,message,setNextStage,stage,final,generateReport}) {

    const [loader, setLoader] = useState(false);
    
     
  return (
    <Modal open={open}>
    <div className='w-[50%] h-[250px] bg-white p-5 border rounded-md'>
        <p onClick={()=>{setOpen(false)}} className='font-bold text-xl cursor-pointer text-end'>x</p>
       
   <section className='mt-4 m-10'>
    <h2 className='text-base text-gray-600 font-bold text-center my-4'>{message}</h2>

    <p className='text-blue-900 text-sm font-semibold text-center'>{final === "yes" ? "Click to generate quaterly report": "Click yes to move to the next stage" }</p>
    <div className='flex justify-center mt-6 gap-10'>
        <button onClick={()=>{setOpen(false)}} className='text-white bg-red-600 text-center rounded-sm px-6 py-2'>No</button>
        {final === "yes" ? 

        <button onClick={generateReport} className='fex bg-black text-white text-center py-2 px-6 text-sm rounded-sm '>Generate Report</button>
        :
        <button onClick={()=>setNextStage(stage)} className='text-white bg-green-600 text-center rounded-sm px-6 py-2'>yes</button>
}
    </div>
   </section>
    </div> 
    </Modal>
  )
}

export default PromptModal