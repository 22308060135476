import React,{useState,useEffect} from 'react';
import { base } from '../../utils/api';
import Loading from '../../smallLoader/Loading';
import { readFromLocalStorage } from '../../utils/util';

const ExportSingleBalancesButton = () => {
    const [exporting,setExporting] = useState(false)
    const [message,setMessage] = useState(false)

    //const quater = readFromLocalStorage("quater")
    

    const handleExport = () => {
        setExporting(true)
        // if(!quater){
        //     setMessage("quater not found")
        //     setExporting(false)
        //     return
        // }
        
        const url = `${base}/export-single-label-balances`;  // Adjust URL if needed
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/xlsx',
            },
        })
        .then(response => {
            if (response.ok) {
                return response.blob();
            }else{
                setExporting(false)
                setMessage("sorry something went wrong")
                throw new Error('Network response was not ok.')
            }
            
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `SingleLabelAccountBalanceCarriedForward_ForNextQuater.xlsx`); // Specify the filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setExporting(false)
            setMessage("Balances generated successfully")
        })
        .catch(error => console.error('Export failed:', error));
    };

    useEffect(() => {
        if (message) {
          
          const timer = setTimeout(() => {
            setMessage(null);
          }, 6000);
    
          return () => clearTimeout(timer);
        }
      }, [message]);

    return (
        <div className='flex flex-col'>
        <button className='flex text-white bg-blue-950 font-semibold rounded-md justify-center py-4 text-sm text-center ' onClick={handleExport}>
           {exporting ? <><Loading color="white" size={18} /> Generating balances...</> :"Export single labels balances"} 
        </button>
        {message && <p className={`${message==="Balances generated successfully" ? "text-green-600" : "text-red-500"} text-center text-sm`}>{message}</p>}
        </div>
    );
};

export default ExportSingleBalancesButton;
