import React, { useEffect, useState } from 'react';
import LoaderSmall from '../../smallLoader/LoaderSmall';
import {api,base} from '../../utils/api'
import axios from 'axios'

const GetBpm = () => {
  const [audio, setAudio] = useState(null);
  const [audioFeatures, setAudioFeatures] = useState(null);
  const CLIENT_ID = 'bd8472ae262b4697a224cce12b61c448';
  const CLIENT_SECRET = '90f55eac87874eb48c3e3eda5d0c2441';
  let isrc = ["USV351359425","USV351359427"]

  const updatebpm = (obj)=>{   
    let formData = new FormData();
    formData.append("bpm",  obj.bpm)
    formData.append("keyz",  obj.key)
    formData.append("mood",  obj.mode)
    formData.append("isrc",  obj.isrc)
       const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
          }
    } 
 axios.post(base + `/updatebpm/`, formData, axiosConfig).then(

     response =>{
      console.log(response)
     }
 ).catch(err=>{
         console.log(err)
      }
 )
}
  
  const getToken = async () => {
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`,
      },
      body: 'grant_type=client_credentials',
    });
  
    const data = await response.json();
    return data.access_token;
  };
  
 
  const getAudios = ()=>{
    let idArr = []
    axios.get(base + `/getaudioisrc/`).then(
        response =>{
            const arr = response?.data?.data
            for(let i = 0; i<arr.length; i++){
             idArr.push(response?.data?.data[i].ISRC)
            }
            fetchTrackId(idArr)
        }
    ).catch(
         err =>{
         console.log(err)
         }
    )
      
}

const fetchTrackId = async (ISRC_CODE) => {
    const ids = []
    const isrcs = []
    for (let i= 0; i<ISRC_CODE.length; i++){
        const token = await getToken();
        const response = await fetch(`https://api.spotify.com/v1/search?type=track&q=isrc:${ISRC_CODE[i]}`, {
          headers: {
              Authorization: `Bearer ${token}`,
            },
        });
        if (response.ok) {
          const data = await response.json();
          const isrc = data?.tracks?.items[0]?.external_ids?.isrc
          const id = data?.tracks?.items[0]?.id
          if(id === undefined){
            const notfoundData ={
                bpm:"not_found",
                key:"not_found",
                mode:"not_found",
                isrc:ISRC_CODE[i]
            }
            updatebpm(notfoundData)
            console.log("data not found")
          }else{
          ids.push(id);
          isrcs.push({isrc:isrc,id:id})
          }
         
        } else {
          console.error('Error fetching audio analysis data');
        }

    }

    const audioIds = ids.join(",")
fetchAudioFeatures(audioIds,isrcs)
};

  

  const fetchAudioFeatures = async (audioIds,isrcs) => {
     const token = await getToken();
     const response = await fetch(`https://api.spotify.com/v1/audio-features?ids=${audioIds}`, {
     headers: {
         Authorization: `Bearer ${token}`,
       },
   });
   console.log(response)
   if (response.ok) {
     const data = await response.json();
     let finalData = []
     const arr = data?.audio_features
     for(let i = 0;i<arr.length;i++){
        if(arr[i].id === isrcs[i]?.id){
            const neededData = {
                bpm:data.audio_features[i].tempo,
                key:data.audio_features[i].key,
                mode:data.audio_features[i].mode,
                id:data.audio_features[i].id,
                isrc:isrcs[i].isrc
             }
             updatebpm(neededData)
             console.log("bpm submited successfully")
             finalData.push(neededData)
             
        }
     } 
     setAudioFeatures(finalData);
     console.log("Audio featueres:",finalData)
   } else {
     console.error('Error fetching audio analysis data');
   }
 };


    
 setInterval(() => {
    getAudios()
  }, 30000)
  

     
  
 

  if (!audioFeatures) {
    return <div className='flex flex-col mx-auto items-center justify-center '>
      <LoaderSmall/> 
      <p className='text-sm text-center' >Loading ...</p>
      </div>;
  }

  // Render your audio analysis data here
  return <div className='flex mx-auto items-center justify-center text-center text-sm' >{JSON.stringify(audioFeatures)}</div>;
};

export default GetBpm;
