import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable from '../ui/DynamicTable';
import {api,base} from '../../utils/api'
import DataLoader from '../../smallLoader/DataLoader';


function ExpenseLabelHistory() {

const [expenseData, setExpenseData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(500);
const [loading, setLoading] = useState(false);
const [err,setErr] = useState(null)

const getExpense = (page,page_size)=>{
    setLoading(true)
    axios.get(api + `/expenses/?page=${page}&page_size=${page_size}`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.results;
         const agents = arr.filter(item => item.AccountType === "Label");
         setExpenseData(agents)
       
         setLoading(false)
         console.log("response.data ok")
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getExpense(currentPage,page_size);
  }, [currentPage]);


const displayDetails = (row)=>{
    console.log(row)
}


  const columns = [
    { key: "AccountType", header: "AccountType" },
    { key: "AccountID", header: "AccountID" },
    { key: "AccountName", header: "AccountName" },
    { key: "Advance", header: "Advance" },
    { key: "Commission", header: "Commission" },
    { key: "Deduction", header: "Deduction" },
    { key: "Amount", header: "Amount" },
    { key: "Description", header: "Description" },
    { key: "ExpenseDate", header: "Date" },
   
  ];


  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>

{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <> 
      <p className='font-bold text-center text-lg mb-3 text-gray-500'>Label Expenses History</p>
      <DynamicTable
      data={expenseData}
      columns={columns}
      click={displayDetails}
    />
    </> 
    }

               
    
    </div>
     
    </div>
  )
}

export default ExpenseLabelHistory