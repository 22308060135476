import React,{useEffect,useRef, useState} from 'react';
import LoaderSmall from '../../smallLoader/LoaderSmall';
import axios from 'axios';
import {useParams} from 'react-router-dom';
import {Link,useNavigate} from 'react-router-dom';
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer';
import {api,base} from '../../utils/api'

const EditData = ()=>{
 
  const [load,setLoad] = useState(false)
  const [message,setMessage] = useState("")
  const [singlefile,setSinglefile] = useState("")
  const navigate = useNavigate()

  const params = useParams();

const arranger = useRef();
const artist = useRef();
const artistUrl = useRef();
const bpm = useRef();
const c_line= useRef();
const key = useRef();
const mood= useRef();
const dataType= useRef();
const deleted= useRef();
const displayUPC= useRef();
const download= useRef();
const error= useRef();
const exclusive= useRef();
const genre= useRef();
const genreAlt= useRef();
const genreSub= useRef();
const genreSubAlt= useRef();
const hiddenTrack= useRef();
const ISRC= useRef();
const itunesLink= useRef();
const labelCatalogNo= useRef();
const labelName= useRef();
const language= useRef();
const manufacturerUPC= useRef();
const masterCarveouts= useRef();
const p_line= useRef();
const parentalAdvisory= useRef();
const previewClipStartTime= useRef();
const previewClipDuration= useRef();
const priceBand= useRef();
const producer= useRef();
const publisher= useRef();
const recordingArtist= useRef();
const releaseDate= useRef();
const releaseName= useRef();
const royaltyRate= useRef();
const salesStartDate= useRef();
const salesEndDate= useRef();
const songVersion= useRef();
const territories= useRef();
const territoriesCarveOuts= useRef();
const title= useRef();
const totalTracks= useRef();
const totalVolumes= useRef();
const trackDuration= useRef();
const trackNo= useRef();
const vendorName= useRef();
const VolumeNo= useRef();
const wholesalePrice= useRef();
const writer= useRef();
const zIDKey_UPISRC = useRef();

  
  

  const updatefile = ()=>{   
    setLoad(prev=>!prev)
    let formData = new FormData();
    const id = Number(params.id)
    formData.append("id",id)
    formData.append("arranger", arranger.current.value)
    formData.append("artist",  artist.current.value)
    formData.append("artistUrl",  artistUrl.current.value)
    formData.append("bpm",  bpm.current.value)
    formData.append("c_line",  c_line.current.value)
    formData.append("key",  key.current.value)
    formData.append("mood",  mood.current.value)
    formData.append("dataType",  dataType.current.value)
    formData.append("deleted",  deleted.current.value)
    formData.append("displayUPC",  displayUPC.current.value)
    formData.append("error",  error.current.value)
    formData.append("download",  download.current.value)
    formData.append("genre",  genre.current.value)
    formData.append("genreAlt",  genreAlt.current.value)
    formData.append("genreSubAlt",  genreSubAlt.current.value)
    formData.append("genreSub",  genreSub.current.value)
    formData.append("exclusive",  exclusive.current.value)
    formData.append("hiddenTrack",  hiddenTrack.current.value)
    formData.append("ISRC",  ISRC.current.value)
    formData.append("itunesLink",  itunesLink.current.value)
    formData.append("labelCatalogNo",  labelCatalogNo.current.value)
    formData.append("language",  language.current.value)
    formData.append("labelName",  labelName.current.value)
    formData.append("manufacturerUPC",  manufacturerUPC.current.value)
    formData.append("masterCarveouts",  masterCarveouts.current.value)
    formData.append("p_line",  p_line.current.value)
    formData.append("parentalAdvisory",  parentalAdvisory.current.value)
    formData.append("previewClipStartTime",  previewClipStartTime.current.value)
    formData.append("previewClipDuration",  previewClipDuration.current.value)
    formData.append("priceBand",  priceBand.current.value)
    formData.append("producer",  producer.current.value)
    formData.append("publisher",  publisher.current.value)
    formData.append("recordingArtist",  recordingArtist.current.value)
    formData.append("releaseDate",  releaseDate.current.value)
    formData.append("releaseName",  releaseName.current.value)
    formData.append("royaltyRate",  royaltyRate.current.value)
    formData.append("salesEndDate",  salesEndDate.current.value)
    formData.append("salesStartDate",  salesStartDate.current.value)
    formData.append("songVersion",  songVersion.current.value)
    formData.append("territories",  territories.current.value)
    formData.append("territoriesCarveOuts",  territoriesCarveOuts.current.value)
    formData.append("title",  title.current.value)
    formData.append("totalTracks",  totalTracks.current.value)
    formData.append("totalVolumes",  totalVolumes.current.value)
    formData.append("trackDuration",  trackDuration.current.value)
    formData.append("trackNo",  trackNo.current.value)
    formData.append("vendorName",  vendorName.current.value)
    formData.append("VolumeNo",  VolumeNo.current.value)
    formData.append("writer",  writer.current.value)
    formData.append("wholesalePrice",  wholesalePrice.current.value)
    formData.append("zIDKey_UPISRC", zIDKey_UPISRC.current.value)

       const axiosConfig = {
        headers: {
            "Content-Type": "multipart/form-data",
          }
    }
 axios.post(base + "/updatefile/", formData, axiosConfig).then(
     response =>{
      console.log(response)
      setLoad(prev=>!prev)
      setMessage("successfully updated")
      navigate("/dashboard",{replace:true})
     }
 ).catch(err=>{
         console.log(err)
         setMessage("Sorry something went wrong"+ err.toString())
         setLoad(prev=>!prev)
      }
 )
}
useEffect(()=>{
   getFileToEdit()
   
},[])

const getFileToEdit = ()=>{ 
  axios.get(base + `/getsinglefile/${params.id}`).then(
      response =>{
       setSinglefile(response.data)
      }
  ).catch(
       err =>{
       console.log(err)
       }
  )
}


  return(
    <>
     <Navbar/>
      <div className="w-full items-center justify-center mx-auto flex flex-col px-4 mt-3 mb-[70px]">
     <div className='p-4 h-[470px] w-[800px] overflow-auto rounded-md'>
          <p className='font-bold my-5 text-lg'>Fill in all Fields you want to update </p>
         <form class="w-full ">
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="arranger">
        Arranger
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="arranger" id="arranger" type="text" 
      defaultValue={singlefile?.data?.[0]?.arranger}
      ref={arranger}
      
        />
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="artist">
        Artist
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="artist" id="artist" type="text" 
      defaultValue={singlefile?.data?.[0]?.artist}
      ref={artist}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="artist-url">
        Artist Url
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="artistUrl" id="artist_url" type="text"
      defaultValue={singlefile?.data?.[0]?.artistUrl}
      ref={artistUrl}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bpm">
        bpm
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="bpm" id="bpm" type="text"
       defaultValue={singlefile?.data?.[0]?.bpm}
       ref={bpm}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="key">
        Key
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="key" id="key" type="text"
      defaultValue={singlefile?.data?.[0]?.key}
      ref={key}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="bpm">
        Mood
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="mood" id="mood" type="text"
       defaultValue={singlefile?.data?.[0]?.mood}
       ref={mood}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="c_line">
        C_line
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="c_line" id="c_line" type="text"
       defaultValue={singlefile?.data?.[0]?.c_line}
       ref={c_line}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Data type
      </label>
      <div class="relative">
        <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="dataType" id="data-type"
         ref={dataType}>
          <option defaultValue={singlefile?.data?.[0]?.dataType}>Track</option>
          <option defaultValue={"Track"}>Track</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
        Deleted
      </label>
      <div class="relative">
        <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="deleted" id="deleted"
        ref={deleted}>
          <option defaultValue={singlefile?.data?.[0]?.deleted}>{singlefile?.data?.[0]?.deleted}</option>
          <option value={"Y"}>Y</option>
          <option value={"N"}>N</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
        Display Upc
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="displayUPC" id="displayUPC" type="text"
      defaultValue={singlefile?.data?.[0]?.displayUPC}
      ref={displayUPC}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="download">
        Download
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="download" id="download" type="text"
      defaultValue={singlefile?.data?.[0]?.download}
      ref={download}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="error">
        Error
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="error" id="error" type="text"
      defaultValue={singlefile?.data?.[0]?.error}
      ref={error}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="download">
        Exclusive
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="exclusive" id="exclusive" type="text"
      defaultValue={singlefile?.data?.[0]?.exclusive}
      ref={exclusive}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="genre">
        Genre
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="genre" id="genre" type="text"
      defaultValue={singlefile?.data?.[0]?.genre}
      ref={genre}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="genre_alt">
       GenreAlt
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="genreAlt" id="genre_alt" type="text"
      defaultValue={singlefile?.data?.[0]?.genreAlt}
      ref={genreAlt}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="genre_sub">
        GenreSub
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="genreSub" id="genre_sub" type="text"
      defaultValue={singlefile?.data?.[0]?.genreSub}
      ref={genreSub}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="">
      genreSubAlt
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="genreSubAlt" id="genreSubAlt" type="text"
      defaultValue={singlefile?.data?.[0]?.genreSubAlt}
      ref={genreSubAlt}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="hidden_track">
        Hidden Track
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="hiddenTrack" id="hidden_track" type="text"
      defaultValue={singlefile?.data?.[0]?.hiddenTrack}
      ref={hiddenTrack}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="isrc">
        ISRC
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="ISRC" id="isrc" type="text"
      defaultValue={singlefile?.data?.[0]?.ISRC}
      ref={ISRC}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="itunes_link">
        Itunes Link
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="itunesLink" id="itunes_link" type="text"
      defaultValue={singlefile?.data?.[0]?.itunesLink}
      ref={itunesLink}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="label_catalogNo">
        Label Catalog No
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="labelCatalogNo" id="labelCatalogNo" type="text"
      defaultValue={singlefile?.data?.[0]?.labelCatalogNo}
      ref={labelCatalogNo}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="label_name">
        Label Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="labelName" id="label_name" type="text"
      defaultValue={singlefile?.data?.[0]?.labelName}
      ref={labelName}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="language">
        Language
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="language" id="language" type="text"
      defaultValue={singlefile?.data?.[0]?.language}
      ref={language}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="manufacturer_upc">
        Manufacturer Upc
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="manufacturerUPC" id="manufacturer_upc" type="text"
      defaultValue={singlefile?.data?.[0]?.manufacturerUPC}
      ref={manufacturerUPC}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="masterCarveouts">
        Master Carveouts
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="masterCarveouts" id="masterCarveouts" type="text"
      defaultValue={singlefile?.data?.[0]?.masterCarveouts}
      ref={masterCarveouts}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="p_line">
        p_line
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="p_line" id="p_line" type="text"
      defaultValue={singlefile?.data?.[0]?.p_line}
      ref={p_line}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="parental_advisory">
        Parental Advisory
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="parentalAdvisory" id="parentalAdvisory" type="text"
     defaultValue={singlefile?.data?.[0]?.parentalAdvisory}
     ref={parentalAdvisory}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="previewClipDuration">
        Preview Clip Duration
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="previewClipDuration" id="previewClipDuration" type="text"
      defaultValue={singlefile?.data?.[0]?.previewClipDuration}
      ref={previewClipDuration}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="previewClipStartTime">
       preview Clip StartTime
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="previewClipStartTime" id="previewClipStartTime" type="text"
      defaultValue={singlefile?.data?.[0]?.previewClipStartTime}
      ref={previewClipStartTime}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="price_band">
        Price Band
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="priceBand" id="priceBand" type="text"
      defaultValue={singlefile?.data?.[0]?.priceBand}
      ref={priceBand}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="producer">
        Producer
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="producer" id="producer" type="text"
      defaultValue={singlefile?.data?.[0]?.producer}
      ref={producer}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="publisher">
        Publisher
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="publisher" id="publisher" type="text"
      defaultValue={singlefile?.data?.[0]?.publisher}
      ref={publisher}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="recording_artist">
        Recording Artist
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="recordingArtist" id="recordingArtist" type="text"
       defaultValue={singlefile?.data?.[0]?.recordingArtist}
       ref={recordingArtist}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="release_date">
        Release date
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="releaseDate" id="releaseDate" type="date"
      defaultValue={singlefile?.data?.[0]?.releaseDate}
      ref={releaseDate}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="release_name">
        Realease Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="releaseName" id="releaseName" type="text"
      defaultValue={singlefile?.data?.[0]?.releaseName}
      ref={releaseName}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="royalty_rate">
        Royalty Rate
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="royaltyRate" id="royaltyRate" type="text"
      defaultValue={singlefile?.data?.[0]?.royaltyRate}
      ref={royaltyRate}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="salesEndDate">
        Sales End Date
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="salesEndDate" id="salesEndDate" type="date"
      defaultValue={singlefile?.data?.[0]?.salesEndDate}
      ref={salesEndDate}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="salesStartDate">
        Sales Start Date
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="salesStartDate" id="salesStartDate" type="date"
      defaultValue={singlefile?.data?.[0]?.salesStartDate}
      ref={salesStartDate}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="song_version">
        Song Version
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="song_version" id="songVersion" type="text"
      defaultValue={singlefile?.data?.[0]?.songVersion}
      ref={songVersion}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="territories">
        Territories
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="territories" id="territories" type="text"
      defaultValue={singlefile?.data?.[0]?.territories}
      ref={territories}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="territories_carveout">
        Territories Carveouts
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="territoriesCarveOuts" id="territoriesCarveOuts" type="text"
      defaultValue={singlefile?.data?.[0]?.territoriesCarveOuts}
      ref={territoriesCarveOuts}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="title">
        Title
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="title" id="title" type="text"
      defaultValue={singlefile?.data?.[0]?.title}
      ref={title}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="total_tracks">
        Total Tracks
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="totalTracks" id="total_tracks" type="number"
      defaultValue={singlefile?.data?.[0]?.totalTracks}
      ref={totalTracks}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="total_volumes">
        Total Volumes
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="totalVolumes" id="total_volumes" type="number"
      defaultValue={singlefile?.data?.[0]?.totalVolumes}
      ref={totalVolumes}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="track_duration">
        Track Duration
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="trackDuration" id="trackDuration" type="text"
      defaultValue={singlefile?.data?.[0]?.trackDuration}
      ref={trackDuration}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="track_no">
       Track No
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="trackNo" id="track_no" type="number"
      defaultValue={singlefile?.data?.[0]?.trackNo}
      ref={trackNo}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="vendor_name">
        Vendor Name
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="vendorName" id="vendor_name" type="text"
      defaultValue={singlefile?.data?.[0]?.vendorName}
      ref={vendorName}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="volumeNo">
       Volume No
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="VolumeNo" id="VolumeNo" type="number"
      defaultValue={singlefile?.data?.[0]?.VolumeNo}
      ref={VolumeNo}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="Wholesale_price">
        Whole Sale Price
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" name="wholesalePrice" id="wholesalePrice" type="number"
      defaultValue={singlefile?.data?.[0]?.wholesalePrice}
      ref={wholesalePrice}/>
    </div>
    <div class="w-full md:w-1/2 px-3">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="writer">
       Writer
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" name="writer" id="writer" type="text"
      defaultValue={singlefile?.data?.[0]?.writer}
      ref={writer}/>
    </div>
  </div>
  <div class="flex flex-wrap -mx-3 mb-6">
    <div class="w-full  px-3 mb-6 md:mb-0">
      <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="zIDKey_UPISRC">
      zIDKey_UPCISRC
      </label>
      <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" readOnly name="zIDKey_UPISRC" id="zIDKey_UPISRC" type="text"
      defaultValue={singlefile?.data?.[0]?.zIDKey_UPISRC}
      ref={zIDKey_UPISRC}/>
    </div>
   
  </div>
  <div class="flex flex-wrap justify-end -mx-3 mb-6">
    <div class="w-full md:w-1/2 px-3">
    <button type="button" onClick={updatefile} className="bg-[#0c0d4e] rounded-md mx-3 py-3 px-12 text-white text-1xl">{load? (<><LoaderSmall/>Updating...</>):"Upload"} </button>
    <p class="text-red-500 text-xs italic">{message}</p>
    </div>
  </div>
</form>
</div>
</div>
    </>
  )
}

export default EditData;