import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import Modal from '../../modal/Modal';
import { notify,readFromLocalStorage } from '../../utils/util'

function AddBalanceModal({open,setOpen,id,accountType,name}) {

    const [loader, setLoader] = useState(false);
    const [amount,setAmount] = useState(null);
    const quater = readFromLocalStorage("quater")
    

     

     const updateBalance = async () => {
        
        if (!amount || amount === ""){
            notify("please enter valid amount","error")
            return
        }

        if (!quater){
            notify("Quater not detected","error")
            return
        }

        setLoader(true)

        const formData = new FormData();
        formData.append('AccountID', id);
        formData.append('Amount', amount);
        formData.append('AccountType', accountType);
        formData.append('quater', quater);
        
        try {
            const response = await axios.post(base + `/create-balance/`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                   
                }

            });
            console.log(response)
            if (response.data.message === "Balance updated successfully"){
             setLoader(false)
             setAmount(null)
             notify("Balance updated successfully","success")
             setOpen(false)
            }
        } catch (error) {
            notify(error?.message,"error")
            setLoader(false)
        }
    };

    


  return (
    <Modal open={open}>
    
    <div className='w-[50%] h-[350px] bg-white p-5'>
        <p onClick={()=>{setOpen(false);setAmount(null)}} className='font-bold text-xl cursor-pointer text-end'>x</p>
    
   <section className='mt-4 m-10'>
    <h2 className='text-lg font-bold text-center my-4'>Enter Balance Amount</h2>
    <div className='flex flex-col justify-between mx-5 space-y-3'>
     <input placeholder='Name' disabled value={name} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    <input placeholder='Agent/Label ID' disabled value={id} className='w-full p-2 text-xs border border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
   
    <div className=''>
    <label for="message" className="block text-sm font-medium text-gray-700 mb-1">Amount</label>
    <input onChange={(e)=>setAmount(e.target.value)} placeholder='Amount' type='number' className='w-full p-2  text-xs border mb-3 border-gray-300 shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50'/>
    </div>
    
    <button onClick={updateBalance} className='text-sm   py-3 bg-green-600 text-center text-white'>{loader ? <LoaderSmall/> : "Add Balance"}</button>
     
    </div>
   </section>   

    </div>
    
    </Modal>
  )
}

export default AddBalanceModal;