import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import Modal from '../../modal/Modal';
import { notify } from '../../utils/util'

function UploadLabelsPaidDirectlyModal({open,setOpen}) {

    const [file,setFile] = useState(null);
    const [fileError,setFileError] = useState(null);
    const [success,setSuccess] = useState(null);
    const [unmatchedLabels, setUnmatchedLabels] = useState([]);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
  


    const handleDragOver = (event)=>{
        event.preventDefault()
     }
   
     const handleDrop = (event)=>{
       event.preventDefault();
       const droppedFile = event.dataTransfer.files;
       if(droppedFile.length > 1){
        setFileError("Only a single csv file is permitted at a time")
        return
       }
       if (droppedFile[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // File is XLSX
        setFile(droppedFile[0]);
        setFileError(null)
      } else {
        setFileError("file format not supported")
       return
      }
     }

     const updateDatabase = async () => {
        setLoader(true)
        const formData = new FormData();
        formData.append('labels_paid_directly', file);
        try {
            const response = await axios.post(api + `/upload-labels-paid-directly/upload_label_paid_directly/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data){
             setLoader(false) 
             setFile(null)
             setUnmatchedLabels(response?.data?.data)
             notify("Labels updated successfully","success")
            }
        } catch (error) {
            console.error('Error uploading CSV:', error);
            setErrorMessage(`${error?.message}, are you sure you uploaded the correct file?`)
            setLoader(false)
        }
    };
    
    
    useEffect(() => {
        if (errorMessage || success) {
          
          const timer = setTimeout(() => {
            setErrorMessage(null);
            setSuccess(null);
          }, 6000);
    
          return () => clearTimeout(timer);
        }
      }, [errorMessage,success]);


       
      const handleCancel = ()=>{
        setFile(null);
        setUnmatchedLabels([])
        setErrorMessage(null);
      }

      



  return (
    <Modal open={open}>
    <div className='w-[50%] h-[400px] bg-white p-5'>
        <p onClick={()=>{setOpen(false);setFile(null);setErrorMessage(null);setUnmatchedLabels([]);}} className='font-bold text-xl cursor-pointer text-end'>x</p>
       
    {!file && unmatchedLabels.length < 1 && <div 
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    className="w-full cursor-pointer flex flex-col h-[300px] justify-center border-2 items-center rounded-sm mt-5 ">
        {fileError && <p className='text-sm text-red-500'>{fileError}</p>}
                          <div className="flex w-max flex-col gap-1 justify-center items-center">
                          <img src={ImageUpload} alt='folderimage' className='h-[60px] w-[60px]' />
                            <p className="text-base font-semibold">
                              <span className="text-primary">
                              Drag and drop Label file
                              </span>{" "}
                             
                            </p>

                            <p className="text-xs font-normal text-[#18191F8C]">
                              File format: csv, xlxs
                            </p>
                          </div>
                        </div>}

     {file && unmatchedLabels.length < 1 && <div   className="w-full cursor-pointer flex flex-col justify-center h-[300px] border-2 items-center rounded-sm mt-5">
     
      <p className=' px-5 py-2 border text-center bg-slate-100  cursor-pointer rounded-sm text-sm text-gray-600' >{file.name}</p>
       
        {errorMessage && <p className='text-xs text-red-500 mt-3'>{errorMessage}</p>}
        {success && <p className='text-xs text-green-500 mt-3 '>{success}</p>}
        <div className='flex gap-6 mt-10'>
        <p onClick={handleCancel} className='font-bold cursor-pointer text-sm  border py-2 px-4 rounded-md' >Cancel </p>
        <button onClick={updateDatabase}   className='flex text-sm py-2 px-6 text-white bg-blue-700 rounded-md'>
         {loader ? <><LoaderSmall /> pls wait.. </>: "Upload Labels" }
        </button>
        
        </div>
        
      </div>
   
    } 

    {unmatchedLabels.length > 0 && !file && 
    <div   className="w-full cursor-pointer flex flex-col justify-center h-[300px] border-2 items-center rounded-sm mt-5">
        <p className='text-sm font-semibold text-blue-900 mb-4'>List of unmatched labels</p>
    <div className='flex flex-col overflow-y-scroll w-[80%] h-[200px]  m-5 '>
     
    {unmatchedLabels.map((label,index)=>(
    <div className='flex gap-2' key={index}>
    <p className='text-xs font-semibold' >{index+1}.</p>
    <p className='text-xs font-semibold'>{label}</p>
    </div>
    ))
    }
    </div>
    </div>
    }
    </div> 
    </Modal>
  )
}

export default UploadLabelsPaidDirectlyModal