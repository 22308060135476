import React, { useState, useEffect } from 'react'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall';
import styled from "styled-components";
import Navbar from '../../navbar/Navbar';
import Footer from '../../footer/Footer'

//import Audio from '../audio'


const  LandingPage =  ()=> {
  
  const [audios, setAudios] = useState([]);
    
    return (
      <>
       <Navbar/>
      <div className="m-5 px-3">
        
        <img 
        className='w-full h-[450px]'  
        src="https://d33wubrfki0l68.cloudfront.net/26b6adba9de9cca3c3654cb585de2e9749addbe0/cd8f6/assets/images/posts/live-music.jpg" 
        alt=""/> 

        {/* <img 
        className='w-full h-[450px]'  
        src="https://th.bing.com/th/id/OIP.jPltqSM9kygFZ9d7uSboyAHaE8?rs=1&pid=ImgDetMain" 
        alt=""/> */}
   

      </div>
     <Footer/>
    </>
    )
  }
 

export default LandingPage

const ModalContainer = styled.div`
  background: white;
  opacity:1;
  min-height: auto;
  padding:10px;
  width: 600px;
  max-width: 400px;
  border-radius: 15px;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
`;
