import React, {useEffect,useState} from 'react'
import {api,base} from '../../utils/api'
import axios from 'axios'
import LoaderSmall from '../../smallLoader/LoaderSmall'
import ImageUpload from  '../../../assets/imgs/image-upload.svg'
import {AiOutlineClose} from 'react-icons/ai'
import Modal from '../../modal/Modal';


function UploadAgentsModal({open,setOpen}) {

    const [file,setFile] = useState(null);
    const [fileError,setFileError] = useState(null);
    const [progress,setProgress] = useState(0);
    const [updating,setUpdating] = useState(false);
    const [success,setSuccess] = useState(null);
    //const [quater, setQuarter] = useState(null);
    const [loader, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null)
  


    const handleDragOver = (event)=>{
        event.preventDefault()
     }
   
     const handleDrop = (event)=>{
       event.preventDefault();
       const droppedFile = event.dataTransfer.files;
       if(droppedFile.length > 1){
        setFileError("Only a single csv file is permitted at a time")
        return
       }
       if (droppedFile[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        // File is XLSX
        setFile(droppedFile[0]);
        setFileError(null)
      } else {
        setFileError("file format not supported")
       return
      }
     }

     const updateDatabase = async () => {
        setLoader(true)
        const formData = new FormData();
        formData.append('agent_data', file);
        try {
            const response = await axios.post(api + `/agent-data/upload_agent_data/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }

            });
            console.log(response)
            if (response.data.message === "Agent Data updated successfully"){
             setLoader(false) 
             //setProgress(100)
             setSuccess("Agents Data updated successfully")
            }
        } catch (error) {
            console.error('Error uploading CSV:', error);
            setErrorMessage(`${error?.message}, are you sure you uploaded the correct file?`)
            setLoader(false)
        }
    };
    
    // const updateProgress = async () => {
    //     try {
    //         const response = await axios.get(api + `/update-progress/`);
    //         console.log(response)
    //         setProgress(response.data.progress);

    //     } catch (error) {
    //         console.error('Error uploading CSV:', error);
    //         setProgress(0);
    //         setUpdating(false)
    //     }
    // };
    
    // Run the function after every 10 seconds
    // useEffect(() => {
    //     if (updating === true) {
    //     const intervalId = setInterval(updateProgress, 10000);

    //     // Cleanup function to clear the interval when the component unmounts
    //     return () => clearInterval(intervalId);

    //     }
    //   }, [updating]);

    useEffect(() => {
        if (errorMessage || success) {
          
          const timer = setTimeout(() => {
            setErrorMessage(null);
            setSuccess(null);
          }, 6000);
    
          return () => clearTimeout(timer);
        }
      }, [errorMessage,success]);


       
      const handleCancel = ()=>{
        setFile(null);
        setErrorMessage(null);
        //setQuarter(null)
      }

      



  return (
    <Modal open={open}>
    <div className='w-[50%] h-[400px] bg-white p-5'>
        <p onClick={()=>{setOpen(false);setFile(null);setErrorMessage(null);}} className='font-bold text-xl cursor-pointer text-end'>x</p>
       
    {!file && <div 
    onDrop={handleDrop}
    onDragOver={handleDragOver}
    className="w-full cursor-pointer flex flex-col h-[300px] justify-center border-2 items-center rounded-sm mt-5 ">
        {fileError && <p className='text-sm text-red-500'>{fileError}</p>}
                          <div className="flex w-max flex-col gap-1 justify-center items-center">
                          <img src={ImageUpload} alt='folderimage' className='h-[60px] w-[60px]' />
                            <p className="text-base font-semibold">
                              <span className="text-primary">
                              Drag and drop agents data file
                              </span>{" "}
                             
                            </p>

                            <p className="text-xs font-normal text-[#18191F8C]">
                              File format: csv, xlxs
                            </p>
                          </div>
                        </div>}

     {file && <div   className="w-full cursor-pointer flex flex-col justify-center h-[300px] border-2 items-center rounded-sm mt-5">
     
      <p className=' px-5 py-2 border text-center bg-slate-100  cursor-pointer rounded-sm text-sm text-gray-600' >{file.name}</p>
       
        {errorMessage && <p className='text-xs text-red-500 mt-3'>{errorMessage}</p>}
        {success && <p className='text-xs text-green-500 mt-3 '>{success}</p>}
        <div className='flex gap-6 mt-10'>
        <p onClick={handleCancel} className='font-bold cursor-pointer text-sm  border py-2 px-4 rounded-md' >Cancel </p>
        <button onClick={updateDatabase}   className='flex text-sm py-2 px-6 text-white bg-blue-700 rounded-md'>
         {loader ? <><LoaderSmall /> pls wait.. </>: "Update Agents" }
        </button>
        
        </div>
        
      </div>
   
    } 
    </div> 
    </Modal>
  )
}

export default UploadAgentsModal