import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable from './ui/DynamicTable';
import {api,base} from '../utils/api'
import DataLoader from '../smallLoader/DataLoader';
import { LuCog } from "react-icons/lu";

function Catalogue() {

const [catalogueData, setCatalogueData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(20);
const [loading, setLoading] = useState(false);
const [err,setErr] = useState(null)

const getAudios = (page,page_size)=>{
    setLoading(true)
    axios.get(api + `/audios/?page=${page}&page_size=${page_size}`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.results;
         setCatalogueData(arr)
        //  setTotalRow(response.data.total_items)
        //  setBegin(295364-Number(response.data.results[0].id))
        //  setEnd(295364-Number(response.data.results[arr.length - 1].id))
         setLoading(false)
         console.log("response.data ok")
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getAudios(currentPage,page_size);
  }, [currentPage]);


  const columns = [
    { key: "VendorName", header: "Vendor" },
    { key: "DisplayUPC", header: "UPC" },
    { key: "ISRC", header: "ISRC" },
    { key: "Title", header: "Title" },
    { key: "Artist", header: "Artist" },
    { key: "LabelName", header: "Label" },
    { key: "Genre", header: "Genre" },
    { key: "RoyaltyRate", header: "Royalty" },
    { key: "Deleted", header: "del" },
   
  ];

  const displayDetails = (row)=>{
    console.log(row)
  }

  return (
    <main>
    <div className='bg-gray-100 flex justify-between px-10 py-4 border-b-2'>
     <p className='text-sm font-semibold text-gray-500'>Showing 3200/32000 records</p>
     <button className='flex text-xs text-white rounded-sm py-2 px-4 bg-black'><LuCog className='mr-1 text-lg'/> Actions</button>
    </div>
    <div>
      
    </div>
      <section className='w-full'>
        {loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err.message} please reload the page</p>:
        <DynamicTable
            data={catalogueData}
           columns={columns}
           click={displayDetails}
            />
    }
      
      </section>
    </main>
  )
}

export default Catalogue
