import React,{useState} from 'react'
import Data from './sub-components/Data';
import Actions from './sub-components/Actions'

function Dashboard() {

    const [activeTab, setActiveTab] = useState("actions");

const onTabSelect = (tab) => {
  setActiveTab(tab);
};

  return (
    <main>
    <div className='bg-gray-100 flex justify-between px-10 py-5'>
     <p className='text-xs font-semibold text-gray-500'>Hello Digger</p>
     <p className='text-xs font-semibold text-gray-500'>Date: 21/06/2021</p>
    </div>

    <header className="flex items-center overflow-x-auto  w-full">
        {subNav?.map((nav, index) => (
          <h3
            onClick={() => onTabSelect(nav.tag)}
            key={index}
            className={`flex border w-full font-medium items-center justify-center text-center text-xs  cursor-pointer  py-2 transition-all ${
              activeTab === nav?.tag
                ? "text-gray-500 bg-gray-50"
                : "text-black  bg-gray-400 "
            } `}
          >
            {nav?.name}
          </h3>
        ))}
      </header>
    
      <section className='w-full'>
     {activeTab === "actions" && <Actions/>}
     {activeTab === "data" && <Data/>}
     {activeTab === "mails" && <p>Mails</p>}
     {activeTab === "logs" && <p>Logs</p>}
     {activeTab === "settings" && <p>Settings</p>}

      </section>
    </main>
  )
}

export default Dashboard

const subNav = [
    {id: 1, name: "Actions",tag:"actions"},
    {id: 1, name: "Data",tag:"data"},
    {id: 1, name: "MailLogs",tag:"mails"},
    {id: 1, name: "Logs",tag:"logs"},
    {id: 2, name:"Settings",tag:"settings"}, 
  ];