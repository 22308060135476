import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable from './ui/DynamicTable';
import {api,base} from '../utils/api'
import { LuFolderPlus } from "react-icons/lu";
import UploadAgentsModal from './sub-components/UploadAgentsModal';
import DataLoader from '../smallLoader/DataLoader';
import AgentLabelsSumarry from './sub-components/AgentLabelsSumarry';
import UpdateAgentModal from './sub-components/UpdateAgentModal';


function AgentData() {

const [agentData, setAgentData] = useState([])
const [currentPage, setCurrentPage] = useState(1);
const [page_size, setPage_size] = useState(50);
const [loading, setLoading] = useState(false);
const [openModal, setOpenModal] = useState(false);
const [agent, setAgent] = useState(null);
const [agentDetailsDisplay,setAgentDetailsDisplay] = useState(false)
const [err,setErr] = useState(null)
const [searchQuery, setSearchQuery] = useState('');
const [colMessage, setColMessage] = useState(null);
const [updateDetails, setUpdateDetails] = useState(null);
const [openUpdate, setOpenUpdate] = useState(false);

const getAgents = (page,page_size)=>{
    setLoading(true)
    axios.get(api + `/agents/?page=${page}&page_size=${page_size}`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.results;
         setAgentData(arr)
         setLoading(false)
         console.log("response.data ok")
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getAgents(currentPage,page_size);
  }, [currentPage,openUpdate]);


  const displayDetails = (row)=>{
    console.log(row)
    setAgent(row)
    setAgentDetailsDisplay(true)
  }

  const update = (row)=>{
    setOpenUpdate(true)
    setUpdateDetails(row)
  }

  const columns = [
    { key: "zkp_Agent", header: "Agent ID" },
    { key: "NameFull", header: "Full Name" },
    { key: "CommSource", header: "CommSource" },
    { key: "EmailAgent", header: "Email" },
    { key: "PhoneAgent", header: "Phone" },
    { key: "AgentType", header: "AgentType" },
    { key: "Country", header: "Country" },
    { key: "IsActive", header: "IsActive" },
   
  ];

  const agentSearch = async (col,colQuery) => {
    try {
      const response = await axios.get(base + `/search-agent/${col}/${colQuery}`);
      let arr = response.data.results;
      console.log("this is arr",response.data.results)
      setAgentData(response.data.results);
      if (response.data.results.length < 1){
        setColMessage(`No result found for ${colQuery} `)
      }
      
    } catch (error) { 
      console.log(`Not Found : Error fetching search results for ${colQuery}`)
      
    }
  };

 

  useEffect(() => {
    if (colMessage) {
      
      const timer = setTimeout(() => {
        setColMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [colMessage]);

  return (
    <div className='flex flex-col'>
   {agentDetailsDisplay && <AgentLabelsSumarry row={agent} setAgentDetailsDisplay={setAgentDetailsDisplay}/>}
   {!agentDetailsDisplay &&<>
      <div className='bg-gray-100 flex justify-between px-10 py-4 border-b-2'>
     <p className='text-sm font-semibold text-gray-500'>Agents records</p>
     <button onClick={()=>setOpenModal(true)} className='flex text-xs text-white rounded-sm py-2 px-4 bg-black'><LuFolderPlus className='mr-1 text-lg'/> Upload agents</button>
    </div>
    <div className='w-full'
    >

{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
        
      <DynamicTable
      data={agentData}
      columns={columns}
      click={displayDetails}
      showSearch={true}
      findAgent={agentSearch}
      setSearchQuery={setSearchQuery}
      searchQuery={searchQuery}
      colMessage={colMessage}
      agentEdit="yes"
      update={update}
    />
    }

               
    
    </div>
    </>}
<UploadAgentsModal open={openModal} setOpen={setOpenModal}/>
<UpdateAgentModal open={openUpdate} row={updateDetails} setOpen={setOpenUpdate}/>

    </div>
  )
}

export default AgentData