import React from "react";
import { LuChevronRight,LuBarChart2,LuRefreshCcw } from "react-icons/lu";



const DynamicTable3 = ({ data, columns,showSearch,setSearchQuery,searchQuery,findAgent,colMessage,addMisc,agents,onChange}) => {
  
  const searchAgent = (title)=>{
      findAgent(title,searchQuery)
  }
 
  return (
    <div className="overflow-auto w-full">
      <table className="table min-w-full">
        <thead >
       {showSearch && <tr>
            <th className="py-1 px-2 text-xl text-left mb-1">
            <LuRefreshCcw/>
            </th>
            {columns
              .filter((item) => item.key !== "actions")
              .map((column) => (
                <th
                  key={column.key}
                  className="text-xs text-left mb-1"
                >
                 <input placeholder={`Find ${column.header}`} onPaste={(e)=>{setSearchQuery(e.target.value);searchAgent(column.key)}}  onChange={(e)=>{setSearchQuery(e.target.value);searchAgent(column.key)}} className="w-full border px-3 py-2 flex font-semibold"/>
                {colMessage && <p className="text-[10px] text-red-500 my-1">{colMessage}</p>}
                </th>
              ))}
          </tr>}
          <tr className="bg-slate-100">
            <th className="py-2 px-2 text-xl text-[#37b871] text-left">
            <LuBarChart2/>
            </th>
            {columns
              .filter((item) => item.key !== "actions")
              .map((column) => (
                <th
                  key={column.key}
                  className="py-2 px-2 uppercase text-xs text-[#8B909A] text-left"
                >
                  {column.header}
                </th>
              ))}
           
              <th className=" text-[#8B909A] font-medium rounded-tr-[14px] rounded-br-[14px] text-left">
                Action
              </th>
    
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b border-[#DBDADE] cursor-pointer">

                <td  className="py-1 px-2 text-xl text-[#37b871]">
                <LuChevronRight/>      
                </td>

                {columns
                  .filter((item) => item.key !== "actions")
                  .map((column) =>
                    
                      <td key={column.key}  className={`${column.key==="DisplayUPC" ? "font-bold":"font-normal"} py-1 px-2 text-xs` }>
                        {row[column.key]}
                      </td>
                    
                  )}

                <td  className="p-2 ">
                  <div className="grid grid-cols-2 gap-3">
                    {row['Ismisc'] === "NO" ?
                    <button onClick={()=>addMisc(row,)} className="flex items-center text-center col-span-1  h-8 px-4 py-2 text-xs bg-blue-700 text-white rounded-md ">Add to misc</button>
                    :
                    <button onClick={()=>addMisc(row)} className="flex items-center text-center col-span-1  h-8 px-2 py-2 text-[10px] bg-red-600 text-white rounded-md ">Remove from misc</button>   
                }
            <select onChange={(e)=>onChange(e.target.value,row['zkp_Label'])} className='w-[100px] h-[20px] col-span-1 border border-red-400 text-xs mb-3  bg-white'>
          <option>{row['PrimaryAgent'] ==="unknown" ? "Update Agent" : row['PrimaryAgent']}</option>
          {agents?.map((agent,index)=>(
           <option key={index} value={agent?.NameFull}>{agent?.NameFull}</option>
          ))}
         </select>
                   </div>  
                </td>
              
              </tr>
            ))
          ) : (
            <tr>
              <td
                className="text-center py-2 text-red-600 text-sm"
                colSpan={columns.length + 1}
              >
                NO RECORD FOUND
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable3;
