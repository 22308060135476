import React from 'react'
import styled from "styled-components";
import {AiOutlineClose} from 'react-icons/ai'

// ----------- import internal dependencies ------------


function CloseModal({ close }) {
  return (
    <>
      <Wrapper onClick={close}>
        <AiOutlineClose  size={30} />
      </Wrapper>
    </>
  );
}

export default CloseModal;

const Wrapper = styled.div`
  background-color: ;
  padding:4px;
  border-radius: 8px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-cintent: center;
  align-items: center;
  cursor: pointer;
`;