import React, { useState, useEffect } from 'react';

const ProgressBar = ({duration,timing}) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const simulateTask = () => {
            let progressValue = 0;
            const interval = setInterval(() => {
                // Simulate progress increase
                progressValue += timing;
                setProgress(progressValue);
            }, 2000); // Update progress every second

            // Simulate task completion time (45 seconds to 1 minute)
            const taskDuration = Math.floor(Math.random() * 16) +  duration; // Randomized between 45 to 60 seconds
            setTimeout(() => {
                clearInterval(interval);
                setProgress(100); // Set progress to 100% after task completion
            }, taskDuration * 2000); // Convert seconds to milliseconds

            return () => clearInterval(interval); // Cleanup on component unmount
        };

        simulateTask();
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <div className='flex'>
            <div style={{ width:`${progress}%`, height: '7px', background: 'green' }}/>
            <span className='mt-[-4px] text-[10px]'>{progress}%</span>
        </div>
    );
};

export default ProgressBar;
