import React, { createContext, useState } from 'react';

const FileContext = createContext();

const FileContextProvider = ({ children }) => {
  const [fileURL, setFileURL] = useState('');
  const [originalFile, setOriginalFile] = useState(null);

  const setFileData = (url, file) => {
    setFileURL(url);
    setOriginalFile(file);
  };

  return (
    <FileContext.Provider value={{ fileURL, originalFile, setFileData }}>
      {children}
    </FileContext.Provider>
  );
};

export { FileContext, FileContextProvider };
