import React, { useState, useEffect } from 'react'
import axios from 'axios'
import DynamicTable2 from '../ui/DynamicTable2';
import {api,base} from '../../utils/api'
import DataLoader from '../../smallLoader/DataLoader';
import AddDeductionModal from './AddDeductionModal';
import PromptModal from '../ui/PromptModal';
import Loading from '../../smallLoader/Loading';
import { notify,readFromLocalStorage } from '../../utils/util';
import CompiledReportButton from '../ui/CompiledReportButton';




function LabelsDeduction({setNextStage}) {

  const [labelData, setLabelData] = useState([])
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(null);
  const [deductionDisplay,setDeductionDisplay] = useState(false)
  const [openPrompt, setOpenPrompt] = useState(false);
  const [err,setErr] = useState(null)
  const [exporting,setExporting] = useState(false)
  const quater = readFromLocalStorage("quater")
  

    const handleExport = () => {
        setOpenPrompt(false)
        setExporting(true)
        const url = `${base}/create-quaterly-report/${quater}`;  
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
            },
        })
        .then(response => {
            if (response.ok) {
                return response.blob();
            }else{
                setExporting(false)
                notify("sorry something went wrong","error")
                throw new Error('Network response was not ok.')
            }
            
        })
        .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${quater}_Agent-label-Commision-Summary.zip`); // Specify the filename
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setExporting(false)
            notify("Report generated successfully","success")
            setNextStage("report_success")
        })
        .catch(error => console.error('Export failed:', error));
    };



const getLabelsDebtors = ()=>{
    setLoading(true)
    axios.get(base + `/label-debtors/`).then(
        response =>{
         console.log("new response",response)
         let arr = response.data.data;
         setLabelData(arr)
         setLoading(false)
        
        }
    ).catch(
         err =>{
         console.log(err)
         setErr(err.message)
         setLoading(false)
         }
    )
}


useEffect(() => {
    getLabelsDebtors();
  }, []);


  const displayDeduction = (row)=>{
    console.log(row)
    setLabel(row)
    setDeductionDisplay(true)
  }


  const columns = [
    { key: "zkp_Label", header: "Label ID" },
    { key: "LabelName_pkt", header: "Label Name" },
    { key: "LabelStatus", header: "LabelStatus" },
   
  ];



  return (
    <div className='flex flex-col'>
      
    <div className='w-full'>
    <div className='flex justify-end gap-4 mt-4 pr-4'>
        <button onClick={()=>setOpenPrompt(true)} className='flex bg-black text-white justify-end py-2 px-6 text-xs rounded-sm  '>
          {exporting ? <><Loading color="white" size={18} /> Generating report...</> :"Generate report"} 
          </button>
          <CompiledReportButton/>
        </div>

{loading ? (
            <DataLoader/>
        ):err ? 
      <p className='text-red-500 text-sm text-center mt-10'>{err} please reload the page</p>:
      <div className='rounded-md border-2 mt-5 md:mx-28 px-5'> 
      <p className='font-bold text-center text-lg my-3 text-gray-500'>Add Labels deductions</p>
      <DynamicTable2
      data={labelData}
      columns={columns}
      addDeduction={displayDeduction}
      type="deduction"
    />
    </div> 
    }

               
    
    </div>
    <AddDeductionModal open={deductionDisplay} setOpen={setDeductionDisplay} quater={quater} accountType="Label" currency={label?.CurrencyPreferred} fullName={label?.LabelName_pkt} id={label?.zkp_Label}/> 
    <PromptModal final="yes" generateReport={handleExport} message="Are you done adding Labels Deductions ?" open={openPrompt} setOpen={setOpenPrompt} /> 
    </div>
  )
}

export default LabelsDeduction